export const Icons = () => {
    return (

  <>
  


    {/* <!-- action --> */}

<h1 className="text-center">Action</h1>

<div><span className="icona-arhive"></span>icon-arhive</div>
<div><span className="icona-attach"></span>icon-attach</div>
<div><span className="icona-bag"></span>icon-bag</div>
<div><span className="icona-barcode"></span>icon-barcode</div>
<div><span className="icona-bell"></span>icon-bell</div>
<div><span className="icona-bookmark"></span>icon-bookmark</div>
<div><span className="icona-burger"></span>icon-burger</div>
<div><span className="icona-calendar"></span>icon-calendar</div>
<div><span className="icona-cart"></span>icona-cart</div>
<div><span className="icona-cebab-horizontal"></span>icona-cebab-horizontal</div>
<div><span className="icona-cebab-vertical"></span>icona-cebab-vertical</div>
<div><span className="icona-check"></span>icona-check</div>
<div><span className="icona-clock"></span>icona-clock</div>
<div><span className="icona-close"></span>icona-close</div>
<div><span className="icona-close-circle"></span>icona-close-circle</div>
<div><span className="icona-comment"></span>icona-comment</div>
<div><span className="icona-copy"></span>icona-copy</div>
<div><span className="icona-crown"></span>icona-crown</div>
<div><span className="icona-dislike"></span>icona-dislike</div>
<div><span className="icona-download"></span>icona-download</div>
<div><span className="icona-edit"></span>icona-edit</div>
<div><span className="icona-edit-1"></span>icona-edit-1</div>
<div><span className="icona-email"></span>icona-email</div>
<div><span className="icona-entrance"></span>icona-entrance</div>
<div><span className="icona-exclamation-circle"></span>icona-exclamation-circle</div>
<div><span className="icona-exit"></span>icona-exit</div>
<div><span className="icona-eye-close"></span>icona-eye-close</div>
<div><span className="icona-eye"></span>icona-eye</div>
<div><span className="icona-face-neitral"></span>icona-face-neitral</div>
<div><span className="icona-face-sad"></span>icona-face-sad</div>
<div><span className="icona-face-smile"></span>icona-face-smile</div>
<div><span className="icona-filter"></span>icona-filter</div>
<div><span className="icona-filter2"></span>icona-filter2</div>
<div><span className="icona-fingerprint"></span>icona-fingerprint</div>
<div><span className="icona-heart"></span>icona-heart</div>
<div><span className="icona-home"></span>icona-home</div>
<div><span className="icona-info-circle"></span>icona-info-circle</div>
<div><span className="icona-keyboard"></span>icona-keyboard</div>
<div><span className="icona-layers"></span>icona-layers</div>
<div><span className="icona-like"></span>icona-like</div>
<div><span className="icona-link"></span>icona-link</div>
<div><span className="icona-list"></span>icona-list</div>
<div><span className="icona-lock"></span>icona-lock</div>
<div><span className="icona-minus"></span>icona-minus</div>
<div><span className="icona-minus-circle"></span>icona-minus-circle</div>
<div><span className="icona-mouse"></span>icona-mouse</div>
<div><span className="icona-plus"></span>icona-plus</div>
<div><span className="icona-plus-circle"></span>icona-plus-circle</div>
<div><span className="icona-print"></span>icona-print</div>
<div><span className="icona-question-mark-circle"></span>icona-question-mark-circle</div>
<div><span className="icona-save"></span>icona-save</div>
<div><span className="icona-scan"></span>icona-scan</div>
<div><span className="icona-search"></span>icona-search</div>
<div><span className="icona-search-menu"></span>icona-search-menu</div>
<div><span className="icona-send-vertical"></span>icona-send-vertical</div>
<div><span className="icona-settings"></span>icona-settings</div>
<div><span className="icona-share"></span>icona-share</div>
<div><span className="icona-star"></span>icona-star</div>
<div><span className="icona-support"></span>icona-support</div>
<div><span className="icona-tag-lable"></span>icona-tag-lable</div>
<div><span className="icona-turn"></span>icona-turn</div>
<div><span className="icona-unlock"></span>icona-unlock</div>
<div><span className="icona-upload"></span>icona-upload</div>
<div><span className="icona-user"></span>icona-user</div>
<div><span className="icona-user-add"></span>icona-user-add</div>
<div><span className="icona-user-block"></span>icona-user-block</div>
<div><span className="icona-user-check"></span>icona-user-check</div>
<div><span className="icona-user-delete"></span>icona-user-delete</div>
<div><span className="icona-users"></span>icona-users</div>
<div><span className="icona-zoom-in"></span>icona-zoom-in</div>
<div><span className="icona-zoom-out"></span>icona-zoom-out</div>
<div><span className="icona-zoom-out"></span>icona-zoom-out</div>


    {/* <!-- end action --> */}


    {/* <!-- arrows --> */}


<h1 className="text-center">Arrows</h1>



    <div><span className="iconar-caret-down"></span>iconar-caret-down</div>
    <div><span className="iconar-caret-left"></span>iconar-caret-left</div>
    <div><span className="iconar-caret-right"></span>iconar-caret-right</div>
    <div><span className="iconar-caret-up"></span>iconar-caret-up</div>
    <div><span className="iconar-chevron-down"></span>iconar-chevron-down</div>
    <div><span className="iconar-chevron-left"></span>iconar-chevron-left</div>
    <div><span className="iconar-chevron-right"></span>iconar-chevron-right</div>
    <div><span className="iconar-chevrons-left"></span>iconar-chevrons-left</div>
    <div><span className="iconar-chevrons-right"></span>iconar-chevrons-right</div>
    <div><span className="iconar-chevrons-up"></span>iconar-chevrons-up</div>
    <div><span className="iconar-chevrons-up-down"></span>iconar-chevrons-up-down</div>
    <div><span className="iconar-circle-down"></span>iconar-circle-down</div>
    <div><span className="iconar-circle-left"></span>iconar-circle-left</div>
    <div><span className="iconar-circle-right"></span>iconar-circle-right</div>
    <div><span className="iconar-circle-up"></span>iconar-circle-up</div>
    <div><span className="iconar-down"></span>iconar-down</div>
    <div><span className="iconar-down-left"></span>iconar-down-left</div>
    <div><span className="iconar-down-right"></span>iconar-down-right</div>
    <div><span className="iconar-forward"></span>iconar-forward</div>
    <div><span className="iconar-left-down"></span>iconar-left-down</div>
    <div><span className="iconar-left"></span>iconar-left</div>
    <div><span className="iconar-maxsize"></span>iconar-maxsize</div>
    <div><span className="iconar-minsize"></span>iconar-minsize</div>
    <div><span className="iconar-reply"></span>iconar-reply</div>
    <div><span className="iconar-right"></span>iconar-right</div>
    <div><span className="iconar-right-down"></span>iconar-right-down</div>
    <div><span className="iconar-up"></span>iconar-up</div>
    <div><span className="iconar-up-left"></span>iconar-up-left</div>
    <div><span className="iconar-up-right"></span>iconar-up-right</div>

  {/* <!-- end arrows --> */}


   {/* design */}

  <h1 className="text-center">Design</h1>



  <div><span className="icond-align-center"></span>icond-align-center</div>
  <div><span className="icond-align-down"></span>icond-align-down</div>
  <div><span className="icond-align-left"></span>icond-align-lefts</div>
  <div><span className="icond-align-right"></span>icond-align-right</div>
  <div><span className="icond-align-top"></span>icond-align-top</div>
  <div><span className="icond-anticlockwise"></span>icond-anticlockwise</div>
  <div><span className="icond-clockwise"></span>icond-clockwise</div>
  <div><span className="icond-compases"></span>icond-compases</div>
  <div><span className="icond-crop"></span>icond-crop</div>
  <div><span className="icond-drop"></span>icond-drop</div>
  <div><span className="icond-font-size"></span>icond-font-size</div>
  <div><span className="icond-image"></span>icond-image</div>
  <div><span className="icond-image-add"></span>icond-image-add</div>
  <div><span className="icond-input-cursore"></span>icond-input-cursore</div>
  <div><span className="icond-layout"></span>icond-layout</div>
  <div><span className="icond-layout-2-left"></span>icond-layout-2-left</div>
  <div><span className="icond-layout-grid"></span>icond-layout-grid</div>
  <div><span className="icond-layout-left"></span>icond-layout-left</div>
  <div><span className="icond-list"></span>icond-list</div>
  <div><span className="icond-list-down"></span>icond-list-down</div>
  <div><span className="icond-listmove"></span>icond-listmove</div>
  <div><span className="icond-list-text"></span>icond-list-text</div>
  <div><span className="icond-list-up"></span>icond-list-up</div>
  <div><span className="icond-magictool"></span>icond-magictool</div>
  <div><span className="icond-pin"></span>icond-pin</div>
  <div><span className="icond-pin-tilted"></span>icond-pin-tilted</div>
  <div><span className="icond-scissors"></span>icond-scissors</div>
  <div><span className="icond-share"></span>icond-share</div>

  {/* <!-- end design --> */}



  {/* <!-- media --> */}


  <h1 className="text-center">Media</h1>


  <div><span className="iconm-call"></span>iconm-call</div>
  <div><span className="iconm-camera-add"></span>iconm-camera-add</div>
  <div><span className="iconm-camera-delete"></span>iconm-camera-delete</div>
  <div><span className="iconm-camera-download"></span>iconm-camera-download</div>
  <div><span className="iconm-camera-off"></span>iconm-camera-off</div>
  <div><span className="iconm-camera-on"></span>iconm-camera-on</div>
  <div><span className="iconm-camera-swap"></span>iconm-camera-swap</div>
  <div><span className="iconm-cast"></span>iconm-cast</div>
  <div><span className="iconm-forward"></span>iconm-forward</div>
  <div><span className="iconm-hd"></span>iconm-hd</div>
  <div><span className="iconm-incoming"></span>iconm-incoming</div>
  <div><span className="iconm-last"></span>iconm-last</div>
  <div><span className="iconm-maxsize"></span>iconm-maxsize</div>
  <div><span className="iconm-mic"></span>iconm-mic</div>
  <div><span className="iconm-mic-off"></span>iconm-mic-off</div>
  <div><span className="iconm-minsize"></span>iconm-minsize</div>
  <div><span className="iconm-missed"></span>iconm-missed</div>
  <div><span className="iconm-music"></span>iconm-music</div>
  <div><span className="iconm-next"></span>iconm-next</div>
  <div><span className="iconm-notification-off"></span>iconm-notification-off</div>
  <div><span className="iconm-notification-on"></span>iconm-notification-on</div>
  <div><span className="iconm-outcoming"></span>iconm-outcoming</div>
  <div><span className="iconm-pause"></span>iconm-pause</div>
  <div><span className="iconm-phone"></span>iconm-phone</div>
  <div><span className="iconm-picture-in-picture"></span>iconm-picture-in-picture</div>
  <div><span className="iconm-play"></span>iconm-play</div>
  <div><span className="iconm-playlist"></span>iconm-playlist</div>
  <div><span className="iconm-press"></span>iconm-press</div>
  <div><span className="iconm-record"></span>iconm-record</div>
  <div><span className="iconm-repeat"></span>iconm-repeat</div>
  <div><span className="iconm-rewind-back"></span>iconm-rewind-back</div>
  <div><span className="iconm-rewind-forward"></span>iconm-rewind-forward</div>
  <div><span className="iconm-rhythm-line"></span>iconm-rhythm-line</div>
  <div><span className="iconm-rss"></span>iconm-rss</div>
  <div><span className="iconm-settings"></span>iconm-settings</div>
  <div><span className="iconm-stop"></span>iconm-stop</div>
  <div><span className="iconm-swap"></span>iconm-swap</div>
  <div><span className="iconm-swap2"></span>iconm-swap2</div>
  <div><span className="iconm-video-add"></span>iconm-video-add</div>
  <div><span className="iconm-video-delete"></span>iconm-video-delete</div>
  <div><span className="iconm-video-download"></span>iconm-video-download</div>
  <div><span className="iconm-video-off"></span>iconm-video-off</div>
  <div><span className="iconm-video-on"></span>iconm-video-on</div>
  <div><span className="iconm-voiceprint"></span>iconm-voiceprint</div>
  <div><span className="iconm-volum-bass"></span>iconm-volum-bass</div>
  <div><span className="iconm-volume"></span>iconm-volume</div>
  <div><span className="iconm-volume1"></span>iconm-volume1</div>
  <div><span className="iconm-volume2"></span>iconm-volume2</div>
  <div><span className="iconm-volume-no"></span>iconm-volume-no</div>
 
  {/* <!-- end media --> */}


{/* <!-- travel --> */}



<h1 className="text-center">Travel</h1>


<div><span className="icont-baby"></span>icont-baby</div>
<div><span className="icont-baby-stroller"></span>icont-baby-stroller</div>
<div><span className="icont-ballon"></span>icont-ballon</div>
<div><span className="icont-beach"></span>icont-beach</div>
<div><span className="icont-bed"></span>icont-bed</div>
<div><span className="icont-bike"></span>icont-bike</div>
<div><span className="icont-bike-human"></span>icont-bike-human</div>
<div><span className="icont-binoculars"></span>icont-binoculars</div>
<div><span className="icont-boat"></span>icont-boat</div>
<div><span className="icont-bus"></span>icont-bus</div>
<div><span className="icont-anchor"></span>icont-anchor</div>
<div><span className="icont-car"></span>icont-car</div>
<div><span className="icont-compass"></span>icont-compass</div>
<div><span className="icont-cup"></span>icont-cup</div>
<div><span className="icont-dog"></span>icont-dog</div>
<div><span className="icont-earth"></span>icont-earth</div>
<div><span className="icont-excalator-down"></span>icont-excalator-down</div>
<div><span className="icont-excalator-up"></span>icont-excalator-up</div>
<div><span className="icont-food"></span>icont-food</div>
<div><span className="icont-foodprint"></span>icont-foodprint</div>
<div><span className="icont-glass"></span>icont-glass</div>
<div><span className="icont-globe"></span>icont-globe</div>
<div><span className="icont-limited-mobility"></span>icont-limited-mobility</div>
<div><span className="icont-map"></span>icont-map</div>
<div><span className="icont-map-pin"></span>icont-map-pin</div>
<div><span className="icont-motorcycle"></span>icont-motorcycle</div>
<div><span className="icont-mountains"></span>icont-mountains</div>
<div><span className="icont-mouseum"></span>icont-mouseum</div>
<div><span className="icont-navigate"></span>icont-navigate</div>
<div><span className="icont-parking"></span>icont-parking</div>
<div><span className="icont-pin"></span>icont-pin</div>
<div><span className="icont-pin-add"></span>icont-pin-add</div>
<div><span className="icont-pin-delete"></span>icont-pin-delete</div>
<div><span className="icont-pin-outline"></span>icont-pin-outline</div>
<div><span className="icont-pin-profile"></span>icont-pin-profile</div>
<div><span className="icont-pin-solid"></span>icont-pin-solid</div>
<div><span className="icont-plane-down"></span>icont-plane-down</div>
<div><span className="icont-plane-up"></span>icont-plane-up</div>
<div><span className="icont-restaurant"></span>icont-restaurant</div>
<div><span className="icont-rocket"></span>icont-rocket</div>
<div><span className="icont-route-down"></span>icont-route-down</div>
<div><span className="icont-route-right"></span>icont-route-right</div>
<div><span className="icont-scooter"></span>icont-scooter</div>
<div><span className="icont-sea"></span>icont-sea</div>
<div><span className="icont-ship"></span>icont-ship</div>
<div><span className="icont-space-ship"></span>icont-space-ship</div>
<div><span className="icont-suitcase"></span>icont-suitcase</div>
<div><span className="icont-truck"></span>icont-truck</div>
<div><span className="icont-walk"></span>icont-walk</div>

{/* <!-- end travel --> */}
  
  </>

    )
}
export default Icons