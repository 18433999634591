import React from 'react'
import Header from '../general/header'
import Footer from '../general/footer'
import SiteMap from '../general/siteMap'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react'
import Joi from 'joi';
import axios from 'axios';
import {useTranslation} from 'react-i18next';

const NewTicket = () => {

  const {t, i18n} = useTranslation();

  const navigate = useNavigate();
  function goConfirm(){
      let path='/ticketList'
      navigate(path)
  }

// Ticket info

let [ticket, setTicket] = useState({
  title: '',
  description: '',
  attachment: '',
});

let[errorMsg,setErrorMsg] = useState("");
let[errorList,setErrorList] = useState({});
const [selectedImage, setSelectedImage] = useState();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);



async function submitFormData(e) {
  e.preventDefault();
  let validationResult = validateForm();
  const {error} = validationResult;
  setLoading(true)

  if (error) {
      const errorData = {};
      for (let item of error.details) {
          const name = item.path[0];
          const message = item.message;
          errorData[name] = message;
      }
      setErrorList(errorData);
  } else {
      validateForm()

    try {
      setErrorMsg('');
      const token = localStorage.getItem("token")
        const formData = new FormData();

      if (selectedImage)
      formData.append(
            "attachment",
            selectedImage,
            selectedImage?.name
        );
        formData.append('title', ticket.title);
        formData.append('description', ticket.description);

        const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

        let {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/customer/tickets`, formData, {
          headers: {
              'x-lang-code': changeLang,
              'Authorization': `Bearer ${token}`
          }



      });

      if (data.status == "ok") {
  setLoading(false)

          goConfirm()

      }
  } catch (error) {

      if (error?.response?.data?.message)
          setErrorMsg( error?.response?.data?.message)
      else
          console.info('')
  }


}
}


function getFormDate(e) {
  let myTicket = {...ticket};
  myTicket[e.target.name] = e.target.value;
  setTicket(myTicket);

}




function validateForm(){
  const schema=Joi.object({
    title:Joi.string().required(),
    description:Joi.string().allow(null, ''),
    attachment:Joi.allow(null, '')

  });

  return schema.validate(ticket,{abortEarly:false});

}



const [title, setTitle] = useState('');



function SubmitButton() {
    if (title) {
        return <button className="solid-btn fontX7" type="submit">{t('ticketNew.btn')}</button>
    } else {
        return <button className="solid-btn fontX7" type="submit" disabled>{t('ticketNew.btn')}</button>
    }
    ;
};






  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      setInputValue(e.target.value);
    }
  };

  const removeSelectedImage = (e) => {
    setSelectedImage();
    setInputValue("");
    const newTicket = {...ticket};
    newTicket.attachment="";
    setTicket(newTicket);
  };




  return (
    <>
         <Header/>
    <SiteMap pageUrl="/TicketList"  pageLink={t('ticketNew.Tickets')} pageName={t('ticketNew.newTicket')}/>
    {loading ? <div className="loader-line"></div> : ""}

      <div className='container new-ticket'>
      <div className='col-xs-12 col-sm-12 col-md-6 col-md-offset-3'>
      <h1 className='fontX3'>{t('ticketNew.title')}</h1>
  <form enctype="multipart/form-data"
onSubmit={submitFormData}>

  {errorMsg&&<p className='error-backend-label'>{errorMsg}</p>}
                    {errorMsg ? <div className="erorMsg"></div> : ""}

     <div className='col-xs-12 remove-padding input-main'>
         <label>{t('ticketNew.Ticket')}</label>
         <input
         name='title'
         style={{borderColor:errorList.confirm_password?'#D62921':''}} onChange={e => {
          setTitle(e.target.value);
          getFormDate(e)
      }} autoComplete="off"
         placeholder={t('ticketNew.Ticket')} type="text"/>
               <div style={{display:errorList.title?'':'none'}} className="error-msg">
                                    {errorList.title}
                                </div>
     </div>

     <div className='col-xs-12 remove-padding input-main'>
         <label>{t('ticketNew.TicketD')}</label>
        <textarea onChange={e => {

          getFormDate(e)
      }}  placeholder={t('ticketNew.TicketD')} name='description'></textarea>
     </div>


     <div className='upload-main col-xs-12'>
        <p>{t('ticketNew.Attach')}</p>
        <label htmlFor='uploadInput'>{t('ticketNew.Image')}</label>
        <input
          accept="image/*"
          type="file"
          name='attachment'
          value={inputValue}
          onChange={e => {
            imageChange(e);
            getFormDate(e)
        }}
          id = "uploadInput"
        />

<div className='upload-img-main'>
  <span className='iconm-camera-on'></span>
        {selectedImage && (
          <div>
            <img
            loading="lazy"
              src={URL.createObjectURL(selectedImage)}
              alt="Thumb"
            />
            <button  onClick={removeSelectedImage} >
            {t('ticketNew.Clear')}
            </button>
          </div>
        )}
      </div>
      </div>
      <SubmitButton/>
  </form>

      </div>

      </div>
    <Footer/>


    </>
  )
}

export default NewTicket
