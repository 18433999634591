import React from 'react'
import {useState, useEffect} from 'react';
import BannerCate from '../Category/bannerCate'
import Header from '../general/header'
import Footer from '../general/footer'
import Axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'


const Category = () => {
    const navigate = useNavigate();
    const [CateData, setCateData] = useState([]);
    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(true);


    async function dataApi() {

        let {data: categoryResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/categories`,{
            headers: {
                'x-lang-code': changeLang,
            }
        });

        const {data: allCategories} = categoryResponse;
        setCateData(allCategories)
    }


    useEffect(() => {
        dataApi().then(r => {
        setLoading(false);

        })
    }, []);


    return (
        <>
            <Header/>
            <BannerCate/>
     
            <div className='container remove-padding cate-grid-main'>
                <div className='col-xs-12'></div>
               
                {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={3}/></div>
                        </SkeletonTheme> : ""}
               
                {CateData.list && CateData.list.map((el, index) =>

                    <div className='col-md-4 col-xs-12 col-sm-6 cate-item-grid' key={index}>
                        <a href="" onClick={() => {
                            navigate(`/SubCategory/${el.id}?cat=${el.id}`, {state: {cat: el}})
                        }}>

                            <div className='img-frame'><img loading="lazy" src={el.photo_url}/></div>
                            {/* <h2 className='fontX3'>{el.name}</h2> */}
                            <h2 className={el.children && el.children.length > 0 ? "fontX3 has-subs" : "fontX3"}>{el.name}</h2>
                            {/* <p>{el.description}</p> */}
                            <div className='after-shaddow'></div>

                        </a>


                        {el.children && el.children.length > 0 ?

                            <ul className='col-xs-12 subs-main'>
                                {el.children && el.children.slice(0, 4).map((child, key) => (
                                    <li key={key}>
                                        <img loading="lazy" src={child.photo_url}/>
                                    </li>))}
                                <div>
                                    {el.children && el.children.length > 3 ?
                                        <a href="" onClick={() => {
                                            navigate(`/SubCategory/${el.id}`, {state: {cat: el}})
                                        }}>
                                            {t('categroies.seeAll')}

                                        </a>
                                        : ""}
                                </div>

                            </ul>
                            : ""}



                    </div>
                )}
            </div>
            <Footer/>
        </>
    )
}

export default Category
