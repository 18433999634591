import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from "../src/locale/en"
import translationAR from "../src/locale/ar"

const resources = {

    en: {
    translation: translationEN
  },
  ar: {
    translation:translationAR 
  }
  
};



i18n
  .use(LanguageDetector) 
  .use(initReactI18next) 
  .init({
    resources,
    lng: localStorage.getItem("lang")??'ar',

    interpolation: {
      escapeValue: false
    },
    react:{
        useSuspense:false
    }
  });

  export default i18n;