import React from 'react'
// import bannerImg from '../../imeges/5.png';
import { useState, useEffect } from 'react';

import Axios from 'axios';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'





export const BannerCate = () => {  

   const [firstSlider, setFirst] = useState([]);
   const [loading, setLoading] = useState(true);

   async function dataApi() { 
    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'
       
       let{data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/categories/banner`,{
 headers: {
   'x-lang-code': changeLang, 
 }
});
       
       const {data: firstSlider} = brandsResponse;
       setFirst(firstSlider)
       }


   useEffect(() => {
       dataApi().then(r => {
        setLoading(false);

       })
   }, []);

   






   
   
    return (
       <>



<div className='banner-main-cont container-fluid remove-padding'>
{loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={1}/></div>
                        </SkeletonTheme> : <div className='container-fluid Banner-main remove-padding' >
  
  <img loading="lazy" src={firstSlider.photo_url}/>
<div className='container'>
<h2 className='fontX2'>{firstSlider.name}</h2>
<p className='fontX4'>{firstSlider.desc}</p>

</div>
<span></span>
</div>}
</div>




       
       </>
    )
}

export default BannerCate
