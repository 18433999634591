import React from 'react'
import {useState, useEffect, useContext} from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import { MassegeContext } from '../MsgStore/MsgContext';


export const Categories = () => {

let msg = useContext(MassegeContext)



    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'


    const [loading, setLoading] = useState(true);
    const [showAll, setShowAll] = useState(false);


    const [CateData, setCateData] = useState([]);

    async function dataApi() {


        let {data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/home/categories`,{
            headers: {
                'x-lang-code': changeLang,
            }
        });


     

        const {data: firstSlider} = brandsResponse;
        setCateData(firstSlider)
    }


    useEffect(() => {
        dataApi().then(r => {
            setShowAll(true);
            setLoading(false);
        })
    }, []);


    return (
        <>


            <div className='container-fluid cat-main-home'>
                <div className='container remove-padding'>             
<p className='priceNotice'>{msg.messages.home_price_notice}</p>
                    {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={1}/></div>
                        </SkeletonTheme> : ""}
                    <ul className='cate-main-home-list col-xs-12 remove-padding'>


                     

                        {CateData.slice(0, 8).map((el, index) =>

                            <li onClick={() => {
                                navigate(`/SubCategory/${el.id}?cat=${el.id}`, {state: {cat: el}})
                            }} className='home-cate-item' key={index}>
                                <div className='img-frame'><img loading="lazy" src={el.photo_url}/></div>


                                <div className='cate-info'>
                                    <h2 className='fontX3'>{el.name}</h2>
                                    {/* <p>{el.description}</p> */}
                                    {/* <Link to={`/subsCate/${el.id}`}>{t('homeCategories.allLink')}</Link> */}
                                    <a href="">{t('homeCategories.allLink')}</a>


                        
                                </div>

                            </li>
                        )}

                        {showAll ? <div className='see-all-cate-main more-cate-grid-main'>
                            {CateData.length > 4 ||true ? <Link className='fontX3' to="/categories">{t('homeCategories.link')}</Link> : ""}
                        </div> : ""}


                    </ul>

                    <div className='clearfix'></div>


                    {/* <div className='col-md-4 col-xs-12 col-sm-12 remove-padding'>
                    <div className="col-md-12 col-sm-6 col-xs-6">
                    <div className='img-frame1 home-cate-item'><img src={catImg1} alt="catImg1" />
                    <div className='cate-info'>
                              <h2 className='fontX3'>Title</h2>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
                              <Link to="home">See all</Link>
                              </div>
                    </div>
                    </div>
                    <div className="col-md-12 col-sm-6 col-xs-6">
                   <div className='img-frame110 home-cate-item'><img src={catImg2} alt="catImg1" />
                   <div className='cate-info'>
                              <h2 className='fontX3'>Title</h2>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
                              <Link to="home">See all</Link>
                              </div>
                   </div>
                    </div>
                    </div>
                    <div className='col-md-8 col-xs-12 remove-padding '>

                         <div className='col-xs-12 home-cate-item'>

                              <div className='img-frame50'>
                                   <img src={catImg3} alt="catImg1" />
                              </div>
                              <div className='cate-info'>
                              <h2 className='fontX3'>Title</h2>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
                              <Link to="home">See all</Link>
                              </div>


                         </div>

                              <div className='col-md-6 col-sm-6 col-xs-6 home-cate-item'>
                    <div className='img-frame1'><img src={catImg4} alt="catImg1" /></div>
                    <div className='cate-info'>
                              <h2 className='fontX3'>Title</h2>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
                              <Link to="home">See all</Link>
                              </div>

                              </div>
                              <div className='col-md-6 col-xs-6 col-sm-6 more-cate-grid-main'>
                                <div className='col-xs-6 remove-padding'>
                                <div className='img-frame1'><img src={catImg4} alt="catImg1" /></div>
                                </div>

                                <div className='col-xs-6 remove-padding'>
                                <div className='img-frame1'><img src={catImg4} alt="catImg1" /></div>
                                </div>

                                <div className='col-xs-6 remove-padding'>
                                <div className='img-frame1'><img src={catImg4} alt="catImg1" /></div>
                                </div>

                                <div className='col-xs-6 remove-padding'>
                                <div className='img-frame1'><img src={catImg4} alt="catImg1" /></div>
                                </div>
                                <Link className='fontX3' to="/category">View All</Link>
                                 <div className='slide-bot'></div>
                              </div>

                    </div> */}
                </div>
            </div>

        </>
    )
}

export default Categories
