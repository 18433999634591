import {Link, useLocation} from 'react-router-dom';
import React from 'react'
import {useState, useEffect} from 'react';
import Header from '../general/header'
import Footer from '../general/footer'
import RootStore from "../../mobx-stores/index";
import ProductStore from "../../mobx-stores/stores/productStore/productStore";
import ReactPaginate from 'react-paginate';
import memberStore from "../../mobx-stores/stores/memberStore/memberStore";
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import noResults from '../../imeges/no-results.png';
import placeHolder from '../../imeges/placeHolder.png';

import queryString from 'query-string';

const {
    woocommerceStore: {
        Stores: {
            cartStore,

        },
    }
} = RootStore.Stores;

const ProductsFilter = () => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);

    const token = memberStore.token
    const [products, setProduct] = useState();
    const [pagination, setPagination] = useState({});
    const [CartItems, setCartItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const {state} = useLocation();
    const searchWord = async (searchWord, page = 1) => {
        const result = await ProductStore.searchWord(searchWord, page);
        if (result?.data?.data?.list) {
            setProduct(result.data.data.list)
            setPagination(result.data.data.paginator)
        
        }
    }

    const handlePageClick = (event) => {
        let {searchValue} = queryString.parse(window.location.search)
        setCurrentPage(event.selected + 1)
        searchWord(searchValue, event.selected + 1).then(r => {

        })
    };
    useEffect(() => {
        if (products?.length) {
            setPageCount(Math.ceil(pagination?.total / pagination?.per_page));
        }
    }, [products, pagination])


    useEffect(() => {

        if (state && state.search) {
            searchWord(state.search).then(r => {
                setLoading(false);
            })
        } else {
            let {searchValue} = queryString.parse(window.location.search)
            searchWord(searchValue, currentPage).then(r => {
                setLoading(false);
            })
        }
    }, []);


    async function addToCart(product) {
        cartStore.addItem(product)
    }


    window.addEventListener('cart', () => {
        setCartItems([...cartStore.cart.line_items])
    })
    const [pageCount, setPageCount] = useState(0);

    async function removeFromCart(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.name

        };

        cartStore.deleteItem(item)
    }

    async function decreaseItemQuantity(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.name

        };

        cartStore.removeItem(item)
    }

    return (
        <>
            <Header/>

            <div className="container products-main search-filter">

                {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                    <div><Skeleton count={8}/></div>
                </SkeletonTheme> : <div>
                    <h1 className='fontX3'> {t('searchRes.title')} '{pagination && pagination.total}'</h1>


                    {products.length && products.length >= 0 ? <div>

                        {products && products.map((item, index) => {
                            let existed = 0;

                            if (CartItems) {
                                existed = CartItems.find(
                                    cartItem => cartItem.id === item.id
                                )

                            }
                            return <div className='col-md-3 col-sm-6 col-xs-6 product-item' key={index}>

                                <div className='col-xs-12 remove-padding'>
                                {item.discount_percentage <= 0 ?
                                       ""  : <span className="offer-label">{t('product.save')} {item.discount_percentage} %</span>}

                                    <div className='img-frame'><img loading="lazy" src={item.photo_url || placeHolder} alt="product name"/></div>

                                    {token ?


                                        <div>

                                            {!existed ?
                                                <button
                                                    onClick={() => addToCart(item)}
                                                > {t('product.addCart')}
                                                </button> :

                                                <div className='qtn-main'>

                                                    {existed.quantity < item.actual_quantity ? "" :
                                                        <p className='no-items-avalible'>
                                                            <span>{t('productDetails.noMore')}</span></p>}

                                                    <button
                                                        className={existed.quantity == 1 ? "icona-minus dis-qtn" : "icona-minus"}
                                                        onClick={() => {
                                                            decreaseItemQuantity(item).then(r => {
                                                            });

                                                        }}></button>

                                                    <h4>{existed.quantity}</h4>
                                                    {existed.quantity < item.actual_quantity ?
                                                        <button className={"icona-plus"} onClick={() => {
                                                            addToCart(item).then(r => {
                                                            });


                                                        }}></button> : <button className="icona-plus showBtn"></button>}


                                                    <span className='icona-minus'
                                                          onClick={() => removeFromCart(item)}></span>
                                                </div>
                                            }

                                        </div>

                                        : <Link className='go-log-in-link' to="/login">{t('product.addCart')}</Link>}


                                    <Link className="fontX6" to={`/Product/${item.id}`}>{item.name}</Link>

                                    <h3 className={item.discount_percentage <= 0 ? "fontX6" : "fontX6 has-offer"} >
                            <b>{item.price} {t('product.currency')}</b>
                            <span>{item.discount_percentage <= 0 ? "" : `${item.old_price} ${t('product.currency')}`} </span></h3>
                                    <h5>{t('product.per')} {item.unit}</h5>

                                </div>
                            </div>
                        })}

                        <div className='col-xs-12 remove-padding pagination'>



                            {pageCount > 1 ? <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                            /> : ''}

                        </div>
                    </div> : <div className='no-results-main'>
                        <img loading="lazy" src={noResults} alt="no Results no-results-address"/>
                        <h4>{t('NotFound.search')}</h4>
                        <Link className='solid-btn' to={`/`}>{t('checkOut.Continue')}</Link>
                    </div>}

                </div>}

            </div>
            <Footer/>


        </>

    )
}

export default ProductsFilter
