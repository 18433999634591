import {Link} from 'react-router-dom';
import React from 'react'
import {useState, useEffect} from 'react';
import Header from '../general/header';
import Footer from '../general/footer';
import Axios from 'axios';
import RootStore from "../../mobx-stores/index";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import placeHolder from '../../imeges/placeHolder.png';

import {useTranslation} from 'react-i18next';




const {
    woocommerceStore: {
        Stores: {
            cartStore,

        },
    }} = RootStore.Stores;
const BestSela = () => {
    const token = localStorage.getItem("token");
    const [products, setProduct] = useState([]);
    const [title, setTitle] = useState("");
    const [CartItems, setCartItems] = useState([]);
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

  


    async function dataApi() {
        const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

        let {data: productsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/home/second-section-products?per_page=4`,{
            headers: {
                'x-lang-code': changeLang,
            }
        });


        const {
            data: {
                title,
                products:
                    {
                        list: products
                    }
            }
        } = productsResponse;
        setProduct(products)
        setTitle(title)
        setCartItems(cartStore?.cart?.line_items)


    }


    useEffect(() => {
        dataApi().then(r => {
            setLoading(false);

        })
    }, []);



 

    async function addToCart(product) {

        

         cartStore.addItem(product)
     
    }



    window.addEventListener('cart', () => {
        setCartItems([...cartStore.cart.line_items])
    })


   
    const [addQtbBtn, setAddQtbBtn] = useState();
    async function removeFromCart(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.description

        };

        cartStore.deleteItem(item)
    }

    async function decreaseItemQuantity(product) {

        let item = {
            id: product.id,
            photo:product.photo_url,
            quantity: 1,
            price:product.price,
            name:product.description

        };

        cartStore.removeItem(item)
    }




    return (
<>
        <Header/>


        <div className="container products-main remove-padding">
        {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={4}/></div>
                        </SkeletonTheme> : ""}
            <h2 className="fontX3">{title}</h2>
            {products && products.map((item, index) => {
                let existed = 0;

                if (CartItems) {
                    existed = CartItems.find(
                        cartItem => cartItem.id === item.id
                    )

                }
                return <div className='col-md-3 col-sm-6 col-xs-6 product-item' key={index}>

                    <div className='col-xs-12 remove-padding'>
                    {item.discount_percentage <= 0 ?
                                       ""  : <span className="offer-label">{t('product.save')} {item.discount_percentage} %</span>}

                        <div className='img-frame'><img loading="lazy" src={item.photo_url || placeHolder} alt="product name"/></div>
                      
                        {token ?   



<div>

{!existed ?
      <button
          onClick={() => addToCart(item)}
      >  {t('product.addCart')}
      </button>: 

<div className='qtn-main'>
                                        
                                        {existed.quantity < item.actual_quantity ? "" : <p  className='no-items-avalible'><span>{t('productDetails.noMore')}</span> </p>}
        
                                                <button
                                                    className={existed.quantity == 1 ? "icona-minus dis-qtn" : "icona-minus"}
                                                    onClick={() => {
                                                        decreaseItemQuantity(item).then(r => {
                                                        });
                                           
                                                    }}></button>
        
                                                <h4>{existed.quantity}</h4>
                                               {existed.quantity < item.actual_quantity? <button  className={isDisabled ? "icona-plus showBtn" : "icona-plus"} onClick={() => {
                                                    addToCart(item).then(r => {
                                                    });
                                                   
                                                    
        
                                                }}></button>: <button  className="icona-plus showBtn"></button> } 
        
        
                                                <span className='icona-minus' onClick={() => removeFromCart(item)}></span>
                                            </div>
  }

</div>

                         :   <Link className='go-log-in-link' to="/login">{t('product.addCart')}</Link> }
   
                      
                        
                        <Link className="fontX6" to={`/Product/${item.id}`}>{item.name}</Link>
                       
                        <h3 className={item.discount_percentage <= 0 ? "fontX6" : "fontX6 has-offer"} >
                            <b>{item.price} {t('product.currency')}</b>
                            <span>{item.discount_percentage <= 0 ? "" : `${item.old_price} ${t('product.currency')}`} </span></h3>

                        <h5>{t('product.per')} {item.unit}</h5>
                        {/* <button
                            onClick={() => removeFromCart(item)}
                        >removeTo Cart
                        </button> */}

                    </div>
                </div>
            })}


        </div>

<Footer/>

        </>

    )
}

export default BestSela
