import React, {useState, useEffect} from 'react'
import Axios from 'axios';
import {useParams} from 'react-router-dom'

import Header from '../general/header'
import Footer from '../general/footer'
import SiteMap from '../general/siteMap'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import SimpleDateTime  from 'react-simple-timestamp-to-date';


const OrderDetails = () => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(true);

    const {orderId} = useParams()
    const [orderToken, setOrderToken] = useState("");

    const [order, setOrder] = useState([]);
    let [itemOrderId, setItemOrderId] = useState({
        order_id: orderId
    });


    async function dataApi() {
        const token = localStorage.getItem("token")
        const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

        let {data: orderResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/orders/${orderId}`, {
            headers: {
                'x-lang-code': changeLang,
                'Authorization': `Bearer ${token}`

            }
        });

        const {data: orderData} = orderResponse;
        setOrder(orderData)

    }


    useEffect(() => {
        dataApi().then(r => {
        setLoading(false);

        })
    }, []);




    async function update() {



          try {

        const token = localStorage.getItem("token")

            const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

            let {data} = await Axios.post(`${process.env.REACT_APP_BASE_URL}/api/customer/orders/xpay/generate/iframe`, itemOrderId, {
                headers: {
                    'x-lang-code': changeLang,
                    'Authorization': `Bearer ${token}`

                }
            });

            if (data.status == "ok") {
                // await window.COWPAYOTPDIALOG.init()
                // await window.COWPAYOTPDIALOG.load(data.data.token)
                const a = document.createElement('a');
                a.innerHTML += 'cancel';
                a.setAttribute('href', `orderDetails`);
                window.document.getElementById('cowpay-otp-container')
                    .innerHTML +=  `
            <div class="order-done-caypay">
              <iframe
        width="100%"
        height="1000"
        title="payment"
        src="${data.data.iframe_url}"
                />
            </div>
            `


            }
        } catch (error) {





        }

        finally {





      }



    }



const totalPrice = order.total_amount*1 - order.tax_amount*1 - order.delivery_fees*1 + order.discount_amount*1 + order.manual_labor_cost*1

let exp = Math.floor(Math.log10(Math.abs(totalPrice)));
let simplifiedNumber = (totalPrice / 1**exp).toFixed(1);
// console.log(simplifiedNumber);


    return (
        <>
            <Header/>
            <SiteMap pageUrl="/Orders" pageLink={t('orders.title')} pageName={t('orderDetails.orderD')}/>
            <div className='container pro-orders-main'>

                <h1 className='fontX3'>{t('orderDetails.title')}</h1>

                <div className='col-md-8 col-xs-12 col-sm-12'>


                {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={2}/></div>
                        </SkeletonTheme> :      <div className='col-xs-12 order-item'>
                        <p className='fontX5'>{t('orderDetails.number')} {order.id}</p>

                        <span
                            className={order.payment_status == 1 ? 'blue' : order.payment_status == 3 ? 'red' : order.payment_status == 2 ? 'green' : 'orange'}>
                  {order.payment_status_name}
                 </span>
                        <p className='fontX6'>{t('orderDetails.Placed')}&nbsp;&nbsp;


                    <SimpleDateTime dateSeparator="/" showTime="0" format="YMD">{order.created_at}</SimpleDateTime>
                    &nbsp;&nbsp; | &nbsp;&nbsp;
                    <SimpleDateTime timeSeparator=":" showDate="0">{order.created_at}</SimpleDateTime>
                        </p>
                        <div className='col-xs-12 remove-padding'>
                            <h6 className='fontX6'>{t('orderDetails.Total')} ({order.order_items_count} {t('orderDetails.items')})  </h6>
                            {/* <h5 className='fontX6'> {order.total_amount} {t('orderDetails.EGP')}</h5> */}
                             <h4 className='fontX6'>&nbsp;{order.total_amount*1} {t('orderDetails.EGP')}</h4>
                            <div className='clearfix'></div>
                            <p>{t('orderDetails.paymentWay')} :&nbsp;&nbsp;<b>{order.payment_method_name}</b></p>
                           {order.payment_status == 0 && order.payment_method == 2 ? <a onClick={update}  className='payNowBtn'>{t('orderDetails.payNow')}</a> : ""}
                            <p>{orderToken}</p>
                        </div>
                    </div>}

              {/* {console.log(order)} */}

                    <div className='col-xs-12 remove-padding'>


                        {order.order_items && order.order_items.map((item, index) =>
                            <div className='col-xs-12 order-d-item' key={index}>

                                <img loading="lazy" src={item.photo_url}/>
                                <Link className='fontX6' to={`/Product/${item.product_id}`}>{item.name}</Link>
                                <div className='col-xs-12 remove-padding'>
                                    <p className='fontX6'>{item.unit} {t('orderDetails.units')}</p>
                                 {item.old_price < item.price ? "" : <h4 className='fontX6'> {item.old_price} {t('orderDetails.EGP')}</h4>}
                                    <h5 className={item.old_price > item.price ? "fontX6 has-dis":"fontX6" }> &nbsp;{item.price} {t('orderDetails.EGP')}</h5>
                                </div>
                                <h3 className='fontX6'>{t('orderDetails.Total')}: <b> {item.price * item.quantity} {t('orderDetails.EGP')}</b><span>x{item.quantity}</span></h3>

                            </div>)}


                    </div>


                </div>
                <div className='col-md-4 col-xs-12 col-sm-12 checkout-side-cont'>

                {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={1}/></div>
                        </SkeletonTheme> :   <div className='checkout-side'>

<ul>
    <h4 className='fontX5'>{t('orderDetails.summary')}</h4>
    <li><h5 className='fontX6'>{t('orderDetails.Total')}</h5><p className='fontX6'>{simplifiedNumber} {t('orderDetails.EGP')}</p></li>
    <li><h5 className='fontX6'>{t('orderDetails.VAT')}</h5><p className='fontX6'>{order.tax_amount*1} {t('orderDetails.EGP')}</p></li>
    <li><h5 className='fontX6'>{t('orderDetails.Delivery')}</h5><p className='fontX6'>{order.delivery_fees*1} {t('orderDetails.EGP')}</p></li>
  {order.discount_amount > 0 ? <li><h5 className='fontX6'>{t('orderDetails.discount')}</h5><p className='fontX6'>{order.discount_amount*1} {t('orderDetails.EGP')}</p></li> : ""}
  {order.manual_labor == 1 ? <li><h5 className='fontX6'>{t('orderDetails.discount')}</h5><p className='fontX6'>{order.manual_labor_cost*1} {t('orderDetails.EGP')}</p></li> : ""}


    <li className={order.discount_amount > 0 ? "has-dis": ""}><h5 className='fontX6'>{t('orderDetails.Grand')}</h5><span
        className='fontX6'></span>
        <p className='fontX6'>{order.total_amount*1} {t('orderDetails.EGP')}</p>
        {order.discount_amount > 0 ?<span className='fontX6'>{order.total_amount*1 + order.discount_amount*1} {t('orderDetails.EGP')}</span> :"" }
        </li>
</ul>

</div> }
{/* {console.log(order)} */}

                </div>

            </div>
            <Footer/>
        </>
    )
}

export default OrderDetails
