import React from 'react'
import {useState, useEffect} from 'react'
import CheckoutSide from './CheckOutSide'
import SiteMap from '../general/siteMap'
import Header from '../general/header'
import Footer from '../general/footer'
import axios from 'axios';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom'
import RootStore from "../../mobx-stores/index";
import {useNavigate} from 'react-router-dom';

const {
    woocommerceStore: {
        Stores: {
            cartStore,
        },
    }
} = RootStore.Stores;






const CheckoutPromo = () => {



    useEffect(() => {
        if(!cartStore?.cart?.selectedAddress?.id){
            window.location.href='/CheckoutAdress';
        }
    }, []);


    useEffect(() => {
        if(cartStore.cart.line_items.length == 0){
            window.location.href='/CartCheckOut';
        }
    }, []);




    let [voucherCode, setVoucherCode] = useState({
        code: ''
    });


    let [errorMsg, setErrorMsg] = useState("");
    let [socMsg, setSocMsg] = useState("");
    const {t, i18n} = useTranslation();


    async function submitFormData(e) {
        e.preventDefault();


        try {
            setErrorMsg('');

            const changeLang = localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == 'ar' ? localStorage.getItem("lang") : 'en'
            const token = localStorage.getItem("token")

            let {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/customer/orders/check-cash-voucher`, voucherCode, {
                headers: {
                    'x-lang-code': changeLang,
                    'Authorization': `Bearer ${token}`
                }
            });


            if (data.status == "ok") {
                 setSocMsg(data.data.value);
                 await cartStore.setPromo(data.data)


            }
        } catch (error) {
            if (error?.response?.data?.message)
                setErrorMsg(error?.response?.data?.message);
        } finally {
            setTimeout(function () {
                setSocMsg(false)
            }, 5000);

            setTimeout(function () {
                setErrorMsg(false)
            }, 5000);


        }


    }


    function getFormDate(e) {
        let myVoucherCode = {...voucherCode};
        myVoucherCode[e.target.name] = e.target.value;
        setVoucherCode(myVoucherCode);
    }


    const navigate = useNavigate();

    function goPay() {
        let path = '/CheckoutPayment'
        navigate(path)
    }




    const setPromoCode = (promo = promp) => {

        cartStore.setPromoCode(promo)
    }


    let [promp, setPromo] = useState({});


    const [promoInputVal, setPromoInputVal] = useState('');

    function SubmitButton() {
        if (promoInputVal) {
            return <button className='text-btn' onClick={(e) => {
                e.preventDefault()
                submitFormData(e)
                setPromoCode()
            }}>{t('checkOut.done')}</button>
        } else {
            return <button disabled className='text-btn' onClick={(e) => {
                e.preventDefault()
                submitFormData(e)
                setPromoCode()
            }}>{t('checkOut.done')}</button>


        }
        ;
    };

    const onHandleChange = (e) => {
        let dataUpdated = {
            ...voucherCode,
            [e.target.name]: e.target.value,
        };
        setVoucherCode(dataUpdated);
    };


    return (
        <>
            <Header hide="hidden-cart"/>
            <SiteMap pageUrl="/CartCheckOut" pageLink={t('checkOut.title')} pageName={t('checkOut.Promocode')}/>

            {/* <CheckoutSide /> */}

            <div className='container cheackout-main'>
                <h1 className='fontX3'>{t('checkOut.title')}</h1>
                <div className='col-lg-7 col-md-6 remove-padding checkout-adress'>
                    <div className='chosen-adress col-xs-12 remove-padding'>
                        <h3 className='fontX4'>{t('checkOut.subTileAdress')}</h3>
                        <Link to="/CheckoutAdress" className='text-btn'>{t('checkOut.change')}</Link>
                        <h5 className='fontX6'> {`${cartStore?.cart?.selectedAddress?.address_title}-`}</h5>
                        <p>{`${cartStore?.cart?.selectedAddress?.street}-${cartStore?.cart?.selectedAddress?.address_title}-`}</p>
                    </div>


                    <form className='promo-form'>


                        <h2 className='fontX3'>{t('checkOut.Promocode')}</h2>
                        <p>{t('checkOut.promoText')}</p>


                        <label>{t('checkOut.Promocode')}</label>
                        <div className='input-main'>
                            <input type="text" name='code' placeholder={t('checkOut.Promocode')}
                                   autoComplete="off"
                                   onChange={e => {
                                       getFormDate(e)
                                       onHandleChange(e);
                                       setPromoInputVal(e)
                                   }}/>
                        </div>


                        <SubmitButton/>
                        <Link className='text-btn' to="/CheckoutPayment">{t('checkOut.Skip')}</Link>
                        <div className='clearfix'></div>
                        {errorMsg && <p className='errorMsg'>{errorMsg}</p>}
                        {socMsg && <p className="socMsg">{t('checkOut.Enjoy')} {socMsg} {t('checkOut.off')}</p>}
                        <div className='clearfix'></div>
                    </form>

                    <ul className='checkout-ul'>
                        <li className='fontX4'>{t('checkOut.Payment')}</li>
                    </ul>


                </div>
                <div className='col-md-4 col-md-offset-1 remove-padding'>


                    <div className='checkout-side'>

                        <CheckoutSide/>

                        <button className='solid-btn' onClick={() => goPay()}>{t('checkOut.Payment')}</button>

                    </div>


                </div>

            </div>

            <Footer/>

        </>
    )
}

export default CheckoutPromo
