import React from 'react'
import { useState, useEffect } from 'react';
import Slider  from "react-slick/lib/slider";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import Axios from 'axios';


export const MySlider = () => {  
  
   
    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

    const [loading, setLoading] = useState(true);


    const [sliderData, setSliderData] = useState([]);

    async function dataApi() { 
        
        let{data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/sliders`,{
  headers: {
    'x-lang-code': changeLang, 
  }
 });
        
        const {data: firstSlider} = brandsResponse;
        setSliderData(firstSlider)
        }


    useEffect(() => {
        dataApi().then(r => {
            setLoading(false);

        })
    }, []);


    const settings = {
        infinite: true,
        speed: 500,
        arrow:true,
        slidesToShow: 1,
        slidesToScroll: 1, 
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    dots: true,

                }
            }]
      };
  
    return (
       <>
<div className='slider-first-main'>

{loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div className='slider-first-loading'><Skeleton count={1}/></div>
                        </SkeletonTheme> : 
                        
                        <Slider className="mySlider" {...settings}>



                        {sliderData.map( (el, index) => 
                        
                        <div className='slider-cont-main' key={index}>
                            <img loading="lazy" src={el.photo_url}/>
                        <div className='container'>
                        <h2 className='fontX1'>{el.title}</h2>
                        <p className='fontX5'>{el.sub_title}</p>
                        </div>
                        <div className='slide-bot'></div>
                        </div>
                        )}
                        
                        
                        
                        
                                </Slider>
                        }

</div>  
       
       </>
    )
}

export default MySlider
