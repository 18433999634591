import React from 'react'
import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import delIcon from '../../imeges/del.svg';

import RootStore from "../../mobx-stores/index";
import {useTranslation} from 'react-i18next';

export const Cart = (props) => {
    const [addQtbBtn, setAddQtbBtn] = useState();
    const {t, i18n} = useTranslation();
    const [isDisabled, setIsDisabled] = useState(false);


    const [refresh, setRefresh] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    window.addEventListener('cart', () => {
        setRefresh(!refresh)
    })
    const [showCart, setShowCart] = useState();

    const [showCartBg, setShowCartBg] = useState();
    const {
        cartStore
    } = RootStore.Stores.woocommerceStore.Stores;

    const [items, setItems] = useState([]);

    useEffect(() => {
        loadData()
    }, [refresh])


    const loadData = () => {
        setItems([...cartStore.cart.line_items])
        setTotalCount(cartStore.totalCount)
    }

    async function addToCart(product) {


        cartStore.addItem(product)

    }

    async function removeFromCart(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price * 1,
            name: product.description,
            actual: product.actual_quantity,
            taxes: product.tax


        };

        cartStore.deleteItem(item)
    }

    async function decreaseItemQuantity(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price * 1,
            name: product.description,
            actual: product.actual_quantity,
            tax: product.tax


        };

        cartStore.removeItem(item)
    }


    // console.log()


    return (
        <>

            <div onClick={() => {
                setShowCart(false);
                setShowCartBg(false)
            }} className={showCartBg ? "cart-bg opend" : "cart-bg "}></div>


            <button onClick={() => {
                setShowCart(true);
                setShowCartBg(true);
                loadData()
            }}>
                <span className='icona-cart'>

                    {totalCount > 0 ? <p>{totalCount}</p> : ""}

                    </span>
                {t('header.cart')}
            </button>

            <div className={showCart ? "showcart cart-main" : "cart-main"}>
                {!totalCount && <div className='empty-cart'>
                    <span className='icona-cart'></span>
                    <h3>{t('header.EmptyCart')}</h3>
                    <Link className='solid-btn' to="/"> {t('header.Continue')}</Link>
                </div>}

                <div className="cart-list">

                    {totalCount > 0 && items?.map((item, index) => (
                        <div className="cart-item" key={index}>
                            <img loading="lazy" src={item.photo}/>
                            <Link to={`/Product/${item.id}`}>{item.name}</Link>
                            <h4>{item.price * 1} {t('product.currency')}</h4>

                            {item.quantity < item.actual_quantity ? "" :
                                <p className='no-items-avalible'><span>{t('productDetails.noMore')}</span></p>}

                            {console.log(item)}

                            <div className='qtn-main'>
                                <button className={item.quantity === 1 ? " icona-minus dis-qtn" : "icona-minus"}
                                        onClick={() => {
                                            decreaseItemQuantity(item).then(r => {
                                            });
                                        }}></button>

                                <h4>{item.quantity}</h4>

                                {item.quantity < item.actual_quantity ?
                                    <button className={isDisabled ? "icona-plus showBtn" : "icona-plus"}
                                            onClick={() => {
                                                addToCart(item).then(r => {
                                                });
                                                item.quantity == 1 ? setAddQtbBtn(false) : setAddQtbBtn(true)

                                            }}></button> : <button className="icona-plus showBtn"></button>}


                            </div>
                            <span className='remove-btn-cart' onClick={() => removeFromCart(item)}><img loading="lazy"
                                                                                                        src={delIcon}/></span>
                            <div className='clearfix'></div>
                            <h3>{t('orders.Total')}:<span>{parseFloat(item.price * item.quantity).toFixed(2) * 1} {t('product.currency')}</span>
                            </h3>
                        </div>


                    ))}
                </div>

                {cartStore.totalCount && <div className='cart-total-price'>
                    <p>{t('orders.Total')}</p>
                    <h3> {cartStore.cartTotals.total} {t('product.currency')}</h3>
                    <div className='clearfix'></div>
                    <Link to={props.url??'cartCheckOut'} className='outline-btn'>{t('header.cheachOutBtn')}</Link>
                </div>}


            </div>


        </>
    )
}

export default Cart
