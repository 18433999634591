import React from 'react'
import logo from '../../imeges/logo.png';
import { Link } from 'react-router-dom';
import arFlg from '../../imeges/eg.png';
import googleD from '../../imeges/googleD.png';
import appleD from '../../imeges/appleD.png';

import faceBookIcn from '../../imeges/facebook.svg';
import instaIcn from '../../imeges/instagram.svg';
// import linkdeinIcn from '../../imeges/linkedin.svg';
// import twitterIcn from '../../imeges/twitter.svg';
import enFlg from '../../imeges/ar.png';
import {changeLanguage} from "../../utils/language";

import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation(); 

      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' 
        });
      };

      const refreshPage = ()=>{
        window.location.reload();
     }


  return (
    <>

      <footer className="container-fluid remove-padding footer-main">
           <div className='upScroll'>
           <div className="container remove-padding">
        <p onClick={scrollToTop}>{t('footer.Scroll')}<span className='iconar-up'></span></p>
        </div>
               </div>
           <div className="container remove-padding">
                <div className="col-md-3 col-sm-3 col-xs-12 fot-logo">
                    
                <Link to="/"><img loading="lazy" src={logo} alt="Logo" /></Link>
                  <h6 className='fotSlog'>{t('footer.fotSlog')}</h6>
                <p>{t('footer.socialTile')}</p>
                     <ul>
                          <li><Link target={"_blank"} to="//facebook.com/ezawaya"><img loading="lazy" src={faceBookIcn} alt="faceBookIcn" /></Link></li>
                          <li><Link target={"_blank"} to="//instagram.com/ezawaya/"><img loading="lazy" src={instaIcn} alt="instaIcn" /></Link></li>
                          {/* <li><Link to="home"><img src={linkdeinIcn} alt="linkdeinIcn" /></Link></li>
                          <li><Link to="home"><img src={twitterIcn} alt="twitterIcn" /></Link></li>                           */}
                     </ul>
                </div>
                <div className="col-md-2 col-sm-2 col-xs-12">
                    <h4 className='fontX6Label'>{t('footer.Ftitle')}</h4>
                    <ul>
                    <li>
                    {token ? <Link to="/Profile">{t('footer.Account')}</Link>:<Link to="/Login">{t('footer.Account')}</Link>}
                      </li>
                    <li>
                    {token ? <Link to="/Orders">{t('footer.Orders')}</Link>:<Link to="/Login">{t('footer.Orders')}</Link>}
                    </li>
                    <li><Link to="/Address">{t('footer.Addresses')}</Link></li>
                    </ul>
                </div>
                <div className="col-md-2 col-sm-2 col-xs-12">
                    <h4 className='fontX6Label'>{t('footer.Stitle')}</h4>
                    <ul>
                    <li><Link to="/Terms">{t('footer.Terms')}</Link></li>
                    {/* <li><Link to="/terms">{t('footer.Privacy')}</Link></li> */}
                    </ul>
                </div>
                <div className="col-md-2 col-sm-2 col-xs-12">
                    <h4 className='fontX6Label'>{t('footer.Ttitle')}</h4>
                    <ul>
                    <li><Link to="/HelpCenter">{t('footer.Help')}</Link></li>
                    <li><Link to="/Faqs">{t('footer.faq')}</Link></li>
                    </ul>
                </div>
                <div className="col-md-3 col-sm-3 col-xs-12">
                    <p>{t('footer.download')}</p>
                <ul>
                          <li><Link target={"_blank"} to="//play.google.com/store/apps/details?id=com.zawaya"><img loading="lazy" src={googleD} alt="googleD" /></Link></li>
                          <li><Link target={"_blank"} to="//apps.apple.com/us/app/zawaya/id6446228530"><img loading="lazy" src={appleD} alt="appleD" /></Link></li>
                     </ul>
                </div>
                
           </div>
<div className='fot-bottom'>
    
    <div className="container">
        <p>{t('footer.copy')}</p>
       
        {i18n.language == "en" ? <button onClick={e => {
                            changeLanguage("ar")
                        }}>العربية<img src={arFlg} alt="arFlg"/></button> : <button onClick={e => {
                          changeLanguage("en")

                        }}>
                            English<img src={enFlg} alt="arFlg"/></button>
                        }
                           
    </div>
    
</div>

      </footer>
    </>
  )
}

export default Footer