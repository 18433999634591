import React, {useEffect} from 'react'
import Header from "../general/header"

import {Helmet} from "react-helmet";
import Footer from "../general/footer";
import queryString from "query-string";
import {json, useNavigate} from "react-router-dom";


export const XpayCallBack = () => {
    const token = localStorage.getItem("token");
    let {transaction_status} = queryString.parse(window.location.search)
    const navigate = useNavigate();

    useEffect(() => {
        window.JSBridge?.showMessageInNative(JSON.stringify({data:queryString.parse(window.location.search)}))
        window.webkit?.messageHandlers?.JSBridge?.postMessage(JSON.stringify({data:queryString.parse(window.location.search)}))
    }, []);

    if (transaction_status===undefined)
        navigate(`/Home`)

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Zawaya Stores</title>
                <meta name="description" content="ezaway application"/>
                <link rel="canonical" href="https://ezawaya.com/"/>
            </Helmet>
            {token ? <Header hide="hidden-head"/> : ''}
            <div className="card-container" style={{height:token?'51vh':'100vh'}}>
                {transaction_status === 'SUCCESSFUL' ?

                    <div className="card">
                        <div className="checkmark-container">
                            <i className="checkmark">✓</i>
                        </div>
                        <h1>Success</h1>
                    </div>
                 :
                    <div className="card-failure">
                        <div className="checkmark-container">
                            <i className="checkmark">✗</i>
                        </div>
                        <h1>Transaction Failed</h1>
                    </div>

            }
            </div>
            {token ? <Footer/> : ''}
        </>
    )
}

export default XpayCallBack
