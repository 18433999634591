import React from 'react'
import { Link } from 'react-router-dom'
const SiteMap = (props) => {
  return (
    <div className="container site-map-main">
         <ul>
             <li><Link className="icona-home" to="/home"></Link> </li>
             <li><span className="iconar-caret-right"></span></li>
             <li><Link to={props.pageUrl}>{props.pageLink}</Link> </li>
             <li className={props.hide}> <span className="iconar-caret-right"></span></li>
             <li><p>{props.pageName}</p></li>

         </ul>
    </div>
  )
}

export default SiteMap