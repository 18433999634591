import React, {useState, useEffect} from 'react'

import SiteMap from '../general/siteMap'
import Header from '../general/header'
import Footer from '../general/footer'
import CheckoutSide from './CheckOutSide'

import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';
import {Link, useNavigate} from 'react-router-dom'
import RootStore from "../../mobx-stores/index";
import {useTranslation} from 'react-i18next';
import ar from 'date-fns/locale/ar';

const {
    woocommerceStore: {
        Stores: {
            cartStore,
        },
    }
} = RootStore.Stores;


const CheckoutData = () => {



useEffect(() => {
    if(!cartStore?.cart?.selectedCashAddress?.id){
        window.location.href='/CheckoutDownPayment';
    }
}, []);

    useEffect(() => {
        if(!cartStore?.cart?.selectedAddress?.id){
            window.location.href='/CheckoutAdress';
        }
    }, []);


    useEffect(() => {
        if(cartStore.cart.line_items.length == 0){
            window.location.href='/CartCheckOut';
        }
    }, []);



    const {t, i18n} = useTranslation();

    const [times, setTimes] = useState([]);
    const [selectedTime, setSelectedTime] = useState([]);
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false)
    const [selectedDate, setSelectedDate] = useState();


    const getFormattedDate = (date) => {

        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let dd = String(date.getDate()).padStart(2, '0');
        let yy = date.getFullYear()

        return `${yy}-${mm}-${dd}`;


    }

    const update = (date) => {

    

        const formattedDate = getFormattedDate(date);
        cartStore.setTime("")
        setChecked(false)
        setLoading(true)
        const changeLang = localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == 'ar' ? localStorage.getItem("lang") : 'en'
        
        cartStore.setDate(formattedDate)
        setSelectedDate(formattedDate)
        Axios
            .get(`${process.env.REACT_APP_BASE_URL}/api/customer/slots?date=${formattedDate}`, {
                headers: {
                    'x-lang-code': changeLang,
              


                }
            })
            .then((res) => {
                setTimes(res.data);
                setLoading(false)
            });
    };

    useEffect(() => {
        update(new Date())
    }, []);

    const [reviewBtn, setReviewBtn] = useState('');

    function SubmitButton() {
        if (reviewBtn) {
            return <button className='solid-btn' onClick={goReview}>{t('checkOut.review')}</button>
        } else {
            return <button disabled className='solid-btn' onClick={goReview}>{t('checkOut.review')}</button>


        }
        ;
    };


    const navigate = useNavigate();

    function goReview() {
        let path = '/OrderReview'
        navigate(path)
    }

    const toggleChecked = (status) => {
        if (status === "on")
            setChecked(true)
        else
            setChecked(false)
    }


    const changeTime = (time = selectedTime) => {
        cartStore.setTime(time)

    } 


    if (!times) return null;
    return (
        <>
            <Header hide="hidden-cart"/>
            <SiteMap pageUrl="/CartCheckOut" pageLink={t('checkOut.title')} pageName={t('checkOut.dateTime')}/>


            <div className='container cheackout-main'>
                <h1 className='fontX3'>{t('checkOut.title')}</h1>
                <div className='col-lg-7 col-xs-12 col-md-6 remove-padding checkout-adress'>


                    <div className='chosen-adress col-xs-12 remove-padding'>
                        <h3 className='fontX4'>{t('checkOut.subTileAdress')}</h3>
                        <Link to="/CheckoutAdress" className='text-btn'>{t('checkOut.change')}</Link>
                        <h5 className='fontX6'>
                            {`${cartStore?.cart?.selectedAddress?.address_title}-`}

                        </h5>
                        <p>{`${cartStore?.cart?.selectedAddress?.street}-${cartStore?.cart?.selectedAddress?.address_title}-`}</p>
                    </div>

                    <div className='chosen-adress col-xs-12 remove-padding'>
                        <h3 className='fontX4'>{t('checkOut.Promocode')}</h3>
                        <Link to="/CheckoutPromoCode" className='text-btn'>{t('checkOut.change')}</Link>
                        <h5 className='fontX6'>{t('checkOut.Promocode')}</h5>
                        <p>{`${cartStore?.cart?.promo?.code ?? '__'}`}</p>
                    </div>

                    <div className='chosen-adress col-xs-12 remove-padding'>
                        <h3 className='fontX4'>{t('checkOut.Payment')}</h3>
                        <Link to="/CheckoutPayment" className='text-btn'>{t('checkOut.change')}</Link>
                        <div className='clearfix'></div>
                        <p>{t('checkOut.Cash')}</p>
                    </div>


                    <div className='col-xs-12 remove-padding cash-del-adres'>
                        <h3 className='fontX3'>{t('checkOut.Downpayment')}</h3>
                        <p>{t('checkOut.AdressInfo')}</p>
                        <h4 className='fontX5'>{t('checkOut.DownPaymentAdress')}</h4>
                        <h5 className='fontX6'>             {`${cartStore?.cart?.selectedAddress?.address_title}-`}
                        </h5>
                        <p>{`${cartStore?.cart?.selectedCashAddress?.street}-${cartStore?.cart?.selectedCashAddress?.address_title}-`}</p>

                        <div className='selectDate'>
                            <label>{t('checkOut.dateTime')}</label>
                            <DatePicker
                                selected={selectedDate?new Date(selectedDate):new Date()}
                                // isClearable
                                locale={i18n.language == "ar" ? ar : ""}
                                minDate={new Date()}
                                dateFormat="yyyy-MM-dd"
                                onChange={(date) => {
                                    update(date)
                                }}


                            />


                        </div>
                        {/* <button className='setDateBtn' onClick={(e)=>update(e)}>set date</button> */}
                        <div className='times-main'>
                            {times.data && times.data.length > 0 ? <div>

                                {times.data && times.data.map((item, index) =>
                                    <div id='times' className='col-xs-12 radio-main' key={index}>
                                        <input type="radio" onChange={(e) => {
                                            setReviewBtn(e);
                                            changeTime(item);
                                            toggleChecked(e.target.value)
                                        }} id={item.id} name={`time`}/>
                                        <span className='checkmark'></span>
                                        <label
                                            htmlFor={item.id}>{t('checkOut.from')} {item.from} {t('checkOut.to')} {item.to}</label>
                                    </div>)}
                            </div> : <p className='emptyTime'><b>{t('checkOut.noDate')}</b></p>}


                            {loading ? <div className='loadingMain'>
                                <div className="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div> : ""}

                        </div>
                    </div>


                </div>
                <div className='col-md-4 col-xs-12 col-md-offset-1 remove-padding'>
                    <div className='checkout-side'>

                        <CheckoutSide/>


                        <SubmitButton/>


                    </div>
                </div>

            </div>


            <Footer/>

        </>
    )
}

export default CheckoutData
