import React from 'react';
// import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';

import './css/bootstrap.css';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import './i18n';
import i18n from "i18next";
import {changeLanguage} from "./utils/language";

const root = createRoot(document.getElementById("root"));
if (!i18n.language) {
    changeLanguage('ar')
}

root.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
);
reportWebVitals();


