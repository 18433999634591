import {
  RootStore,
} from 'mobx-stores/index';
import CartStore from './cartStore';

export class Store {

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  Stores = {
    cartStore: new CartStore(this),

  };
}

export default Store;
