import React  from 'react';
import ReactDOM from 'react-dom';

export default class SearchBox extends React.Component {


    render() {
        const {onPlacesChanged, ...rest} = this.props
        return <input ref="input" id='search'  {...rest} placeholder={this.props.default=='undefined'?'':this.props.default} type="text"/>;
    }

    onPlacesChanged = () => {
        if (this.props.onPlacesChanged) {
            this.props.onPlacesChanged(this.searchBox.getPlaces());
        }
    }

    searchChanged() {
        if (localStorage.getItem('address')!=='undefined'){
            document.querySelector('#search').value =localStorage.getItem('address')
        }
    }

    componentDidMount() {
        window.addEventListener('address', this.searchChanged)
        var input = ReactDOM.findDOMNode(this.refs.input);
        this.searchBox = new this.props.google.maps.places.SearchBox(input);
        this.searchBox.addListener('places_changed', this.onPlacesChanged);
        this.searchChanged()
    }

    componentWillUnmount() {
        // https://developers.google.com/maps/documentation/javascript/events#removing
        this.props.google.maps.event.clearInstanceListeners(this.searchBox);
    }
}
