import React from 'react'
import {useState, useEffect} from 'react';
import BannerCate from './bannerCate'
import Header from '../general/header'
import Footer from '../general/footer'
import {Link, useLocation, useParams} from 'react-router-dom';
import productStore from "../../mobx-stores/stores/productStore/productStore";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import RootStore from "../../mobx-stores/index";
import {useTranslation} from 'react-i18next';
import placeHolder from '../../imeges/placeHolder.png';
import noResults from '../../imeges/no-results.png';
import queryString from 'query-string';
import ReactPaginate from 'react-paginate';


const {
    woocommerceStore: {
        Stores: {
            cartStore,

        },
    }
} = RootStore.Stores;

const SubCategory = () => {
    const {categoryId} = useParams()
    const {t} = useTranslation();
    const [pageCount, setPageCount] = useState(0);
    const [pagination, setPagination] = useState({});

    const [products, setProduct] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
    const [allCategories, setAllCategories] = useState([]);
    const [subList, setSubList] = useState([]);
    const [loadingG, setLoadingG] = useState(true);
    const [loadingSG, setLoadingSG] = useState(true);
    const [loadingSGP, setLoadingSGP] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);


    const [CartItems, setCartItems] = useState([]);
    const token = localStorage.getItem("token");
    let {sub} = queryString.parse(window.location.search)


    useEffect(() => {
        if (products?.length) {
            setPageCount(Math.ceil(pagination?.total / pagination?.per_page));
        }
    }, [products, pagination])

    useEffect(() => {
        // console.log(selectedSubCategoryId)
        if (allCategories && allCategories.length) {
            if (selectedSubCategoryId) {
                getSubCategoriesList(selectedCategoryId).then(r => {
                })
            }
            if (selectedSubCategoryId) {
                getProductBySub(selectedSubCategoryId, currentPage).then(r => {
                })
            }
        }
    }, [allCategories]);
    useEffect(() => {
        console.info('cat changed')
        if (!selectedSubCategoryId && subList.length) {
            setSelectedSubCategoryId(subList[0].id)
            getProductBySub(subList[0].id, currentPage).then(r => {
            })
        }

    }, [subList]);

    async function dataApi() {

        const allCategories = await productStore.getAllCategories();

        if (allCategories && allCategories.data && allCategories.data.data && allCategories.data.data.list) {
            setAllCategories(allCategories.data.data.list)
        }


    }

    useEffect(() => {

        setSelectedSubCategoryId(sub)
        setSelectedCategoryId(categoryId)

        dataApi().then(r => {


        })
    }, []);

    async function getSubCategoriesList(id) {
        // console.info("id")
        // console.info(id)
        setLoadingSGP(true)

        setSelectedCategoryId(id)
        // console.log(subList[0].id)

        setProduct([])
        const subCategories = await productStore.getSubCategoriesList(id)
        if (subCategories && subCategories.data && subCategories.data.data && subCategories.data.data.list) {

            setSubList(subCategories.data.data.list)


            setLoadingSG(false)
            setLoadingG(false)
            setLoadingSGP(false)

        }


    }

    async function getProductBySub(id, cPage) {
        // console.info("cPage")
        // console.info(cPage)
        setLoadingSGP(true)
        setSelectedSubCategoryId(id)
        setProduct([])
        const products = await productStore.getSubCategoriesProducts(id, cPage)
        if (products && products.data && products.data.data && products.data.data.list) {
            setProduct(products.data.data.list)
            setPagination(products.data.data.paginator)
            setLoadingSGP(false)
        }


    }

    async function addToCart(product) {


        cartStore.addItem(product)

    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
        getProductBySub(selectedSubCategoryId, event.selected + 1).then(r => {
        })
    };

    window.addEventListener('cart', () => {
        setCartItems([...cartStore.cart.line_items])
    })

    const [addQtbBtn, setAddQtbBtn] = useState();

    async function removeFromCart(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.description

        };

        cartStore.deleteItem(item)
    }


    async function decreaseItemQuantity(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.description

        };

        cartStore.removeItem(item)
    }


    return (


        <>

            <Header/>
            <BannerCate/>


            <div className='container-fluid cate-filter remove-padding'>
                <div className='col-xs-12 remove-padding'>
                    {loadingG ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                        <div><Skeleton count={8}/></div>
                    </SkeletonTheme> : <ul>
                        {allCategories ? allCategories.map((category) => (<li key={category.id}>
                            <button className={selectedCategoryId == category.id ? 'active' : ''}
                                    onClick={(event) => {
                                        setSelectedSubCategoryId(category.id)
                                        setSelectedSubCategoryId(null)
                                        getSubCategoriesList(category.id).then(r => {
                                        })
                                    }}>{category.name}</button>
                        </li>)) : ''}


                    </ul>}
                </div>
            </div>

            <div className='container remove-padding '>

                <div className='profilter-main col-xs-12 remove-padding'>

                    {loadingSG ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                        <div><Skeleton count={6}/></div>
                    </SkeletonTheme> : <ul>

                        {subList ? subList.map((sub) => (<li key={sub.id} id={sub.id}>
                            <button className={selectedSubCategoryId == sub.id ? 'active' : ''}
                                    onClick={() => {
                                        getProductBySub(sub.id, currentPage).then(r => {
                                        })
                                    }}>{sub.name}</button>
                        </li>)) : ''}
                    </ul>}


                </div>

            </div>


            <div className="container remove-padding products-main filter-product-main">
                {loadingSGP ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                    <div><Skeleton count={4}/></div>
                </SkeletonTheme> : <div>
                    {products.length > 0 ? <div>
                        {products.map((item, index) => {
                            let existed = 0;

                            if (CartItems) {
                                existed = CartItems.find(
                                    cartItem => cartItem.id === item.id
                                )

                            }
                            return <div className='col-md-3 col-sm-6 col-xs-6 product-item fileredItem' key={index}>

                                <div className='col-xs-12 remove-padding'>


                                    {item.discount_percentage <= 0 ?
                                        "" : <span
                                            className="offer-label">{t('product.save')} {item.discount_percentage} %</span>}

                                    <div className='img-frame'><img loading="lazy" src={item.photo_url || placeHolder}
                                                                    alt="product name"/></div>

                                    {token ?


                                        <div>

                                            {!existed ?
                                                <button
                                                    onClick={() => addToCart(item)}
                                                >  {t('product.addCart')}
                                                </button> :

                                                <div className='qtn-main'>

                                                    {existed.quantity < item.actual_quantity ? "" :
                                                        <p className='no-items-avalible'>
                                                            <span>{t('productDetails.noMore')}</span></p>}

                                                    <button
                                                        className={existed.quantity == 1 ? "icona-minus dis-qtn" : "icona-minus"}
                                                        onClick={() => {
                                                            decreaseItemQuantity(item).then(r => {
                                                            });

                                                        }}></button>

                                                    <h4>{existed.quantity}</h4>
                                                    {existed.quantity < item.actual_quantity ? <button
                                                        className={isDisabled ? "icona-plus showBtn" : "icona-plus"}
                                                        onClick={() => {
                                                            addToCart(item).then(r => {
                                                            });


                                                        }}></button> : <button className="icona-plus showBtn"></button>}


                                                    <span className='icona-minus'
                                                          onClick={() => removeFromCart(item)}></span>
                                                </div>
                                            }

                                        </div>

                                        : <Link className='go-log-in-link' to="/login">{t('product.addCart')}</Link>}
                                    <Link className="fontX6" to={`/Product/${item.id}`}>{item.name}</Link>
                                    {/* <h3 className={item.old_price !== 0 ? "fontX6 has-offer" : "fontX6"}>
                                        <b>{item.price} {t('product.currency')}</b>
                                        <span>{item.old_price === 0 ? "" : item.old_price} {t('product.currency')}</span>
                                    </h3> */}

                                    <h3 className={item.discount_percentage <= 0 ? "fontX6" : "fontX6 has-offer"}>
                                        <b>{item.price} {t('product.currency')}</b>
                                        <span>{item.discount_percentage <= 0 ? "" : `${item.old_price} ${t('product.currency')}`} </span>
                                    </h3>

                                    <h5>{t('product.per')} {item.unit}</h5>
                                </div>
                            </div>
                        })}
                    </div> : <div className='no-results-main'>
                        <img loading="lazy" src={noResults} alt="no Results"/>
                        <h4>{t('NotFound.Products')}</h4>
                        <Link className='solid-btn' to={`/`}>{t('checkOut.Continue')}</Link>
                    </div>}

                </div>}


                <div className='col-xs-12 remove-padding pagination'>
                    {pageCount > 1 ? <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}

                    /> : ''}
                </div>
            </div>
            <Footer/>
        </>)
}

export default SubCategory
