import React from 'react'
// import bannerImg from '../../imeges/5.png';
import { useState, useEffect } from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

import Axios from 'axios';





export const SecondBanner = () => {  

   const [SecondSlider, setSecondSlider] = useState([]);
   const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'
   const [loading, setLoading] = useState(true);

   async function dataApi() { 
       
       let{data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/home/banners/second`,{
 headers: {
   'x-lang-code': changeLang, 
 }
});
       
       const {data: SecondSlider} = brandsResponse;
       setSecondSlider(SecondSlider)
       }


   useEffect(() => {
       dataApi().then(r => {
        setLoading(false);

       })
   }, []);

   






   
   
    return (
       <>





<div className='container-fluid bannerLoading remove-padding'>
{loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={1}/></div>
                        </SkeletonTheme> : <div className='container-fluid Banner-main remove-padding' >

<img loading="lazy" src={SecondSlider.photo_url}/>
<div className='container'>
<h2 className='fontX2'>{SecondSlider.name}</h2>
<p className='fontX4'>{SecondSlider.desc}</p>

</div>
<span></span>
</div>}
</div>




       
       </>
    )
}

export default SecondBanner
