import React from 'react'
import { useState } from 'react';
// import headerL from '../general/header'
import Header from "../general/header"
import Slider from './firstSlider'
import Footer from '../general/footer'
import Search from './serach'
import Categories from './Categories'
import FirstBanner from './firstBanner'
import SecondBanner from './secondBanner'

import WhyUs from './whyUs'
import HomeProducts from './homeProducts';
import Subscribe from './Subscribe'
import PartSlider from './Partner'
// import "../Home/searchHide.css"

// import BathRoomWare from './bathRoomWare'

import MainInfo from "../general/mainInfo"
import {Helmet} from "react-helmet";

// import Products from '../general/Products'
export const Home = () => {



  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zawaya Stores</title>
        <meta name="description" content="ezaway application" />
        <link rel="canonical" href="https://ezawaya.com/" />
      </Helmet>
    <Header hide="hidden-head"/>
    <Slider/>
    <Search/>
    <Categories/>
    <FirstBanner/>


     <MainInfo />
    <PartSlider/>
    <SecondBanner/>
    <HomeProducts />

    {/* <BathRoomWare/> */}
    <WhyUs/>
    <Subscribe/>
    <Footer/>




    </>
  )
}

export default Home
