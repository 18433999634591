import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';

import CheckoutSide from './CheckOutSide'
import SiteMap from '../general/siteMap'
import Header from '../general/header'
import Footer from '../general/footer'
import Axios from 'axios';
import {useTranslation} from 'react-i18next';

import { Link } from 'react-router-dom'
import RootStore from "../../mobx-stores/index";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

const {
  woocommerceStore: {
      Stores: {
          cartStore,
      },
  }
} = RootStore.Stores;

const CheckoutPaymentCash = () => {



    useEffect(() => {
        if(!cartStore?.cart?.selectedAddress?.id){
            window.location.href='/CheckoutAdress';
        }
    }, []);

    useEffect(() => {
      if(cartStore?.cart?.PaymentMethod == null){
          window.location.href='/CheckoutPayment';
      }
  }, []);


    useEffect(() => {
        if(cartStore.cart.line_items.length == 0){
            window.location.href='/CartCheckOut';
        }
    }, []);



// console.log(cartStore?.cart?.PaymentMethod)


  const {t, i18n} = useTranslation();
  const [defaultActive, setDefaultActive] = useState(false);

  const navigate = useNavigate();

    function goChoseDate() {
        let path = '/CheckoutDownPaymentDate'
        navigate(path)
    }






  const [showPop, setShowPop] = useState();
  const [loading, setLoading] = useState(true);


  const [Adress, setAdress] = useState([]);

  async function dataApi() {
      const token = localStorage.getItem("token")
    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

      let{data: AdressResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/addresses`,{
headers: {
  'x-lang-code': changeLang,
  'Authorization':`Bearer ${token}`
}
});
      const {data: Adress} = AdressResponse;
      setAdress(Adress)
      if (Adress.length)
      cartStore.setSelectedCashAddress(Adress[0])
      }


  useEffect(() => {
      dataApi().then(r => {
        setLoading(false);

      })
  }, []);


  const changeCashAddress = (address) => {
    cartStore.setSelectedCashAddress(address)

}

  return (
    <>
    <Header hide="hidden-cart"/>
    <SiteMap pageUrl="/CartCheckOut"  pageLink={t('checkOut.title')} pageName={t('checkOut.Downpayment')}/>



    <div className='container cheackout-main'>
    <h1 className='fontX3'>{t('checkOut.title')}</h1>
         <div className='col-lg-7 col-xs-12 col-md-6 remove-padding checkout-adress cash-address-main'>



           <div className='chosen-adress col-xs-12 remove-padding'>
             <h3 className='fontX4'>{t('checkOut.subTileAdress')}</h3>
             <Link to="/CheckoutAdress" className='text-btn'>{t('checkOut.change')}</Link>
             <h5 className='fontX6'>
             {`${cartStore?.cart?.selectedAddress?.address_title}-`}
             </h5>


             <p>{`${cartStore?.cart?.selectedAddress?.street}-${cartStore?.cart?.selectedAddress?.address_title}-`}</p>
           </div>

           <div className='chosen-adress col-xs-12 remove-padding'>
             <h3 className='fontX4'>{t('checkOut.Promocode')}</h3>
             <Link to="/CheckoutPromoCode" className='text-btn'>{t('checkOut.change')}</Link>
             <h5 className='fontX6'>{t('checkOut.Promocode')}</h5>
             {/* <p>{userPromo}</p> */}
             {`${cartStore?.cart?.promo?.code??'__'??'__'}`}

           </div>

           <div className='chosen-adress col-xs-12 remove-padding'>
             <h3 className='fontX4'>{t('checkOut.Payment')}</h3>
             <Link to="/CheckoutPayment" className='text-btn'>{t('checkOut.change')}</Link>
            <div className='clearfix'></div>
             <p>{t('checkOut.Cash')}</p>
           </div>



          <div className='col-xs-12 remove-padding cash-del-adres'>
              <h3 className='fontX3'>{t('checkOut.Downpayment')}</h3>
              <p>{t('checkOut.AdressInfo')}</p>
              {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={2}/></div>
                        </SkeletonTheme> :           <form>

<h5>{t('checkOut.Adress')}</h5>

{Adress && Adress.map((item, index) =>
<div className="radio-label adress-item" key={index}>
<input defaultChecked={item.is_default == 1 ||(!defaultActive&&index==0)? true : false}
id={item.id}
onClick={() => changeCashAddress(item)}
type="radio" className="space"
name="selectedCashAddress" />
<div className="bg"></div>
<label id={item.id}>{item.address_title}</label>
<p>{t('Adress.street')} {item.street}, {t('Adress.building')} {item.building_number}, {t('Adress.floor')} {item.floor_number}, {item.flat_number} {t('Adress.Phone')} {item.phone_number}</p>

</div>)}

<Link to="/addAdress" className='outline-btn'><span className='icona-plus'></span> {t('checkOut.newAdress')}</Link>
<button onClick={() => goChoseDate()} className='solid-btn'>{t('checkOut.useAdress')}</button>
</form>}


            </div>


            <ul className='checkout-ul'>
     <li className='fontX4'>{t('checkOut.dateTime')}</li>
</ul>



         </div>
         <div className='col-md-4 col-xs-12 col-md-offset-1 remove-padding'>
         <div className='checkout-side'>

         <CheckoutSide/>

<button className='solid-btn' onClick={() => goChoseDate()}>{t('checkOut.dateTime')}</button>

</div>

         </div>

        </div>

<div className={showPop  ? "pop-addcard-main" : " showPop"} >
 <div className='pop-addcard '>
    <h2 className='fontX3'>Add credit/debit card</h2>
    <form>
        <div className='input-main col-md-6 col-xs-12'>
           <p>Card number</p>
           <input placeholder='enter the card  16 digits' type="text"/>
        </div>
        <div className='input-main col-md-6 col-xs-12'>
           <p>Name on card</p>
           <input placeholder='enter  card name' type="text"/>
        </div>
        <div className='input-main col-md-6 col-xs-12'>
           <p>Expirational date</p>
           <input placeholder='00/00' type="text"/>
        </div>
        <div className='col-xs-12 remove-padding'>
    <div className='col-md-6 col-xs-12'>
    <span className='outline-btn'  onClick={() => setShowPop(false)}>close</span>
    </div>
    <div className='col-md-6 col-xs-12'>
    <button className='solid-btn'>Add card</button>
    </div>
    </div>
    </form>



 </div>
 <div className='bg-pop' onClick={() => setShowPop(false)}></div>

</div>

        <Footer/>

        </>
  )
}

export default CheckoutPaymentCash
