const baseUrl = `${process.env.REACT_APP_BASE_URL}/api`;
export const UpdateCreateAddress = baseUrl + '/customer/addresses';
export const social = (provider) => baseUrl + `/customer/auth/${provider}/login`;
export const register = baseUrl + '/customer/auth/signup';
export const checkLat = baseUrl + '/customer/addresses/check-location';

export const UpdateName = baseUrl + '/customer/profile';
export const searchProduct = (search) => baseUrl + `/customer/search/autocomplete?search_word=${search}`;
export const autoCompleteSearchProduct = (search) => baseUrl + `/customer/products/search?search_name=${search}`;
export const searchWord = baseUrl + '/customer/products';
export const createOrder = baseUrl + '/customer/orders';
export const getProducts = baseUrl + '/customer/products';
export const getAllCategories = baseUrl + '/customer/categories';
export const getSubCategories = (categoryId) => baseUrl + `/customer/categories?per_page=10&parent_category_id=${categoryId}`;
export const UpdateEmail = baseUrl + '/customer/profile';
export const UpdatePhone = baseUrl + '/customer/profile';


export const login = baseUrl + '/customer/auth/login';


export const deleteRequest = (address_id) => baseUrl + `/customer/addresses/${address_id}`;
export const deleteRequestTicket = (tickets_id) => baseUrl + `/customer/tickets/${tickets_id}`;
export const getAddress = (address_id) => baseUrl + `/customer/addresses/${address_id}`;

