import React from 'react'
import CheckoutSide from './CheckOutSide'
import SiteMap from '../general/siteMap'
import Header from '../general/header'
import Footer from '../general/footer'
import {useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import RootStore from "../../mobx-stores/index";
import orderStore from "../../mobx-stores/stores/orderStore/index";
import {IPAYMENT_METHOD} from "../../mobx-stores/stores/orderStore/types";
import {useTranslation} from 'react-i18next';


const {
    woocommerceStore: {
        Stores: {
            cartStore,
        },
    }
} = RootStore.Stores;

const CheckoutPayment = () => {

const [cowPayMsg, setCowPayMsg] = useState("")



window.addEventListener('message', function (e) {
  
    if (e.data && e.data.message_source === 'cowpay') {


        let paymentStatus = e.data.payment_status;
        setCowPayMsg(paymentStatus)
        const a = document.createElement('a');
        a.innerHTML += 'cancel';
        a.setAttribute('href', `orderDetails`);
        const orderPopId = localStorage.getItem('activeOrder')
        if (paymentStatus == 'PAID')
        window.document.getElementById('cowpay-otp-container')
        .innerHTML +=(`<div class= "pop-addcard-main">
        <div class='pop-addcard order-done-pop'>
             <span class='icona-check'></span>
             <h2 class='fontX3'>${t('checkOut.placedOrder')}</h2>
             <h5>${t('checkOut.PaymentCompleted')}</h5>
             <p class='fontX5'>${t('checkOut.OrderNumber')} ${orderPopId}</p>
             <ul>
               <li><a href="/" class='solid-btn'>${t('checkOut.Continue')}</a></li>
                <li><a href="/orderDetails/${orderPopId}" class='outline-btn'>${t('checkOut.orderDetails')}</a></li>
            </ul>
        </div><div class='bg-pop order-done-bg'></div></div>`)
            // window.location.href = `orderComplatedTrue`
            
        else

        window.document.getElementById('cowpay-otp-container')
        .innerHTML +=(`<div class= "pop-addcard-main">
        <div class='pop-addcard order-done-pop'>
             <span class='icona-info-circle'></span>
             <h2 class='fontX3'>${t('checkOut.waitPaymentMsg')}</h2>
             <h5>${t('checkOut.PaymentFailed')}</h5>
             <p class='fontX5'>${t('checkOut.OrderNumber')} ${orderPopId}</p>
             <ul>
               <li><a href="/" class='solid-btn'>${t('checkOut.Continue')}</a></li>
                <li><a href="/orderDetails/${orderPopId}" class='outline-btn'>${t('checkOut.orderDetails')}</a></li>
            </ul>
        </div><div class='bg-pop order-done-bg'></div></div>`)

            // window.location.href = `orderComplatedFalse`

    }

}, false);

    useEffect(() => {
        if(!cartStore?.cart?.selectedAddress?.id){
            window.location.href='/CheckoutAdress';
        }
    }, []);


    useEffect(() => {
        if(cartStore.cart.line_items.length == 0){
            window.location.href='/CartCheckOut';
        }
        setPaymentMethod(paymentMethod)
    }, []);

    const {t, i18n} = useTranslation();

    const navigate = useNavigate();

    async function goCash() {


        if (paymentMethod == IPAYMENT_METHOD.card) {
            await createOrder()
        } else {
            let path = '/CheckoutDownPayment'
            navigate(path)
        }

    }

    const [showPop, setShowPop] = useState();
    const [showDonePop, setShowDonePop] = useState();

    const [payWay, setPayWay] = useState();

    const [paymentMethod, SetPaymentMethod] = useState(IPAYMENT_METHOD.cash);



const setPaymentMethod = (payment) => {
    SetPaymentMethod(payment)
    cartStore.setPaymentMethodCridet(payment)
}

    function handleClick(e) {
        if (e.defaultPrevented) return  // Exits here if event has been handled
        e.preventDefault()
    }


    const createOrder = async () => {

        let res = await orderStore.createOrder()
        console.info('create order res')
        console.info(res)
        if (paymentMethod == IPAYMENT_METHOD.card && res.cowpay_token) {
            localStorage.setItem('activeOrder', res.orderId)
            await window.COWPAYOTPDIALOG.init()
            await window.COWPAYOTPDIALOG.load(res.cowpay_token)
            const a = document.createElement('a');
            a.innerHTML += 'cancel';
            a.setAttribute('href', `orderDetails`);
            window.document.getElementById('cowpay-otp-container')
            .innerHTML +=  `
            <div class="order-done-caypay">
            <p>${t('checkOut.placedOrder')}</p>
            <a href="/orderDetails/${res.orderId}"  class="go-to-order-pop-btn"> ${t('orders.trackOrder')} </a>
            </div>
            `
        }
        if (paymentMethod == IPAYMENT_METHOD.card && res.iframe_url) {
            console.info('xpay url')
            console.info(res.iframe_url)
            localStorage.setItem('activeOrder', res.orderId)
            // await window.COWPAYOTPDIALOG.init()
            // await window.COWPAYOTPDIALOG.load(res.cowpay_token)
            const a = document.createElement('a');
            a.innerHTML += 'cancel';
            a.setAttribute('href', `orderDetails`);
            window.document.getElementById('cowpay-otp-container')
            .innerHTML +=  `
            <div class="order-done-caypay">
              <iframe
        width="100%"
        height="1000"
        title="payment"
        src="${res.iframe_url}"
                />
            </div>
            `
        }

        if ( res.status && res.orderId)
            // navigate(`/orderDetails/${res.orderId}`)
            setShowDonePop(false)
    }





 






    return (
        <>
        {/* {console.log("cowPayMsg")} */}

        {/* {console.log(cowPayMsg)} */}
            <Header hide="hidden-cart"/>
            <SiteMap pageUrl="/CartCheckOut" pageLink={t('checkOut.title')} pageName={t('checkOut.title')}/>
            <div className='container cheackout-main'>
                <h1 className='fontX3'>{t('checkOut.title')}</h1>
                <div className='col-lg-7 col-md-6 remove-padding checkout-adress'>
                    <div className='chosen-adress col-xs-12 remove-padding'>
                        <h3 className='fontX4'>{t('checkOut.subTileAdress')}</h3>
                        <Link to="/CheckoutAdress" className='text-btn'>{t('checkOut.change')}</Link>
                    </div>


                    <div className='chosen-adress col-xs-12 remove-padding promo-shown'>
                        <h3 className='fontX4'>{t('checkOut.Promocode')}</h3>
                        <Link to="/CheckoutPromoCode" className='text-btn'>{t('checkOut.change')}</Link>
                        <h5 className='fontX6'>

                            {`${cartStore?.cart?.promo?.code ?? '__'}`}
                        </h5>

                        <p>


                        </p>
                    </div>


                    <form className='payment-form'>

                        <h2 className='fontX3'>{t('checkOut.Payment')}</h2>
                        <div className="radio-label">
                            <input id='1' defaultChecked={true} 
                            value={IPAYMENT_METHOD.cash}
                            onChange={(e) => {
                                setPaymentMethod(IPAYMENT_METHOD.cash);
                              
                            }}
                             type="radio" className="space" name="method"/>
                            <label  htmlFor='1'>{t('checkOut.Cash')} </label>
                        </div>

                        <div className="radio-label ">
                            <input id='2' value={IPAYMENT_METHOD.card} onChange={(e) => {
                                setPaymentMethod(IPAYMENT_METHOD.card)
                               
                            }} type="radio" className="space" name="method"/>
                            <label htmlFor='2'>{t('checkOut.Credit')} </label>
                        </div>

                        {/* <div className="radio-label">
                            <input id='3' onChange={() => {
                                setPaymentMethod(IPAYMENT_METHOD.credit_card_on_delivery)
                             
                            }}  value={IPAYMENT_METHOD.credit_card_on_delivery} type="radio" className="space" name="method"/>
                            <label htmlFor='3'>{t('checkOut.delivery')} </label>
                        </div> */}

                      

                        {/* <Link to="" onClick={() => setShowPop(true)} className='solid-btn bank-pay'> <span
                            className='icona-plus'></span>{t('checkOut.Credit')}</Link> */}


                    </form>

                    {paymentMethod == IPAYMENT_METHOD.card ?
                       <button className='solid-btn' onClick={goCash}>{t('checkOut.useDownPayBtn')}</button>
                        :<button className='solid-btn' onClick={goCash}>{t('checkOut.downPayBtn')}</button> }


                </div>
                <div className='col-md-4 col-md-offset-1 remove-padding'>
                    <div className='checkout-side'>

                        <CheckoutSide/>

                        {paymentMethod == IPAYMENT_METHOD.card ?
                        <button className='solid-btn' onClick={goCash}>{t('checkOut.useDownPayBtn')}</button>
                        :<button className='solid-btn' onClick={goCash}>{t('checkOut.downPayBtn')}</button>}

                       

                    </div>


                </div>

            </div>

            <div className={showPop ? "pop-addcard-main" : " showPop"}>
                <div className='pop-addcard '>
                    <h2 className='fontX3'>Add credit/debit card</h2>
                    <form>
                        <div className='input-main col-md-6 col-xs-12'>
                            <p>Card number</p>
                            <input placeholder='enter the card  16 digits' type="text"/>
                        </div>
                        <div className='input-main col-md-6 col-xs-12'>
                            <p>Name on card</p>
                            <input placeholder='enter  card name' type="text"/>
                        </div>
                        <div className='input-main col-md-6 col-xs-12'>
                            <p>Expirational date</p>
                            <input placeholder='00/00' type="text"/>
                        </div>
                        <div className='col-xs-12 remove-padding'>
                            <div className='col-md-6 col-xs-12'>
                                <span className='outline-btn' onClick={() => setShowPop(false)}>close</span>
                            </div>
                            <div className='col-md-6 col-xs-12'>
                                <button className='solid-btn'>Add card</button>
                            </div>
                        </div>
                    </form>


                </div>
                <div className='bg-pop' onClick={() => setShowPop(false)}></div>

            </div>


            <div className={showDonePop ? "pop-addcard-main" : " showPop"}>
                <div className='pop-addcard order-done-pop'>
                    <span className='icona-check'></span>
                    <h2 className='fontX3'>Order placed successfuly</h2>
                    <ul>
                        <li><Link className='solid-btn' to={`/`}>{t('header.Continue')}</Link></li>
                        <li><Link className='outline-btn' to={`/orders`}>{t('header.showOrder')}</Link></li>

                    </ul>

                </div>
                <div className='bg-pop'></div>

            </div>


            <Footer/>

        </>
    )
}

export default CheckoutPayment
