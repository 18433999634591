import React, {useEffect} from 'react'

import axios from 'axios';
import {useState} from 'react'
import logo from '../../imeges/logo.png';
import arFlg from '../../imeges/eg.png';
import enFlg from '../../imeges/ar.png';
import googleImg from '../../imeges/gogole.png';
import fbImg from '../../imeges/fb.png';
import {Link} from 'react-router-dom';

import Joi from 'joi';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import memberStore from "../../mobx-stores/stores/memberStore/memberStore";
import {changeLanguage} from "../../utils/language";

// import {
//     getHeader,
// } from '../../helperFunctions/getHeader.ts';
// import Connections from "../../connections/index.tsx";


export const Register = () => {


    const {t, i18n} = useTranslation();


// eye password

    const toggleIsPasswordShowValue = () => {
        setIsPasswordShow(!isPasswordShow);
    };
    const [isPasswordShow, setIsPasswordShow] = useState(false);
    const toggleIsPasswordShowValue1 = () => {
        setIsPasswordShow1(!isPasswordShow1);
    };
    const [isPasswordShow1, setIsPasswordShow1] = useState(false);


    const navigate = useNavigate();

    function goConfirm() {
        let path = '/ConfirmUserNumber'
        window.location.href = path
    }

// user info

    let [user, setUser] = useState({
        name: '',
        phone: '',
        email: '',
        password: '',
        confirm_password: ''
    });

    let [errorMsg, setErrorMsg] = useState("");
    let [errorList, setErrorList] = useState({});
    let [social, setSocial] = useState({});

    async function submitFormData(e) {
console.log(user.phone)

        e.preventDefault();
        let validationResult = validateForm();
        const {error} = validationResult;

        if (error) {
            const errorData = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                errorData[name] = message;
            }
            setErrorList(errorData);
        } else {
            // validateForm()

            try {
                setErrorMsg('');

                let RegData = {...user}
                if (social.social_id)
                    RegData = {...user, ...social}

                let {data} = await memberStore.register(RegData)

                const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'


                if (data.status === "ok") {
                    localStorage.setItem("phone", user.phone)
                    goConfirm()
                } else {
                    setErrorMsg(data?.message);
                }
            } catch (error) {

                if (error?.response.data?.message)
                    setErrorMsg(error?.response.data?.message)
                else
                    setErrorMsg(error?.response.data?.message)
            }


        }
    }

    function getFormDate(e) {
        let myUser = {...user};
        myUser[e.target.name] = e.target.value;
        setUser(myUser);
    }


    function validateForm() {
        const schema = Joi.object({
            name: Joi.string().required().regex(/^([^0-9]*)$/)
            .messages({'string.pattern.base': `${t('validation.Name')}`}),
            phone: Joi.string().min(8).max(20).required()
            .messages({'string.min': `${t('validation.phoneNumber')}`}),
            email: Joi.string().required()
            .email({tlds: {allow: ['com', 'net', 'ru', 'info', 'io', 'biz']}})
            .messages({'string.email': `${t('validation.email')}`}),
            password: Joi.string().min(6).max(15).required().label('Password')
            .messages({'string.min': `${t('validation.password')}`})
            ,
            confirm_password: Joi.any().equal(Joi.ref('password'))
                .required()
                .label('Confirm password')
                .messages({ 'any.only': `${t('profile.confirmPasswordWrong')}` })
        });

        return schema.validate(user, {abortEarly: false});

    }


    // const [first_name, setFirstNname] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [checked, setChecked] = useState('');
    const [defaultName, setDefaultName] = useState();
    const [defaultEmail, setDefaultEmail] = useState();


    function SubmitButton() {
        if (name && email && phone && password && confirm_password && checked) {
            return <button className="solid-btn fontX7" type="submit">{t('Register.logBtn')}</button>
        } else {
            return <button className="solid-btn fontX7" type="submit" disabled>{t('Register.logBtn')}</button>
        }
        ;
    };


    const responseGoogle = (response) => {
        setDefaultName(response?.profileObj?.name)
        setName(response?.profileObj?.name)
        setDefaultEmail(response?.profileObj?.email)
        setEmail(response?.profileObj?.email)
        let myUser = {...user};
        myUser['name'] = response?.profileObj?.name;
        myUser['email'] = response?.profileObj?.email;
        setUser(myUser)

        setSocial({
            social_id: response?.googleId,
            social_provider: "google"
        })
    }
    const responseFacebook = async (response) => {
        setDefaultName(response?.name)
        setName(response?.name)
        let myUser = {...user};
        myUser['name'] = response?.profileObj?.name;
        setUser(myUser)

        setSocial({
            social_id: response.id,
            social_provider: "facebook"
        })
        // await memberStore.faceBookLogin(response.id)
    }
    const clientId = '856709740247-j6i9i3295gvgd00ig73o4mdvimmj6fr0.apps.googleusercontent.com'


    return (

        <div className="container-fluid form-main remove-padding">

            <aside className=" col-sm-5 col-xs-12 col-md-5 form-side hidden-xs">
                <div className="logo-main">

                    <Link to="/home"><img src={logo} alt="Logo"/></Link>
                </div>
                <div className="form-side-info">
                    <h2 className="fontX3">{t('Register.title')}</h2>
                    {/* <p className="fontX5">{t('Register.subTitle')}</p> */}
                    <p className="fontX5"></p>

                    <Link className="outline-btn fontX6" to="/LogIn">{t('Register.btn')}</Link>
                </div>
                <div className="form-side-fot">
                    <ul>
                        {i18n.language == "en" ? <button onClick={e => {
                            changeLanguage("ar");

                        }}>العربية<img src={arFlg} alt="arFlg"/></button> : <button onClick={e => {
                            changeLanguage("en");

                        }}>
                            English<img src={enFlg} alt="arFlg"/></button>
                        }
                    </ul>
                    <h5>{t('Register.copy')}</h5>
                </div>
            </aside>
            <div className="col-md-7 col-sm-7 log-form-main remove-padding">
                <div className='reg-main col-xs-12'>
                <Link className='visible-xs' to="/home"><img src={logo} alt="Logo"/></Link>

                    <h1 className="fontX3">{t('login.title')}</h1>

                    <div className="row">
                        <ul className="col-xs-12 remove-padding">
                            <li className="col-md-6 col-sm-6">
                                <GoogleLogin
                                    clientId={clientId}
                                    render={renderProps => (
                                        <button className="outline-btn google-btn" onClick={renderProps.onClick}>
                                            <img src={googleImg} alt="Logo"/>{t('login.google')}
                                        </button>
                                    )}
                                    buttonText="Login"
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />

                            </li>
                            <li className="col-md-6 col-sm-6">


                                <FacebookLogin
                                    appId={process.env.REACT_APP_FB_ID}
                                    autoLoad={false}
                                    callback={responseFacebook}
                                    textButton={t('login.facebook')}
                                    icon={<img src={fbImg} alt="Logo"/>}
                                    render={renderProps => (
                                        <button className="outline-btn facebook-btn"
                                                onClick={renderProps.onClick}></button>
                                    )}
                                />
                            </li>

                        </ul>
                        <h2 className='fontX4'>
                            <span>{defaultName ? t('Register.socialConnected') : t('Register.loginOption')}</span></h2>
                        {errorMsg && <p className='error-backend-label'>{errorMsg}</p>}
                        <form onSubmit={submitFormData}>

                            {/* {errorMsg ? <div className="erorMsg"></div> : ""} */}
                            <div className='names-main-form'>


                                <div className="col-xs-12 col-md-12 col-sm-12 input-main">
                                    <label> {t('Register.name')}</label>
                                    <input defaultValue={defaultName}
                                           style={{borderColor: errorList.name ? '#D62921' : ''}}
                                           onChange={e => {
                                               setName(e.target.value);
                                               getFormDate(e)
                                           }} autoComplete="off" name="name" type="text"/>
                                    <div style={{display: errorList.name ? '' : 'none'}} className="error-msg">
                                        {errorList.name}
                                    </div>
                                </div>

                            </div>


                            <div className="col-xs-12 input-main remove-padding">
                                <label>{t('Register.phone')}</label>
                                <input style={{borderColor: errorList.phone ? '#D62921' : ''}} onChange={e => {
                                    setPhone(e.target.value);
                                    getFormDate(e)
                                }} autoComplete="off" name="phone" type="number"/>
                                <div style={{display: errorList.phone ? '' : 'none'}} className="error-msg">
                                    {errorList.phone}
                                </div>
                            </div>

                            <div className="col-xs-12 input-main remove-padding">
                                <label>{t('Register.email')}</label>
                                <input defaultValue={defaultEmail}
                                       style={{borderColor: errorList.email ? '#D62921' : ''}} onChange={e => {
                                    setEmail(e.target.value);
                                    getFormDate(e)
                                }} autoComplete="off" name="email" type="text"/>
                                <div style={{display: errorList.email ? '' : 'none'}} className="error-msg">
                                    {errorList.email}
                                </div>
                            </div>

                            <div className="col-xs-12 input-main remove-padding">
                                <label>{t('Register.Pssword')}</label>
                                <input
                                    name="password"
                                    autoComplete="off"
                                    type={isPasswordShow ? 'text' : 'password'}
                                    style={{borderColor: errorList.password ? '#D62921' : ''}}
                                    onChange={e => {
                                        setPassword(e.target.value);
                                        getFormDate(e)
                                    }}


                                />
                                <span className={isPasswordShow ? `icona-eye-close` : `icona-eye`}
                                      onClick={toggleIsPasswordShowValue}>

         </span>


                                <div style={{display: errorList.password ? '' : 'none'}} className="error-msg">
                                    {errorList.password}
                                </div>

                            </div>


                            <div className="col-xs-12 input-main remove-padding">
                                <label>{t('Register.confirmPassword')}</label>
                                <input
                                    style={{borderColor: errorList.confirm_password ? '#D62921' : ''}}
                                    name="confirm_password"
                                    autoComplete="off"
                                    type={isPasswordShow1 ? 'text' : 'password'}
                                    onChange={e => {
                                        setConfirmPassword(e.target.value);
                                        getFormDate(e)
                                    }}
                                />
                                <span id='eye1' className={isPasswordShow1 ? `icona-eye-close` : `icona-eye`}
                                      onClick={toggleIsPasswordShowValue1}>

         </span>

                                <div style={{display: errorList.confirm_password ? '' : 'none'}} className="error-msg">
                                    {errorList.confirm_password}
                                </div>

                            </div>


                            <div className='col-xs-12 remove-padding'><label className="checkbox-main">
                                {t('Register.agreetitle')} 
                                <Link className='text-btn' to="/terms"> {t('Register.linkTerm')} </Link>
                                   {t('Register.and')}   
                                <Link className='text-btn' to="/terms"> {t('Register.linkPolicy')}</Link>
                                <input type="checkbox" onChange={e => setChecked(e.target.checked)}/>
                                <span className="checkmark fontX6Label"></span>
                            </label></div>

                            <div className='col-xs-12 remove-padding'><label
                                className="checkbox-main">{t('Register.remember')}
                                <input type="checkbox"/>
                                <span className="checkmark fontX6Label"></span>
                            </label></div>


                            {/* <button className="solid-btn fontX7">solid button</button> */}
                            <SubmitButton/>


                        </form>


                    </div>

                    <p className='visible-xs fontX6Label'>{t('Register.haveAcount')} <Link className='text-btn' to="/LogIn">
                        {t('Register.btn')}</Link></p>


                    <div className="form-side-fot visible-xs text-center">


                        <ul>


                            {i18n.language == "en" ? <button onClick={e => {
                                changeLanguage("ar");

                            }}>العربية<img src={arFlg} alt="arFlg"/></button> : <button onClick={e => {
                                changeLanguage("en");

                            }}>
                                English<img src={enFlg} alt="arFlg"/></button>
                            }

                        </ul>


                        <h5>{t('footer.copy')}</h5>
                    </div>

                </div>
            </div>

        </div>
    )


}
export default Register
