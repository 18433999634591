import React from 'react'
import { useState, useEffect} from 'react'
import Axios from 'axios'
import Joi from 'joi';
import { useTranslation } from 'react-i18next';

export const ProfilePassword = () => {
  const { t, i18n } = useTranslation();
  const [editModePassword, setEditModePassword] = useState(true);
  const [doneMesg, setDoneMesg] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleIsPasswordShowValue = () => {
    setIsPasswordShow(!isPasswordShow);
};
const [isPasswordShow, setIsPasswordShow] = useState(false);

const toggleIsPasswordShowValue1 = () => {
  setIsPasswordShow1(!isPasswordShow1);
};
const [isPasswordShow1, setIsPasswordShow1] = useState(false);


const toggleIsPasswordShowValue2 = () => {
  setIsPasswordShow2(!isPasswordShow2);
};
const [isPasswordShow2, setIsPasswordShow2] = useState(false);



  let [password, setPassword] = useState({
    new_password: '',
    new_confirm_password: '',
    current_password:''
});

let[errorMsg,setErrorMsg] = useState("");
let[errorList,setErrorList] = useState({});

async function submitFormData(e) {
  e.preventDefault();
  let validationResult = validateForm();
  const {error} = validationResult;

  if (error) {
      const errorData = {};
      for (let item of error.details) {
          const name = item.path[0];
          const message = item.message;
          errorData[name] = message;
      }
      setErrorList(errorData);
  } else {
      // validateForm()

    try {
      setErrorMsg('');
      setLoading(true)
      const token = localStorage.getItem("token")
      const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

      let {data} = await Axios.post(`${process.env.REACT_APP_BASE_URL}/api/customer/auth/change-password`, password, {
        headers: {
          'x-lang-code': changeLang,
          'Authorization': `Bearer ${token}`

      }
      });

 


      if (data.status == "ok") {
        setEditModePassword(true)
        setDoneMesg(true)
      }
  } catch (error) {

      if (error?.response?.data?.message)
          setErrorMsg( error?.response?.data?.message)
      else
          console.info('')
  }
  finally {

    setLoading(false)

      setTimeout(function() {
        setDoneMesg(false)
           }, 3000);

}


}
}





function getFormDate(e) {
let myUser = {...password};
myUser[e.target.name] = e.target.value;
setPassword(myUser);
}


function closeEditMode() {
  setEditModePassword(true)
  setErrorMsg('');
  setErrorList('');

  }
  


function validateForm(){
const schema=Joi.object({
  new_password:
  Joi.string().min(6).max(15).required().label('Password')
   .messages({'string.min': `${t('validation.password')}`})
  ,
  new_confirm_password: Joi.any().equal(Joi.ref('new_password'))
.required()
.label(`${t('profile.confirmPassword')}`)
.messages({ 'any.only': `{{#label}} ${t('profile.confirmPasswordWrong')}` }),
current_password: Joi.string().allow(null, '')
});

return schema.validate(password,{abortEarly:false});

}

const [passwordLabel, setPasswordLabel] = useState('');
const [confirmPassword, setConfirmPassword] = useState('');
const [oldPassword, setOldPassword] = useState('');

// onClick={e => setEditModePassword(true)}

function SubmitButton() {
    if (passwordLabel && confirmPassword && oldPassword) {
        return <button  className="text-btn" type="submit">{t('profile.saveBtn')}</button>
    } else {
        return <button className="text-btn" type="submit" disabled>{t('profile.saveBtn')}</button>
    }
    ;
};


  return (
<>

{loading ? <div className="loader-line"></div> : ""}


    {editModePassword ?
      <div className='col-xs-12 remove-padding profile-info-item'>
     <p className='fontX8'>{t('profile.password')}</p>
     <span className='icona-edit-1' onClick={() => setEditModePassword(false)}></span>
     <h4 className='fontX6'>******</h4>
</div> : <div className='edit-pro-info'>
       <p className='fontX8'>{t('profile.password')}</p>

          <form onSubmit={submitFormData} className='edit-password col-xs-12 remove-padding'>
          {errorMsg&&<div className='col-xs-12'><p className='error-backend-label'>{errorMsg}</p></div>}
                    {errorMsg ? <div className="erorMsg"></div> : ""}


                    <div className='pass-input-item col-xs-12'>
<p>{t('profile.currentPassword')}</p>
             <input
             type={isPasswordShow2 ? 'text' : 'password'}
              onChange={e => {
              setOldPassword(e.target.value);
              getFormDate(e)
          }}
             name='current_password' />

<span className={isPasswordShow2 ? `icona-eye-close` : `icona-eye`}
                                      onClick={toggleIsPasswordShowValue2}>

         </span>


             </div>

             <div className='pass-input-item col-xs-12 col-md-6 col-sm-12'>
             <p> {t('profile.newPassword')}</p>
             <input
             type={isPasswordShow ? 'text' : 'password'}

             style={{borderColor:errorList.confirm_password?'#D62921':''}} onChange={e => {
              setPasswordLabel(e.target.value);
              getFormDate(e)
          }}
             name='new_password' />
                             <span className={isPasswordShow ? `icona-eye-close` : `icona-eye`}
                                      onClick={toggleIsPasswordShowValue}>

         </span>

                <div style={{display:errorList.new_password?'':'none'}} className="error-msg">
                                    {errorList.new_password}
                                </div>
             </div>
             <div className='pass-input-item col-xs-12 col-md-6 col-sm-12'>
             <p>{t('profile.confirmPassword')}</p>
             <input
             type={isPasswordShow1 ? 'text' : 'password'}
             style={{borderColor:errorList.confirm_password?'#D62921':''}} onChange={e => {
              setConfirmPassword(e.target.value);
              getFormDate(e)
          }}
             name='new_confirm_password' />

<span className={isPasswordShow1 ? `icona-eye-close` : `icona-eye`}
                                      onClick={toggleIsPasswordShowValue1}>

         </span>

   <div style={{display:errorList.new_confirm_password?'':'none'}} className="error-msg">
                                    {errorList.new_confirm_password}
                                </div>

             </div>


<button className='close-btn' onClick={closeEditMode}>{t('profile.cancel')} </button>
<SubmitButton/>
<div className='clearfix'></div>
          </form>




       </div>}
       {doneMesg ? <p className='done-msg'> {t('profile.passInfo')}</p>:""}
       </>

  )
}

export default ProfilePassword
