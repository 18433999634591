import React, {useState, useEffect} from 'react'
import Axios from 'axios';

import Header from '../general/header'
import Footer from '../general/footer'
import SiteMap from '../general/siteMap'
import {Link} from 'react-router-dom'
import SimpleDateTime from 'react-simple-timestamp-to-date';
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import noResults from '../../imeges/no-results.png';


const Orders = () => {

    const [orders, setOrders] = useState([]);
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(true);

    async function dataApi() {
        const token = localStorage.getItem("token")
        const changeLang = localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == 'ar' ? localStorage.getItem("lang") : 'en'

        let {data: ordersResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/orders`, {
            headers: {
                'x-lang-code': changeLang,
                'Authorization': `Bearer ${token}`

            }
        });

        const {data: orderData} = ordersResponse;
        setOrders(orderData)

    }


    useEffect(() => {
        dataApi().then(r => {
            setLoading(false);

        })
    }, []);


  return (
    <>
   <Header/>
   <SiteMap pageUrl="/Profile"  pageLink={t('helpCenter.profile')} pageName={t('orders.title')}/>
       <div className='container pro-orders-main'>

                <h1 className='fontX3'>{t('orders.title')}</h1>

                <div className='col-md-8 col-md-offset-2 ol-xs-12 col-sm-12 remove-padding'>


                    {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={4}/></div>
                        </SkeletonTheme> :

                        <div>
                            {orders.list.length && orders.list.length >= 0 ? <div>
                                    {orders.list && orders.list.map((item, index) =>

                                        <div className='col-xs-12 order-item' key={index}>


                                            <Link to={`/orderDetails/${item.id}`}
                                                  className='fontX5'>{t('orders.OrderNumber')}{item.id}</Link>

                                            <span
                                                className={item.payment_status == 1 ? 'blue' : item.payment_status == 3 ? 'red' : item.payment_status == 2 ? 'green' : 'orange'}>
   {item.payment_status_label}
  </span>

                                            {/* <p className='fontX6'>{t('orders.Placed')} {item.created_at}</p> */}

                                            <p className='fontX6'>{t('orders.Placed')} &nbsp;&nbsp;


                                                <SimpleDateTime dateSeparator="/" showTime="0"
                                                                format="YMD">{item.created_at}</SimpleDateTime>
                                                &nbsp;&nbsp; | &nbsp;&nbsp;
                                                <SimpleDateTime timeSeparator=":"
                                                                showDate="0">{item.created_at}</SimpleDateTime>
                                            </p>
{console.log(item.manual_labor_cost)}
                                      

                                            <div className='col-xs-12 remove-padding'>
                                                <h6 className='fontX6'>{t('orders.Total')} ({item.order_items_count} {t('orderDetails.items')})</h6>
                                                {/* <h5 className='fontX6' >{t('orders.EGP')} {item.total_amount}</h5> */}

                                                <h4 className='fontX6'>&nbsp;{t('orders.EGP')} {item.total_amount - item.discount_amount}</h4>
                                            </div>

                                            <div className='col-xs-12 remove-padding'>
                                                
  {item.manual_labor == 1 ? <p>manual labor cost : <b>{item.manual_labor_cost}</b></p> : ""}

                                            </div>

                                        </div>)}

                                </div> :
                                <div className='no-results-main'>
                                    <img src={noResults} alt="no Results"/>
                                    <h4>{t('NotFound.orders')}</h4>
                                    <Link className='solid-btn' to={`/`}>{t('checkOut.Continue')}</Link>
                                </div>}


                        </div>}


                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Orders
