
import React from 'react'
import { useState, useEffect, createContext } from 'react';
import Axios from 'axios';


export let MassegeContext = createContext()

export const MsgContext = (props) => {  
    const [messages, setMessages] = useState([]);

    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

    async function dataApi() { 
       
        let{data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/messages`,{
    headers: {
    'x-lang-code': changeLang, 
    }
    });
        
        const {data: messages} = brandsResponse;
        setMessages(messages)
        }
    
    
      useEffect(() => {  
        dataApi()
    }, [ ]);

    return (
       <>


<MassegeContext.Provider value={{messages}}>
    {props.children}
</MassegeContext.Provider>




       
       </>
    )
}

export default MsgContext