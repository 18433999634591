import React from 'react'
import { useState, useEffect } from 'react';

import Header from '../general/header'
import Footer from '../general/footer'
import SiteMap from '../general/siteMap'
import { Link } from 'react-router-dom'
import Axios from 'axios';
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

const HelpCenter = () => {
    const [loading, setLoading] = useState(true);

    const token = localStorage.getItem("token");
    const {t, i18n} = useTranslation();
    const [hotLine, setHotLine] = useState([]);
    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'
 
    async function dataApi() { 
        
        let{data: hotLieResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/hotline`,{
  headers: {
    'x-lang-code': changeLang, 
  }
 });
        
        const {data: hotLine} = hotLieResponse;
        setHotLine(hotLine)
        }
 
 
    useEffect(() => {
        dataApi().then(r => {
            setLoading(false)

        })
    }, []);


  return (
      <>
         <Header/>
          <div className='container'>
              <SiteMap pageUrl="/profile"  pageLink={t('helpCenter.profile')} pageName={t('helpCenter.title')}/>
              <div className='col-md-8 col-md-offset-2 help-main'>
              <h1 className='fontX3'>{t('helpCenter.title')}</h1>
                 <h3 className='fontX6'>{t('helpCenter.info')}</h3>
              {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={3}/></div>
                        </SkeletonTheme> :   
               
                 <ul>
                     <li>
                          <div>
                              <h4  className='fontX6'><span className='icona-support'></span>
                              {hotLine.key} - {hotLine.value}
                              </h4>
                              <p>{t('helpCenter.phone')}</p>
                           </div>
                     </li>

                     <li>
                         
                    {token ? 
                        <Link to="/TicketList">
                        <h4><span className='icona-comment'></span>{t('helpCenter.TicketTitle')}</h4>
                        <p>{t('helpCenter.Ticketinfo')}</p>
                     </Link>
                    :
                    <Link to="/Login">
                            <h4><span className='icona-comment'></span>{t('helpCenter.TicketTitle')}</h4>
                             <p>{t('helpCenter.Ticketinfo')}</p>

                 </Link>
                   }
                            
                     </li>

                     <li>
                          <Link to="/Faqs">
                              <h4><span className='icona-question-mark-circle'></span>{t('helpCenter.FAQs')}</h4>
                              <p>{t('helpCenter.FAQsInfo')}</p>
                           </Link>
                     </li>

                 </ul>
               }
              
             
   
              </div>
          </div>
         <Footer/>
      </>
  )
}

export default HelpCenter
