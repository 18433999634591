// import React from "react";
import React from 'react'
import MsgContext from "../MsgStore/MsgContext"

import LogIn from '../Login/LogIn'
import NotFound from '../Notfound/NotFound'
import Icons from '../icons/icons'
import Register from '../Register/Register'
import Product from '../Product/Product'
import Home from '../Home/Home'
import Category from '../Category/Category'
import Profile from '../profile/profile'
import Address from '../address/Address'
import Terms from '../Terms/Terms'
import ConfirmNumber from '../confirmNumber/ConfirmNumber'
import ConfirmUserNumber from '../confirmNumber/ConfirmUserNumber'
import ConfirmNumberNew from '../confirmNumber/ConfirmNumberNew'
import ForgotPasswprd from '../forgotPassword/forgotPasswprd'
import AddAdress from '../address/addAdress'
import MapAddress from '../address/mapAddress'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import CheckoutAdress from '../chechOut/CheckoutAdress'
import CheckoutPromo from '../chechOut/CheckoutPromo'
import CheckoutPayment from '../chechOut/CheckoutPayment'
import XpayCallBack from '../xpay/callBack'
import CartCheckOut from '../chechOut/cartCheckOut'
import HelpCenter from '../profile/helpCenter'
import Faqs from '../general/Faqs'
import Orders from '../profile/orders'
import OrderDetails from '../profile/orderDetails'
import Tickets from '../profile/ticketList'
import NewTicket from '../profile/newTicket'
import Ticket from '../profile/tickets'
import ProductsFilter from '../Product/productsFilter'
import CheckoutPaymentCash from '../chechOut/CheckoutPaymentCash'
import CheckoutData from '../chechOut/CheckoutData'
import CheckoutReview from '../chechOut/CheckoutReview'
import SubCategory from '../Category/subCategory'
import BestSela from '../bestSela/bestSela'
import Offers from '../offers/offers'
import SubsCate from '../Category/subsCate'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../css/action.css'
import '../../css/arrows.css'
import '../../css/design.css'
import '../../css/media.css'
import '../../css/travel.css'


import {useLayoutEffect} from 'react';
import styled from "@emotion/styled/macro";
import i18n from "i18next";






function App() {
  


if (window.location.pathname == "/CheckoutReview") {
  
} 



    const Wrapper = ({children}) => {
        const location = useLocation();
        useLayoutEffect(() => {
            document.documentElement.scrollTo(0, 0);
        }, [location.pathname]);
        return children
    }
    const token = localStorage.getItem("token");


    const setStyle=()=>{
        if (i18n.language === 'en')
        return  styled.div`  `;

        return  styled.div`
         
         .site-map-main li span {
          transform: rotate(180deg);
    display: block;
         }
         .slick-prev, .slick-next {
          
          left: 70px !important;
    left: right !important;
         }

.container, .form-main, .pop-addcard, h1, h2, h3, h4, h5, h6, p, span, b, button, .react-datepicker__month-container   {
    font-family: 'Almarai', sans-serif;
    direction: rtl;
}
.radio-main, .header-main .logo-main ul li, .footer-main .fot-bottom button, .checkout-side ul h5
,.cheackout-main .chosen-adress h3, .promo-form p, .cheackout-main .promo-form input, .cart-item .qtn-main,
.cart-item .qtn-main button, .cart-item .qtn-main h4, .cart-check-out .cart-total-price p,
.product-item .qtn-main button, .product-item .qtn-main h4, .product-item .qtn-main span.icona-minus,
.pro-orders-main .order-item h4, .pro-orders-main .order-item h5, .pro-orders-main .order-item h6,
.order-d-item p, .order-d-item h4, .order-d-item h5, .ticket-chat div.send-section p,
.cate-main-home-list li, .products-main div span span, .partner-main div span span,
.cate-grid-main div span span, .sub-cate-loading div span span, .cate-filter div span span,
.profilter-main div span span, .add-ticket-loading div span span 
{
    float: right;
}

.forgot-input label {
  text-align: right;
}


.form-main .forgot-btn, .header-main .head-men-main ul, .search-main svg, .partner-main > a,
.address-main .address-item ul, .products-main > a, .checkout-side ul p, .checkout-side .has-dis-total span,
.cheackout-main .chosen-adress a, .cart-item span.remove-btn-cart, .cart-check-out .cart-total-price h3,
.promo-form a.text-btn, .ticket-chat div.reply-section p, ul.col-xs-12.subs-main > div,
.edit-pro-info button, .add-ticket-loading div span span:nth-of-type(3), .add-ticket-loading div span span:nth-of-type(7),
.add-ticket-loading div span span:nth-of-type(11), .add-ticket-loading div span span:nth-of-type(15),
.profile-info span, .checkout-side .has-dis span, .checkout-side .has-dis p,  .checkout-side  .hasDisCart span,
.checkout-side  .hasDisCart p
{
    float: left;
}
.payNowBtn {
  left: 0; 
  right: auto !important;
}

.checkbox-main .checkmark, .radio-main input, .radio-main .checkmark,
.cate-item-grid span, .cat-main-home .cate-info h2::after,.more-cate-grid-main .slide-bot, 
.more-cate-grid-main a::after,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,
.cart-bg, .cart-main::after, .cart-main .cart-list img, .order-d-item img, .see-all-cate-main::after, 
.upload-img-main img, .loader-linem
 {right: 0;  left: auto !important;}
.checkbox-main .checkmark:after{right: 9px;  left: auto !important;}
.radio-main .checkmark:after {right: 6px;  left: auto !important;}
.slider:before {right: 4px;  left: auto !important;}
.slider-cont-main .slide-bot { right: 0;  left: auto !important;}
.cate-item-grid h2::after {right: 30px;  left: auto !important;}
.radio-label input[type="radio"] {right: 15px;  left: auto !important;}
.cart-main::before {right: 106px;  left: auto !important;}
.cart-item .qtn-main p.no-items-avalible {right: -15px;  left: auto !important;}
.cart-li > button span.icona-cart { right: -9px;  left: auto !important;}
.cart-li > button span.icona-cart p {right: -11px;  left: auto !important;}
.hidden-cart .cheackout-cart-link span {right: -20px;  left: auto !important;}
.hidden-cart .cheackout-cart-link b.icona-cart{right: -7px;  left: auto !important;}
.react-datepicker-popper { right: 0 !important;  left: auto !important;}
.lds-ellipsis div:nth-of-type(1), .lds-ellipsis div:nth-of-type(2) {right: 8px;  left: auto !important;}
.lds-ellipsis div:nth-of-type(3) {right: 32px;  left: auto !important;}
.lds-ellipsis div:nth-of-type(4) {right: 56px;  left: auto !important;}
.product-item .qtn-main {right: 24px;  left: auto !important;}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {right: 30px !important;  left: auto !important;}
.qtn-main-product button.icona-minus, .qtn-main-product > span {right: 5px;  left: auto !important;}
.more-cate-grid-main a { right: 15px;  left: auto !important;}
.map-main::before {right: calc(25% + 10px);  left: auto !important;}
.subs-main {right: 12px;  left: auto !important;}
.upload-main P {right: -30px;  left: auto !important;}
.loader-line:before {right: -50%;  left: auto !important;}
@keyframes lineAnim {
  0% {
      right: -40%;
      left: auto !important;
  }
  50% {
      right: 20%;
      left: auto !important;
  }
  100% {
      right: 100%;
      left: auto !important;
  }
}

.header-main .col-md-5.col-lg-6.col-lg-offset-1 ul {right: 14px;  left: auto !important;}


/* left */


.col-xs-12.remove-padding.ticket-item img {left: 18px;right:  auto !important;}
.log-form-main .input-main span.icona-eye, .log-form-main .input-main span.icona-eye-close  {left: 12px;right:  auto !important;}
.cart-main { left: 12px;right:  auto !important;}
.cart-check-out  .cart-item span.remove-btn-cart, .tab label::after, 
.tab span, .react-datepicker__close-icon, .upload-img-main button {left: 0;right:  auto !important;}
.setDateBtn, .add-ticket form button{left: 15px;right:  auto !important;}
.loadingMain{left: 60px;right:  auto !important;}
.pro-orders-main .order-item span{left: 18px;right:  auto !important;}
.qtn-main-product button.icona-plus {left: 5px;right:  auto !important;}
.cate-main-home-list li:nth-of-type(5){ left: 16.66666667%;right:  auto !important;}
.cate-main-home-list li:nth-of-type(6) {left: 12px;right:  auto !important;}
.cate-main-home-list li:nth-of-type(7) {left: calc(16.66666667% );right:  auto !important;}
.cate-main-home-list li:nth-of-type(8) {left: 12px;right:  auto !important;}
.see-all-cate-main {left: 12px;right:  auto !important;}
.cate-filter ul {
  text-align: end;
}




.cate-filter ul {
    flex-direction: row-reverse;
}

.product-item .qtn-main .icona-plus {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.product-item .qtn-main button.icona-minus, .product-item .qtn-main span.icona-minus {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.add-ticket form button {
    margin-top: 30px;
    transform: rotate(180deg);
}

.checkbox-main {
    padding-inline-end: 35px;
}

a.cart-btn-float {
  left:30px;
  right: auto;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: right;
}

.col-xs-offset-12 {
  margin-right: 100%;
  margin-left: 0;
}
.col-xs-offset-11 {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.col-xs-offset-10 {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.col-xs-offset-9 {
  margin-right: 75%;
  margin-left: 0;
}
.col-xs-offset-8 {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.col-xs-offset-7 {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.col-xs-offset-6 {
  margin-right: 50%;
  margin-left: 0;
}
.col-xs-offset-5 {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.col-xs-offset-4 {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.col-xs-offset-3 {
  margin-right: 25%;
  margin-left: 0;
}
.col-xs-offset-2 {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.col-xs-offset-1 {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.col-xs-offset-0 {
  margin-right: 0%;
  margin-left: 0;
}
@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: right;
  }
  
  
  .col-sm-offset-12 {
    margin-right: 100%;
    margin-left: 0;
  }
  .col-sm-offset-11 {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .col-sm-offset-10 {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .col-sm-offset-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .col-sm-offset-8 {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .col-sm-offset-7 {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .col-sm-offset-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .col-sm-offset-5 {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .col-sm-offset-4 {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .col-sm-offset-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .col-sm-offset-2 {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .col-sm-offset-1 {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .col-sm-offset-0 {
    margin-right: 0%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: right;
  }

 

  
  .col-md-offset-12 {
    margin-right: 100%;
    margin-left: 0;
  }
  .col-md-offset-11 {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .col-md-offset-10 {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .col-md-offset-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .col-md-offset-8 {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .col-md-offset-7 {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .col-md-offset-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .col-md-offset-5 {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .col-md-offset-4 {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .col-md-offset-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .col-md-offset-2 {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .col-md-offset-1 {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .col-md-offset-0 {
    margin-right: 0%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: right;
  }

  .col-lg-offset-12 {
    margin-right: 100%;
    margin-left: 0;
  }
  .col-lg-offset-11 {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .col-lg-offset-10 {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .col-lg-offset-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .col-lg-offset-8 {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .col-lg-offset-7 {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .col-lg-offset-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .col-lg-offset-5 {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .col-lg-offset-4 {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .col-lg-offset-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .col-lg-offset-2 {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .col-lg-offset-1 {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .col-lg-offset-0 {
    margin-right: 0%;
    margin-left: 0;
  }


  
}

@media only screen and (max-width : 991px) {
    .mobile-btns, .header-main .close-search-btn {
    float: left;
  }

  .header-main form, .product-item .qtn-main, .product-item .qtn-main span.icona-minus,
  .header-main .col-md-5.col-lg-6.col-lg-offset-1 ul {
    right: 0;  left: auto !important;
  }

  .mobile-btns a.icona-cart {right: 5px;  left: auto !important;}
  li.search-icon {
    /* right: 18px;  */
     left: auto !important;}
  .subs-main {right: 7px;  left: auto !important;}

.head-men-main { left: 0;right:  auto !important;}
.head-men-main span.icona-close{left: 30px;right:  auto !important;}
/* .mobile-btns a.icona-cart span {right:  auto !important;left: -11px;} */

.cate-main-home-list li:nth-of-type(5), .cate-main-home-list li:nth-of-type(7) {left: 25%;right:  auto !important;}
.cate-main-home-list li:nth-of-type(6), .cate-main-home-list li:nth-of-type(8), 
.see-all-cate-main {left: 12px;right:  auto !important;}
.header-main .close-search-btn {left: 10px;right:  auto !important;}

}

caption {
  text-align: right;
}
th {
  text-align: right;
}
@media screen and (max-width: 767px) {
 .map-main::before {
    right: 12px;
    left: auto!important;
}
.radio label,
.checkbox label {
  padding-right: 20px;
  padding-left: initial;
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  margin-right: -20px;
  margin-left: auto;
}
.radio-inline,
.checkbox-inline {
  padding-right: 20px;
  padding-left: 0;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-right: 10px;
  margin-left: 0;
}

.add-ticket form button {
    margin-top: 5px;
}


}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
  }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}


@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-right: -15px;
    margin-left: auto;
  }
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 25px 5px 15px;
  }

  .slider-cont-main .container {
  direction: rtl;
}

.order-d-item h5 {
    float: right;
}

.partner-main .slick-prev { right: 10px;   left: auto !important;}

.mySlider .slick-prev, .mySlider .slick-next {left: 15px;right:  auto !important;}
.partner-main .slick-prev, .partner-main .slick-next {
  left: 10px;
  right:  auto !important;
}

.add-ticket form button { left: 10px;
  right:  auto !important;}
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  left: 90px;
  right:  auto !important;

}

.see-all-cate-main {
  left: 4px;
  right:  auto !important;
}



.cate-main-home-list li:nth-of-type(7) {
  left: 25%;
  right:  auto !important;

}
.cate-main-home-list li:nth-of-type(5) {
  left: 25%;
  right:  auto !important;

}

.cate-main-home-list li:nth-of-type(6) {
 
  left: 5px;
  right:  auto !important;

}


.cate-main-home-list li:nth-of-type(8) {
  left: 5px;
  right:  auto !important;
}
a.cart-btn-float {
  left:15px;
  right: auto;
}


}

.product-item a {
  text-transform: initial;
}

`;

    }
    const UlGeneralStyle =setStyle();

    const PrivateRoute = ({children}) => {
        const auth = JSON.parse(token);
        return auth?.user ? children : <Navigate to="/LogIn"/>;
    };




    

    return (

      <MsgContext>
        <UlGeneralStyle>
         

            <Wrapper>
                <Routes>


                    { /* general */}
                   

                    <Route path='/' element={<Home/>}></Route>
                    <Route path='Home' element={<Home/>}></Route>
                    <Route path='xpay-call-back' element={<XpayCallBack/>}></Route>
                    <Route path='LogIn' element={<LogIn/>}></Route>
                    <Route path='Register' element={<Register/>}></Route>
                    <Route path='icons' element={<Icons/>}></Route>
                    <Route path='Product/:productId' element={<Product/>}></Route>
                    <Route path='Categories' element={<Category/>}></Route>
                    <Route path='Filter/:categoryId' element={<SubCategory/>}></Route>
                    <Route path='SubCategory/:subsCateId' element={<SubsCate/>}></Route>
                    <Route path='Terms' element={<Terms/>}></Route>
                    <Route path='BestSela' element={<BestSela/>}></Route>
                    <Route path='Offers' element={<Offers/>}></Route>
                    <Route path='HelpCenter' element={<HelpCenter/>}></Route>
                    <Route path='Faqs' element={<Faqs/>}></Route>
                    <Route path='ProductsFilter' element={<ProductsFilter/>}></Route>
                    <Route path='*' element={<NotFound/>}></Route>
                    <Route path="ConfirmNumber" element={<ConfirmNumber/>}/>
                    <Route path="ConfirmUserNumber" element={<ConfirmUserNumber/>}/>
                    <Route path="ConfirmNumberNew" element={<ConfirmNumberNew/>}/>
                    <Route path="ForgotPasswprd" element={<ForgotPasswprd/>}/>
                  

                    { /* login */}

                    <Route path="Profile" element={token ? <Profile/> : <PrivateRoute/>}/>
                    <Route path="Address" element={token ? <Address/> : <PrivateRoute/>}/>
                    <Route path="AddAdress" element={token ? <AddAdress/> : <PrivateRoute/>}/>
                    <Route path="EditAddress/:addressId" element={token ? <AddAdress/> : <PrivateRoute/>}/>
                    <Route path="Loacation" element={token ? <MapAddress/> : <PrivateRoute/>}/>
                    <Route path="EditMapAddress/:addressId" element={token ? <MapAddress/> : <PrivateRoute/>}/>
                    <Route path="CheckoutAdress" element={token ? <CheckoutAdress/> : <PrivateRoute/>}/>
                    <Route path="CheckoutPromoCode" element={token ? <CheckoutPromo/> : <PrivateRoute/>}/>
                    <Route path="CheckoutPayment" element={token ? <CheckoutPayment/> : <PrivateRoute/>}/>       
                    <Route path="CartCheckOut" element={token ? <CartCheckOut/> : <PrivateRoute/>}/>
                    <Route path="Orders" element={token ? <Orders/> : <PrivateRoute/>}/>
                    <Route path="OrderDetails/:orderId" element={token ? <OrderDetails/> : <PrivateRoute/>}/>
                    <Route path="TicketList" element={token ? <Tickets/> : <PrivateRoute/>}/>
                    <Route path="NewTicket" element={token ? <NewTicket/> : <PrivateRoute/>}/>
                    <Route path="TicketDetails" element={token ? <Ticket/> : <PrivateRoute/>}/>
                    <Route path="Ticket/:ticketId" element={token ? <Ticket/> : <PrivateRoute/>}/>
                    <Route path="CheckoutDownPaymentDate" element={token ? <CheckoutData/> : <PrivateRoute/>}/>
                    <Route path="CheckoutDownPayment" element={token ? <CheckoutPaymentCash/> : <PrivateRoute/>}/>
                    <Route path="OrderReview" element={token ? <CheckoutReview/> : <PrivateRoute/>}/>



                    

                </Routes>
            </Wrapper>

        </UlGeneralStyle>
        </MsgContext>
    );
}

export default App;
