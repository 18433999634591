import React from 'react'
import Header from '../general/header'
import Footer from '../general/footer'
import { useState } from 'react'
import { useEffect } from 'react'
import Axios from 'axios'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import { Markup } from 'interweave';




const Terms = () => {
  const [loading, setLoading] = useState(true);
  const [terms, setTerms] = useState([]);

  async function dataApi() { 
    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'
      
      let{data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/terms-and-conditions`,{
headers: {
  'x-lang-code': changeLang, 
}
});
      
      const {data: terms} = brandsResponse;
      setTerms(terms)
      }


  useEffect(() => {
      dataApi().then(r => {
        setLoading(false);

      })
  }, []);

  return (
      <>
      <Header/>



      <div className='container'>
         <div className='col-md-6 col-md-offset-3 col-xs-12 col-sm-12 terms-main'>

         {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={10}/></div>
                        </SkeletonTheme> : <>
                        <h1 className='fontX3'>{terms.title}</h1>
              <p className='fontX6'>
              <Markup content={terms.description} />
              </p>
                        </> }

              
         </div>
      </div>
      <Footer/>
      </>
  )
}

export default Terms
