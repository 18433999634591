
import { Store } from './stores';
import AsyncStorage from '@react-native-community/async-storage';

import { observable } from 'mobx';
import {
  IHydrateResult,
  create,
} from 'mobx-persist';
export enum LoadingState {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR
}

export class RootStore {
  Store = new Store(this);
  Stores = {
    woocommerceStore: this.Store,
  }

 
}
export default new RootStore();
export {
  default as WoocommerceStore,
} from './stores';
