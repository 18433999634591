import React, {useState, useEffect} from 'react'
import Axios from 'axios';
import Header from './header';
import Footer from './footer';
import SiteMap from './siteMap';
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import noResults from '../../imeges/no-results.png';
import { Link } from 'react-router-dom'

const Faqs = () => {
    const [faqsData, setFaqsData] = useState([]);
    const {t, i18n} = useTranslation();

    const [loading, setLoading] = useState(true);



    async function dataApi() { 
    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'
        
        let{data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/faq`,{
  headers: {
    'x-lang-code': changeLang, 
  }
 });
        
        const {data: firstSlider} = brandsResponse;
        setFaqsData(firstSlider)
        }


    useEffect(() => {
        dataApi().then(r => {
  setLoading(false)

        })
    }, []);



  

    


  return (
    <>

<Header/>
<SiteMap pageUrl="/helpCenter"  pageLink={t('profile.profileSide.helpCenter')} pageName={t('faqs.title')}/>


<div className='container faq-main'>
<h1 className='fontX3'>{t('faqs.title')}</h1>
<div className='col-md-8 col-xs-12 col-sm-12 remove-padding'>
  {faqsData.length > 0 ? <>{loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={5}/></div>
                        </SkeletonTheme> :  <>
                        {faqsData.map( (el, index) => 
<div className='tab'  key={index}>
<input id={el.id} type="checkbox" name="tabs"/>
      <label className='fontX5' htmlFor={el.id}>{el.question}</label>
      <span className='iconar-chevron-down'></span>
      <div className="tab-content">
<p  className="fontX6">{el.answer} </p>
</div>
</div>
)}
                        </>}</> :
                        <div className='no-results-main remove-padding col-xs-12 col-md-offset-3'>
                        <img loading="lazy" src={noResults} alt="no Results"/>
                        <h4>{t('NotFound.faqs')}</h4>
                        <Link className='solid-btn' to={`/`}>{t('checkOut.Continue')}</Link>
                       </div>
                        }

  




</div>
  
</div>


<Footer/>



    </>
  )
}

export default Faqs
