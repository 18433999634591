import {action, observable} from "mobx";
import {persist} from "mobx-persist";
import {uniq} from "lodash";
import Store from "../index";
import {Billing, Cart, CartItem, CartTotals, Promo, Tax} from "./types";

import * as requests from "./requests";


export class CartStore {


    Store: Store;

    @persist("object") @observable cart: Cart = {
        customer: 0,
        line_items: [],
        meta_data: [],
        billing: {} as Billing,
        shipping: {} as Billing,
        shipping_lines: [],
        selectedAddress: {},
        selectedCashAddress: "",
        promoCode: "No Promo",
        promoPriceN: 0,
        promo: {} as Promo,
        Date: "",
        Time: "",
        PaymentMethod: null
    };


    @observable isLoadingCartProducts = false;


    @observable isLoadingShippingPrice = false;

    @observable totalCount = 0;

    @observable ShippingPrice: number;
    @observable loading = false;

    @persist("object") @observable cartTotals: CartTotals = {
        subtotal: 0,
        taxes: 0,
        total: 0,
    };


    @observable taxes: Tax[] = [];

    constructor(Store: Store) {
        this.Store = Store;
        this.loadCart();
    }


    // checks if the item already exists in the cart
    itemFound(product: CartItem, item: CartItem) {
        return (product.id === item.id);
    }

    @action loadCart = () => {
        if (!this.totalCount) {
            let cart = JSON.parse(localStorage.getItem('cart'))
            if (cart) {
                this.cart = cart;
                this.calculateCartTotals()
            }
        }


    };
    @action setSelectedAddress = (address) => {
        const newCart = this.cart;
        newCart.selectedAddress = address;
        this.updateLocalCartStorage()
        this.calculateCartTotals()
    };

    @action setSelectedCashAddress = (address) => {
        const newCart = this.cart;
        newCart.selectedCashAddress = address;
        this.updateLocalCartStorage()
    };


    @action setPaymentMethodCridet = (paymentways) => {
    // console.log(paymentways)
        const newCart = this.cart;
        newCart.PaymentMethod = paymentways;
        this.updateLocalCartStorage()
    };



    @action setPromoCode = (promoCode) => {

        const newCart = this.cart;
        newCart.promoCode = promoCode;
        this.updateLocalCartStorage()
    };


   setPromo = (promo) => {
        const newCart = this.cart;
        newCart.promo = promo;
        this.updateLocalCartStorage()
    };


    @action setDate = (chosenDate) => {

        const newCart = this.cart;
        newCart.Date = chosenDate;
        this.updateLocalCartStorage()
    };


    @action setTime = (time) => {
        const newCart = this.cart;
        newCart.Time = time;
        this.cart = newCart;
        this.updateLocalCartStorage()
    };

    @action setLabor = (labor) => {
        const newCart = this.cart;
        newCart.manual_labor = labor;
        this.cart = newCart;
        this.updateLocalCartStorage()
    };

 



    @action resetCart = () => {

        let cart: Cart = {
            customer: 0,
            line_items: [],
            meta_data: [],
            billing: {} as Billing,
            shipping: {} as Billing,
            promo: {} as Promo,
            shipping_lines: [],
            selectedAddress: {},
            selectedCashAddress: {},
            promoCode: 'no promo',
            Date: '',
            Time: '',
            manual_labor: '',

            PaymentMethod: null
        };
        this.cart = cart;
        this.calculateCartTotals();
    };
    @action addItem = (itemObject) => {
// console.log("itemObject")
// console.log(itemObject)

        this.loading = true
        const newCart = this.cart;
        let product = {
            id: itemObject.id,
            photo: itemObject.photo_url,
            quantity: 1,
            price: itemObject.price,
            name: itemObject.description,
            unit: itemObject.unit,
            actual_quantity: itemObject.actual_quantity,
            taxes: itemObject.tax

        };
        const found = newCart.line_items.some((item: CartItem) => {
            if (this.itemFound(product, item)) {
               if(item.quantity +1 > itemObject.actual_quantity){
                alert("quantity")
                return false
               }
                
                item.quantity += 1;
                return true;
            }
        });

        if (!found) {
            newCart.line_items.push(product);
        }

        this.cart = newCart;
        this.calculateCartTotals();

        this.loading = false


    };

    calculateTotalCount() {
        let totals = 0;
        if (this.cart.line_items.length) {

            this.cart.line_items.forEach((item) => {


                totals += item.quantity;

            })
        }
        this.totalCount = totals;

    }

    canDecreaseQuantity(cartItem: CartItem, item: CartItem) {
        return (cartItem.id === item.id && item.quantity > 1);
    }

    removeItem = (cartItem: CartItem) => {
        const newCart = this.cart;
        newCart.line_items = this.cart.line_items.map((item: CartItem) => {
            if (this.canDecreaseQuantity(cartItem, item)) {
                item.quantity -= 1;
            }
            return item;
        });
        this.cart = newCart;
        this.calculateCartTotals();
    };

    excludeITemFromCard(cartItem: CartItem) {
        return this.cart.line_items.filter(
            (item) =>
                !(item.id === cartItem.id)
        );
    }

    @action deleteItem = (cartItem: CartItem) => {
        const newCart: Cart = {
            line_items: [],
            shipping_lines: [],
        };
        const line_items = this.excludeITemFromCard(cartItem);
        newCart.line_items = line_items;
        this.cart = newCart;
        this.calculateCartTotals();
    };


    @action calculateCartTotals = () => {
        const totals: CartTotals = {
            subtotal: 0,
            taxes: 0,
            total: 0,
        };
        if (this.cart.line_items.length) {
            this.cart.line_items.forEach((item) => {
                totals.subtotal += Number(item.quantity * item.price);
                totals.taxes += Number(item.quantity * item.taxes);

            })
        }
        if (this.cart.selectedAddress?.area?.fees)
            totals.total = Number(totals.subtotal) + Number(this.cart.selectedAddress?.area?.fees)
        else
            totals.total = Number(totals.subtotal)
        totals.total = Number(totals.total.toFixed(2));
        totals.subtotal = Number(totals.subtotal.toFixed(2));
        this.cartTotals = totals;
        this.calculateTotalCount();
        this.updateLocalCartStorage()
    };

    updateLocalCartStorage() {
        localStorage.setItem('cart', JSON.stringify(this.cart));
        window.dispatchEvent(new Event("cart"));

    }

}

export default CartStore;
