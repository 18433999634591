import React from 'react'
import RootStore from "../../mobx-stores/index";
import {useTranslation} from 'react-i18next';
import {number} from "joi";
import { MassegeContext } from '../MsgStore/MsgContext';
import { useContext } from "react";

const CheckoutSide = () => {
    const {t, i18n} = useTranslation();
    let msg = useContext(MassegeContext)


    const {
        cartStore
    } = RootStore.Stores.woocommerceStore.Stores;

    return (


        <ul>
            <h4 className='fontX5'>{t('orderDetails.summary')}</h4>
            <li><h5 className='fontX6'>{t('orderDetails.Total')}</h5><p
                className='fontX6'>{cartStore.cartTotals.subtotal} {t('product.currency')}</p></li>
            <li><h5 className='fontX6'>{t('orderDetails.VAT')} </h5><p
                className='fontX6'>
                    {cartStore.cartTotals.taxes}
                  {t('product.currency')}</p></li>
           
           {cartStore.cart?.promo?.value > 0 ? <li><h5 className='fontX6'>{t('checkOut.Promocode')}</h5><p
                className='fontX6'>{cartStore.cart?.promo?.value} {t('product.currency')}</p></li>:""}
            
            <li>
                <p className='fontX6'> {msg.messages.delivery_cost_notice}</p>
                </li>
            <li className={cartStore.cart?.promo?.value > 0  ? "hasDisCart" : ""}><h5 className='fontX6'>{t('orderDetails.Grand')}</h5>
                {/*<p className='fontX6'>{cartStore.cartTotals.total + cartStore.cart?.selectedAddress?.area?.fees + cartStore.cartTotals.taxes}</p>*/}

                {cartStore.cart?.promo?.value > 0  ? <span className="fontX6">{cartStore.cartTotals.total + cartStore.cartTotals.taxes - cartStore.cart?.promo?.value??0} {t('product.currency')}</span> : ""}

            <p className="fontX6">{cartStore.cartTotals.total+ cartStore.cartTotals.taxes} {t('product.currency')}</p>

            </li>
        </ul>

    )
}

export default CheckoutSide
