import React, {useState} from 'react'
import Map from "./map";
import Header from "../general/header";
import {Link, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';


export const MapAddress = (props) => {
    const {t, i18n} = useTranslation();

    window.addEventListener('areaActive', () => {


        setActive(localStorage.getItem('areaActive'))
    })


    const [active,setActive]=useState(false)


    const {addressId} = useParams()
    return (
        <>
            <Header/>
            <div className='container location-main'>
                <h1 className='fontX3'>{t('mapAdress.title')}</h1>
                <div className='col-xs-12 remove-padding map-main'>
                    <Map/>
                </div>
                <div className='clearfix'></div>
                <Link to={addressId ? `/EditAddress/${addressId}` : "/addAdress"}   className={`solid-btn ${active=='yes'?'enable':'disabled'}`}>{t('addAdress.btn')}</Link>

            </div>


        </>
    )
}

export default MapAddress
