import {Link} from 'react-router-dom';
import React from 'react'
import {useState, useEffect} from 'react';

import Axios from 'axios';
import RootStore from "../../mobx-stores/index";
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import placeHolder from '../../imeges/placeHolder.png';


const {
    woocommerceStore: {
        Stores: {
            cartStore,
        },
    }
} = RootStore.Stores;


const SamilerProducts = () => {
    const token = localStorage.getItem("token");
    const [Product, setProduct] = useState([]);
    const [CartItems, setCartItems] = useState([]);
    const {productId} = useParams()
    const {t, i18n} = useTranslation();

    const [loading, setLoading] = useState(true);


    async function dataApi() {
        const changeLang = localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == 'ar' ? localStorage.getItem("lang") : 'en'

        let {data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/products/${productId}`, {
            headers: {
                'x-lang-code': changeLang,
            }
        });

        const {data: Product_data} = brandsResponse;
        setProduct(Product_data)
    }


    useEffect(() => {
        dataApi().then(r => {
            setLoading(false);

        })
    }, [productId]);


    async function addToCart(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.description


        };

        cartStore.addItem(item)

    }


    window.addEventListener('cart', () => {
        setCartItems([...cartStore.cart.line_items])
    })


    const [addQtbBtn, setAddQtbBtn] = useState();

    async function removeFromCart(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.description

        };

        cartStore.deleteItem(item)
    }

    async function decreaseItemQuantity(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.name

        };

        cartStore.removeItem(item)
    }


    return (
        <>

            <div className='container samiler-products-main products-main'>


                {Product.similar_products?.length > 0 ? <h2 className="fontX3">{t('samilerPruduct.title')}</h2> : ""}
                {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                    <div><Skeleton count={4}/></div>
                </SkeletonTheme> : ""}

                {Product.similar_products && Product.similar_products.map((item, index) => {
                    let existed = 0;

                    if (CartItems) {
                        existed = CartItems.find(
                            cartItem => cartItem.id === item.id
                        )

                    }
                    return <div className='col-md-3 col-sm-6 col-xs-6 product-item' key={index}>

                        <div className='col-xs-12 remove-padding'>
                            {item.discount_percentage <= 0 ?
                                "" :
                                <span className="offer-label">{t('product.save')} {item.discount_percentage} %</span>}

                            <div className='img-frame'><img loading="lazy" src={item.photo_url || placeHolder}
                                                            alt="product name"/></div>

                            {token ?


                                <div>

                                    {!existed ?
                                        <button
                                            onClick={() => addToCart(item)}
                                        >  {t('product.addCart')}
                                        </button> :

                                        <div className='qtn-main'>

                                            <button
                                                className={existed.quantity == 1 ? "icona-minus dis-qtn" : "icona-minus"}
                                                onClick={() => {
                                                    decreaseItemQuantity(item).then(r => {
                                                    });
                                                    item.quantity == 1 ? setAddQtbBtn(false) : setAddQtbBtn(true)
                                                }}></button>

                                            <h4>{existed.quantity}</h4>
                                            <button className="icona-plus" onClick={() => {
                                                addToCart(item).then(r => {
                                                });
                                                existed.quantity == 1 ? setAddQtbBtn(false) : setAddQtbBtn(true)
                                            }}></button>

                                            <span className='icona-minus' onClick={() => removeFromCart(item)}></span>
                                        </div>
                                    }

                                </div>

                                : <Link className='go-log-in-link' to="/login">{t('product.addCart')} </Link>}


                            <Link className="fontX6" to={`/Product/${item.id}`}>{item.name}</Link>

                            <h3 className={item.discount_percentage <= 0 ? "fontX6" : "fontX6 has-offer"}>
                                <b>{item.price} {t('product.currency')}</b>
                                <span>{item.discount_percentage <= 0 ? "" : `${item.old_price} ${t('product.currency')}`} </span>
                            </h3>
                            <h5>{t('product.per')} {item.unit}</h5>

                        </div>
                    </div>
                })}


            </div>

        </>
    )
}

export default SamilerProducts
