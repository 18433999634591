import {action, observable} from "mobx";

import axios from "axios";
import {
    autoCompleteSearchProduct,
    getAllCategories, getProducts, getSubCategories,
    searchProduct, searchWord
} from "../../Requests/requests";

class ProductStore {
    @observable token = localStorage.getItem("token");
    @observable itemsPerPage = 10;
    @observable lang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'
    @observable header = { headers: {'x-lang-code': this.lang, 'Authorization': `Bearer ${this.token}`}}

    autoCompleteProduct = async (search: string) => {
        try {
            return await axios.get(autoCompleteSearchProduct(search), {
                headers: {'x-lang-code': this.lang}
            });

        } catch {

        } finally {

        }
    }
    searchProduct = async (search: string) => {
        try {
            return await axios.get(searchProduct(search), {
                headers: {'x-lang-code': this.lang}
            });

        } catch {

        } finally {

        }
    }
    searchWord = async (search: string, page = 1) => {
        try {
            const data = {'search_name': search, page: page}
            return await axios.post(searchWord, data, {
                headers: {'x-lang-code': this.lang}
            });

        } catch {

        } finally {

        }
    }



    getAllCategories = async () => {
        try {
            return await axios.get(getAllCategories, {
                headers: {'x-lang-code': this.lang}
            });
        } catch {

        } finally {

        }
    }
    getCategoryById = async (id: string) => {
        try {
            return await axios.get(`${getAllCategories}?per_page=10&parent_category_id=${id}`, {
                headers: {'x-lang-code': this.lang}
            });
        } catch {

        } finally {

        }
    }
    getSubCategoriesList = async (categoryId) => {
        try {
            return await axios.get(getSubCategories(categoryId), {
                headers: {'x-lang-code': this.lang}
            });
        } catch {

        } finally {

        }
    }
    getSubCategoriesProducts = async (subCategoryId,page:any =1) => {
        try {
            var data = new FormData();
            data.append('category_ids[0]', subCategoryId);
            data.append("page", page);

            return await axios.post(getProducts, data, {
                headers: {'x-lang-code': this.lang}
            });
        } catch {

        } finally {

        }
    }


}

export default new ProductStore();
