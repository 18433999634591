import React from 'react'
import ProfileSide from '../profile/profileSide'
import Header from '../general/header'
import Footer from '../general/footer'
import ProfilePassword from './ProfilePassword'
import {useEffect, useState} from 'react'
import memberStore from "../../mobx-stores/stores/memberStore/memberStore";
import Axios from 'axios'
import SimpleDateTime from 'react-simple-timestamp-to-date';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import Joi from 'joi';

import {useTranslation} from 'react-i18next';

export const Profile = () => {


    const {t, i18n} = useTranslation();
    const [profileInfo, setProfileInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [editModeName, setEditModeName] = useState(true);
    const [editModeEmail, setEditModeEmail] = useState(true);
    const [editModePhone, setEditModePhone] = useState(true);
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [responseError, setResponseError] = useState();
    const [responseErrorEmail, setResponseErrorEmail] = useState();
    const [loadingP, setLoadingP] = useState(true);


    async function dataApi() {
        const token = localStorage.getItem("token")
        const changeLang = localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == 'ar' ? localStorage.getItem("lang") : 'en'

        let {data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/profile`,{
            headers: {
                'x-lang-code': changeLang,
                'Authorization': `Bearer ${token}`

            }
        });


        const {data: brands} = brandsResponse;
        setProfileInfo(brands)
        setPhone(brands.phone)
    }


// name


    let [newNameVal, setNewNameVal] = useState({
        new_name: '',
    });


    let [errorList, setErrorList] = useState({});

    const [nameBtn, setNameBtn] = useState('');

    function SubmitNameButton() {
        if (nameBtn) {
            return <button className='text-btn' onClick={e => {
                updateName();
                setNewName()
            }}>{t('profile.saveBtn')}</button>

        } else {
            return <button disabled className='text-btn' onClick={e => {
                updateName();
                setNewName()
            }}>{t('profile.saveBtn')}</button>
        }
        ;
    };


    const setNewName = async () => {


        setLoading(true)
        try {
            await dataApi()
            await memberStore.UpdateName(name).then(dataApi)
        } catch {

        } finally {
            setLoading(false)
        }


    }


    const updateName = async () => {
        setEditModeName(true)
    }


    function getFormDate(e) {
        let latestName = {...newNameVal};
        latestName[e.target.name] = e.target.value;
        setNewNameVal(latestName);

    }

    function validateForm(name) {
        setErrorList({});


        const schema = Joi.object({
            name: Joi.string().required().regex(/^([^0-9]*)$/)
                .messages({
                        'string.pattern.base': `${t('validation.Name')}`,
                        'string.empty': `${t('validation.empty')}`
                    }
                )
            ,
        });
        const message = schema.validate({name: name}, {abortEarly: false});
        const {error} = message;
        if (error) {
            const errorData = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                errorData[name] = message;
            }

            setErrorList(errorData);
        }


        return schema.validate({name: name}, {abortEarly: false});

    }


// email

    const [emailBtn, setEmailBtn] = useState('');

    function SubmiteEmailButton() {
        if (emailBtn) {
            return <button className='text-btn' onClick={e => {
                updateEmail();
                setNewEmail()
            }}>{t('profile.saveBtn')}</button>

        } else {
            return <button disabled className='text-btn' onClick={e => {
                updateName();
                setNewName()
            }}>{t('profile.saveBtn')}</button>
        }
        ;
    };


    const setNewEmail = async () => {
        setLoading(true)
        try {
            await memberStore.UpdateEmail(email).then((res) => {
                if (res?.response?.status === false) {
                    setResponseErrorEmail(res?.response?.response?.data?.message)
                    setTimeout(function () {
                        setResponseErrorEmail(false)
                    }, 3000);
                }
                dataApi()
            })


        } catch {
        } finally {
            setLoading(false)
        }
    }


    const updateEmail = () => {
        if (email) {
            let tempInf = {...profileInfo.email}
            tempInf.email = email
            setProfileInfo(tempInf)
        }

        setEditModeEmail(true)
    }


// phone


    const [PhoneBtn, setPhoneBtn] = useState('');

    function SubmitPhoneButton() {
        if (PhoneBtn) {
            return <button className='text-btn' onClick={e => {
                setNewPhone().then(r => {
                })
            }}>{t('profile.saveBtn')}</button>

        } else {
            return <button disabled className='text-btn' onClick={e => {
                setNewPhone().then(r => {
                })
            }}>{t('profile.saveBtn')}</button>
        }
    };


    const setNewPhone = async () => {
        setLoading(true)
        try {
            await memberStore.UpdatePhone(phone).then((res) => {

                if (res?.response?.status === false) {
                    setResponseError(res?.response?.response?.data?.message)

                    setPhone(profileInfo.phone)
                    setTimeout(function () {
                        setResponseError(false)
                    }, 3000);
                } else {
                    if (phone == profileInfo.phone)
                        return
                    localStorage.setItem("new_phone", phone)
                    window.location.href = '/ConfirmNumberNew'
                }
            })

        } catch {

        } finally {
            setLoading(false)
            setEditModePhone(true)
        }
    }

    useEffect(() => {
        dataApi().then(r => {
            setLoadingP(false);

        })
    }, []);


    return (
        <>
            <Header/>

            <div className='container profile-main'>


                {loading ? <div className="loader-line"></div> : ""}


                <div className='col-md-8 col-sm-12 col-xs-12 profile-info'>

                    {loadingP ? <div className='profile-loading'><SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                        <div><Skeleton count={6}/></div>
                    </SkeletonTheme></div> : <>

                        <h2 className='fontX3'>{profileInfo.name}</h2>
                        <h5 className='fontX7'>{t('profile.date')}
                            &nbsp;&nbsp;

                            <SimpleDateTime dateSeparator="/" showTime="0"
                                            format="YMD">{profileInfo.create_at}</SimpleDateTime>
                            &nbsp;&nbsp; | &nbsp;&nbsp;<b> </b>
                            <SimpleDateTime timeSeparator=":" showDate="0">{profileInfo.create_at}</SimpleDateTime>
                        </h5>


                        <h3 className='fontX4'>{t('profile.personalInfo')}</h3>

                        {editModeName ? <div className='col-xs-12 remove-padding profile-info-item'>
                            <p className='fontX8'>{t('profile.name')}</p>
                            <span className='icona-edit-1' onClick={() => {
                                setEditModeName(false);
                                setNameBtn(profileInfo.name)
                            }}></span>
                            <h4 className='fontX6'>{profileInfo.name}</h4>
                        </div> : <div className='edit-pro-info col-xs-12 reove-padding'>
                            <p className='fontX8'>{t('profile.name')}</p>
                            <input onChange={e => {
                                setName(e.target.value);
                                setNameBtn(e.target.value);
                                getFormDate(e)
                                validateForm(e.target.value)
                            }} defaultValue={profileInfo.name} name='new_name'
                                   style={{borderColor: errorList.name ? '#D62921' : ''}}
                            />


                            {errorList.name ? <button disabled className='text-btn'>{t('profile.saveBtn')}</button> :
                                <SubmitNameButton/>}
                            <div style={{display: errorList.name ? '' : 'none'}} className="error-msg">
                                {errorList.name}
                            </div>
                        </div>}


                        {editModePhone ? <div className='col-xs-12 remove-padding profile-info-item'>
                            <p className='fontX8'>{t('profile.phone')}</p>
                            <span className='icona-edit-1' onClick={() => {
                                setEditModePhone(false);
                                setPhoneBtn(phone)
                            }}></span>
                            <h4 className='fontX6'>{phone}</h4>
                        </div> : <div className='edit-pro-info'>
                            <p className='fontX8'>{t('profile.phone')}</p>
                            <input
                                type="number"
                                onChange={e => {
                                    setPhone(e.target.value);
                                    setPhoneBtn(e.target.value);
                                }} defaultValue={phone}/>

                            <SubmitPhoneButton/>
                        </div>}
                        <div className='remove-padding col-xs-12'>
                            {responseError ? <h6 className='erorr-handel-msg'>{responseError}</h6> : <></>}
                        </div>
                        {editModeEmail ? <div className='col-xs-12 remove-padding profile-info-item'>
                            <p className='fontX8'>{t('profile.Email')}</p>
                            <span className='icona-edit-1' onClick={() => {
                                setEditModeEmail(false);
                                setEmailBtn(profileInfo.email)
                            }}></span>
                            <h4 className='fontX6'>{profileInfo.email}</h4>
                        </div> : <div className='edit-pro-info'>
                            <p className='fontX8'>{t('profile.Email')}</p>
                            <input onChange={e => {
                                setEmail(e.target.value);
                                setEmailBtn(e.target.value)
                            }} defaultValue={profileInfo.email}/>

                            <SubmiteEmailButton/>
                        </div>}
                        <div className='remove-padding col-xs-12'>
                            {responseErrorEmail ? <h6 className='erorr-handel-msg'>{responseErrorEmail}</h6> : ''}
                        </div>


                        <ProfilePassword/>

                    </>}


                </div>
                <ProfileSide/>

            </div>
            <Footer/>
        </>
    )
}

export default Profile
