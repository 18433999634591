import React, {useEffect} from 'react'

import axios from 'axios';
import {useState} from 'react'
import logo from '../../imeges/logo.png';
import arFlg from '../../imeges/eg.png';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import enFlg from '../../imeges/ar.png';
import {changeLanguage} from "../../utils/language";



const ConfirmUserNumber = () => {
  const {t, i18n} = useTranslation();
const [reSendMsg, setReSendMsg ] = useState(false)

  const navigate = useNavigate();
  function goToLogin(){
      let path='/'
      navigate(path)
      window.location.refresh();
  }

  let userData = localStorage.getItem('phone');
  let[errorMsg,setErrorMsg] = useState("");
  let[errorReSendMsg,setErrorReSendMsg] = useState("");

  useEffect(() => {
    if(userData == null){
        window.location.href='/Register';
    }
}, []);


  const [messageOne, setMessageOne] = useState('');

  const handleChangeOne = event => {
    setMessageOne(event.target.value);
  };

  const [messageTow, setMessageTow] = useState('');

  const handleChangeTow = event => {
    setMessageTow(event.target.value);
  };

  const [messageThree, setMessageThree] = useState('');

  const handleChangeThree = event => {
    setMessageThree(event.target.value);
  };

  const [messageFour, setMessageFour] = useState('');

  const handleChangeFour = event => {
    setMessageFour(event.target.value);
  };





      async function submitFormData(e){
        e.preventDefault();
        const code = messageOne + messageTow + messageThree + messageFour
        const userConfirm  = {
          phone_number:userData,
          verification_code:code,
        }

        try {
          setErrorMsg('');
          const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

        let {data: response} = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/customer/auth/verify-phone`, userConfirm,{
          headers: {
            'x-lang-code': changeLang,
            'Access-Control-Allow-Origin': 'token'
 }})


          if (response.status ===  "ok") {            
            const {data} = response;
            localStorage.setItem('token', data.token)
            goToLogin()
            window.location.reload();

        }
    } catch (error) {
setErrorMsg(error?.response?.data?.message)
        if (error?.response?.data?.message)
            setErrorMsg( error?.response?.data?.message)
        else
            console.info('')
    }

 }


 async function sendSmsBtn(e){

  const sendSMS  = {
    phone_number:userData,
    channel:"sms",
  }

  try {
    setErrorReSendMsg('');
    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

  let {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/customer/auth/verification/re-send`, sendSMS,{
    headers: {
      'x-lang-code': changeLang,
}})

if (data.status == "ok") {

  setReSendMsg(true)
}


} catch {

}


}


async function sendCallBtn(e){

  const sendCall  = {
    phone_number:userData,
    channel:"call",
  }

  try {
    setErrorReSendMsg('');
    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

  let {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/customer/auth/verification/re-send`, sendCall,{
    headers: {
      'x-lang-code': changeLang,
}})

if (data.status == "ok") {

  setReSendMsg(true)
}else {
  setErrorReSendMsg(data.status);
}


} catch {

}


}   const [NumOne, setNumOne] = useState('');
    const [NumTow, setNumTow] = useState('');
    const [NumThree, setNumThree] = useState('');
    const [NumFour, setNumFour] = useState('');

    function SubmitButton(){
      if (NumOne && NumTow&& NumThree&& NumFour){
        return <button className="solid-btn fontX7" type="submit">{t('confirmPhone.doneBtn')}</button>
      } else {
        return <button className="solid-btn fontX7" type="submit" disabled>{t('confirmPhone.doneBtn')}</button>
      };
    };


    const handleEnter = (event) => {
        if (event.target.value >= 0 ) {
          const form = event.target.form;
          const index = [...form].indexOf(event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        }
      };
  return (
    <div className="container-fluid form-main remove-padding">

<aside className=" col-sm-4 col-xs-12 col-md-5 form-side hidden-xs">
                <div className="logo-main">
                    <a href="#"></a>
                    <Link to="/home"><img loading="lazy" src={logo} alt="Logo"/></Link>
                </div>
                <div className="form-side-info">
                    <h2 className="fontX3">{t('login.title')}</h2>
                    {/* <p className="fontX5">{t('login.subTitle')}</p> */}
                    <p className="fontX5"></p>

                    <Link to="/Register" className="outline-btn fontX6">{t('login.btn')}</Link>
                </div>
                <div className="form-side-fot">


                <ul>
                        {i18n.language == "en" ? <button onClick={e => {
                            changeLanguage("ar");

                        }}>العربية<img loading="lazy" src={arFlg} alt="arFlg"/></button> : <button onClick={e => {
                            changeLanguage("en");

                        }}>
                            English<img loading="lazy" src={enFlg} alt="arFlg"/></button>
                        }
                    </ul>


                    <h5>{t('login.copy')}</h5>
                </div>
            </aside>
   <div className="col-md-7 col-sm-7 log-form-main remove-padding">

   <div className='reg-main col-xs-12'>



         <form onSubmit={submitFormData}  className="col-xs-12 confirmNum-main-form">
         <h1 className="fontX3 text-center">{t('confirmPhone.Verification')}</h1>



         <div className="confirmNum-main col-xs-12">
         <h2 className="fontX3">{t('confirmPhone.Verification')}</h2>
            <p className="fontX6">{t('confirmPhone.VerificationInfo')} {userData}</p>

            <h6>{t('confirmPhone.Enter')}</h6>
         <div className="input-main">
         <input maxLength={1} minLength={1} onKeyUp={handleEnter} onChange={ e => {setNumOne(e.target.value);handleChangeOne(e)}} autoComplete="off" name="numOne" type="tel" />
         </div>
         <div className="input-main">
         <input maxLength={1} minLength={1} onKeyUp={handleEnter} onChange={ e => {setNumTow(e.target.value);handleChangeTow(e)}} autoComplete="off" name="numTow" type="tel" />
         </div>
         <div className="input-main">
         <input maxLength={1} minLength={1} onKeyUp={handleEnter} onChange={ e => {setNumThree(e.target.value);handleChangeThree(e)}}  autoComplete="off" name="numThree" type="tel" />
         </div>
         <div className="input-main">
         <input maxLength={1} minLength={1} onKeyUp={handleEnter} onChange={ e => {setNumFour(e.target.value);handleChangeFour(e)}} autoComplete="off" name="numFour" type="tel" />
         </div>

         {errorMsg&&<p className='error-backend-label'>{errorMsg}</p>}


<h5>{t('confirmPhone.recive')} <span onClick={sendSmsBtn}>{t('confirmPhone.sms')}</span></h5>
{reSendMsg ? <p>{t('confirmPhone.sentMsg')}</p>:""}
{errorReSendMsg&&<p className='error-backend-label'>{errorMsg}</p>}

         </div>
         <SubmitButton/>
         </form>


         <p className='visible-xs fontX6Label'>{t('confirmPhone.havntAccount')} <Link className='text-btn' to="/Register">
         {t('login.btn')}</Link></p>

         <div className="form-side-fot visible-xs">


         <ul>
                        {i18n.language == "en" ? <button onClick={e => {
                            changeLanguage("ar");

                        }}>العربية<img loading="lazy" src={arFlg} alt="arFlg"/></button> : <button onClick={e => {
                            changeLanguage("en");

                        }}>
                            English<img loading="lazy" src={enFlg} alt="arFlg"/></button>
                        }
                    </ul>


<h5>{t('login.copy')}</h5>
</div>


   </div>

</div>


</div>
  )
}

export default ConfirmUserNumber
