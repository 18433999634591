import React, {useState, useEffect} from 'react'
import Axios from 'axios';

import Header from '../general/header'
import Footer from '../general/footer'
import SiteMap from '../general/siteMap'
import {Link} from 'react-router-dom'
import delIcon from '../../imeges/del.svg';
import memberStore from "../../mobx-stores/stores/memberStore/memberStore";
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import noResults from '../../imeges/no-results.png';

// import {ClipLoader} from "react-spinners";
const Tickets = () => {
    const {t, i18n} = useTranslation();

    const [tickets, setTickets] = useState([]);
    const [selectedTicketId, setSelectedTicketId] = useState();
    const [loading, setLoading] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(false);
    const [loadingT, setLoadingT] = useState(true);


    async function dataApi() {
        const token = localStorage.getItem("token")
        const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

        let {data: ticketsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/tickets`,{
            headers: {
                'x-lang-code': changeLang,
                'Authorization': `Bearer ${token}`

            }
        });

        const {data: ticketsData} = ticketsResponse;
        setTickets(ticketsData)

    }


    useEffect(() => {
        dataApi().then(r => {
        setLoadingT(false);

        })
    }, []);


    const deleteTicket = async (tickets_id) => {
        setButtonVisible(true)
        setSelectedTicketId(tickets_id)
    }
    const ConfirmDeleteTicket = async () => {
        setLoading(true)
        try {
            await memberStore.deleteTicket(selectedTicketId).then(dataApi)
        } catch {

        } finally {
            setLoading(false)
            setButtonVisible(false)
        }
    }

    return (
        <>
            <Header/>
            <SiteMap pageName={t('ticketList.title')} pageUrl="/HelpCenter" pageLink={t('helpCenter.title')}/>
            <div className='container ticket-main'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-md-offset-3'>
                    <h1 className='fontX3'>{t('ticketList.title')}</h1>
                    <p className='fontX6'>{t('ticketList.info')}</p>
                    <Link to="/NewTicket" className='outline-btn'><span className='icona-plus'></span>{t('ticketList.btn')}</Link>


                    {loading ? <div className="loader-line"></div> : ""}


                    {loadingT ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={3}/></div>
                        </SkeletonTheme> :     <>

                        {tickets.list.length && tickets.list.length >= 0 ? <div>
                            {tickets.list && tickets.list.map((item, index) =>
                        <div className='col-xs-12 remove-padding ticket-item' key={index}>
                            <Link to={`/Ticket/${item.id}`} className='fontX6'>{t('ticketList.number')}
                                #{item.id}{item.created_at}</Link>
                           {item.is_admin_reply === false ? <span onClick={() => deleteTicket(item.id)}><img src={delIcon}/></span>:""} 
                            <p>{item.title}</p>
                        </div>)}

                        </div> :  <div className='no-results-main no-results-address'>
   <img loading="lazy" src={noResults} alt="no Results"/>
   <h4>{t('NotFound.ticket')}</h4>
   <Link className='solid-btn' to={`/`}>{t('checkOut.Continue')}</Link>
  </div>}

                  

                       


                        </>}
                  

                </div>



                {buttonVisible ? <div className="pop-addcard-main adress-popup-confirm">
                    <div className='pop-addcard '>
                        <img src={delIcon}/>
                        <h2 className='fontX3'>{t('ticketList.Confirm')}</h2>
                        <p>{t('ticketList.delMsg')}</p>


                        <div className='col-xs-12 remove-padding'>
                            <div className='col-md-6 col-xs-6'>
                                <span className='outline-btn' onClick={() => setButtonVisible(false)}>{t('ticketList.No')}</span>
                            </div>
                            <div className='col-md-6 col-xs-6'>
                                <button className='solid-btn' onClick={ConfirmDeleteTicket}>{t('ticketList.yes')}</button>
                            </div>
                        </div>


                    </div>
                    <div className='bg-pop' onClick={() => setButtonVisible(false)}></div>

                </div> : ''}


            </div>

            <Footer/>

        </>
    )
}

export default Tickets
