import React from 'react'
import {useState} from 'react';
import Axios from 'axios'
import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import Header from '../general/header'
import Footer from '../general/footer'
import Cart from '../general/cart'
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import RootStore from "../../mobx-stores/index";


const CartCheckOut = () => {
    const {t, i18n} = useTranslation();
    const [sorrtedAddressList, setSorrtedAddressList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const token = localStorage.getItem("token")


    const {
        cartStore
    } = RootStore.Stores.woocommerceStore.Stores;
    useEffect(() => {

    }, [refresh])

    async function dataApi() {
        let {data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/addresses`, {
            headers: {
                'x-lang-code': 'en',
                'Authorization': `Bearer ${token}`

            }
        });
        const {data: brands} = brandsResponse;

        setSorrtedAddressList(brands)

    }

    useEffect(() => {
        dataApi().then(r => {
            setLoading(false);

        })
    }, []);


    return (
        <div>
            <Header hide="hidden-cart"/>
            <div className='container'>
                <div className='col-xs-12 col-md-8 col-md-offset-2 cart-check-out'>

                    <h1 className='fontX3'>{t('header.cart')}</h1>
                    {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                        <div><Skeleton count={2}/></div>
                    </SkeletonTheme> : <>
                        <Cart/>
                        {cartStore.totalCount > 0 ? <>
                            {sorrtedAddressList.length > 0 ? <Link to="/CheckoutAdress"
                                                                   className='outline-btn checkOutBtn'>{t('header.cheachOutBtn')}</Link> :
                                <Link to="/Loacation"
                                      className='outline-btn checkOutBtn'>{t('header.cheachOutBtn')}</Link>}
                        </> : ""}
                    </>}
                    {console.log(cartStore?.totalCount && cartStore?.totalCount)}

                </div>
            </div>

            <Footer/>
        </div>
    )
}

export default CartCheckOut
