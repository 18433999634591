import React, {useState, useEffect} from 'react'
import Axios from 'axios';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'




export const PartSlider = () => {


    

    const [loading, setLoading] = useState(true);

    const [logos, setLogos] = useState([]);
    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'
    const { t, i18n } = useTranslation(); 
    async function dataApi() { 
        
        let{data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/brands`,{
  headers: {
    'x-lang-code': changeLang,
     
  }
 });
        
        const {data: brands} = brandsResponse;
        setLogos(brands)
        }


    useEffect(() => {
        dataApi().then(r => {
            setLoading(false);

        })
    }, []);





    
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMood: true,
      
      

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    initialSlide: 3,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]

    };
    return (
        <>
            <div className='container remove-padding partner-main'>
          
                <h1 className='title fontX3'>{t('barnds.title')}</h1>
                <div className='clearfix'></div>

                {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={6}/></div>
                        </SkeletonTheme> : ""}
                <Slider {...settings}>
                    {logos && logos.map((item, index) =>
                        <div className='col-xs-12' key={index}>
                           
                            {/* <Link to={`//${item.url}`} target={"_blank"}  className={item.url == null ? "disActive" : ""}></Link> */}
                            <div>
                            <div className='part-content'>
                                <img loading="lazy" src={item.photo_url}/>
                                <div className='partner-info'>
                                    <p>{item.name}</p>
                                </div>
                            </div>
                            </div>
                        </div>)}
                </Slider>
            </div>


            {/* {logos.map((item,index)=><div key={index}><p>{item.title}</p><img src={item.url}/></div>)} */}


        </>
    )
}

export default PartSlider
