import React from 'react'
import {useState, useEffect} from 'react'
import logo from '../../imeges/logo.png';
import {Link} from 'react-router-dom';
import arFlg from '../../imeges/eg.png';
import enFlg from '../../imeges/ar.png';


import Cart from "../general/cart"
import {useNavigate} from 'react-router-dom';
import RootStore from "../../mobx-stores/index";

import ProductStore from "../../mobx-stores/stores/productStore/productStore";

import {useTranslation} from 'react-i18next';
import i18next from "i18next";
import {changeLanguage} from "../../utils/language";


// import Cart from './cart';


export const Header = (props) => {

    const {t, i18n} = useTranslation();


    const [showMenu, setShowMenu] = useState();
    const [showSearch, setShowSearch] = useState();
    const [refresh, setRefresh] = useState(false);
    const [searchAutoComplete, setShowSearchAutoComplete] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [searchVal, setSearchVal] = useState("");

    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    function logOut() {
        localStorage.removeItem("token")
        // localStorage.removeItem("areaActive")
        localStorage.removeItem("tken")
        // localStorage.removeItem("address")
        // localStorage.removeItem("lat")
        localStorage.removeItem("username")
        localStorage.removeItem("ticket_id")
        localStorage.removeItem("name")
        // localStorage.removeItem("lng")
        window.location.reload();
        let path = '/Home'
        navigate(path)
    }


    window.addEventListener('cart', () => {
        setRefresh(!refresh)
    })
    const {
        cartStore
    } = RootStore.Stores.woocommerceStore.Stores;
    useEffect(() => {

    }, [refresh])
    const searchChange = async (props) => {
        let search = props.target.value
        setSearchVal(search)
        setSearchData([])
        setShowSearchAutoComplete(false)
        if (search) {
            const result = await ProductStore.autoCompleteProduct(search);
             if (result.data) {
                setSearchData(result.data.data)
                setShowSearchAutoComplete(true)
            }
        }
    }


    return (
        <>


            <header className={`container-fluid header-main ${props.hide}`}>

                {/* {cartStore.totalCount > 0 ? <Link to="/cartCheckOut" className='cart-btn-float'>
<span className='icona-cart'><p>{cartStore.totalCount}</p></span>
</Link> : ""} */}
                <div className="container remove-padding">
                    <div className="col-md-3 col-lg-2 logo-main">
                        <ul>
                            <li className='logo-link'><Link to="/Home"><img loading="lazy" src={logo}
                                                                            alt="Logo"/></Link></li>

                            {i18n.language == "en" ? <li className='lang-link ar-lang hidden-sm hidden-xs'>

                                <button
                                    onClick={e => {
                                        changeLanguage("ar")

                                    }}>العربية<img src={arFlg} alt="arFlg"/></button>
                            </li> : <li className='lang-link hidden-sm hidden-xs'>

                                <button onClick={e => {
                                    changeLanguage("en")
                                }

                                }>English<img src={enFlg} alt="arFlg"/></button>
                            </li>
                            }


                        </ul>
                    </div>
                    <div className="col-md-5 col-lg-6 col-lg-offset-1">
                        <div>
                            <form onSubmit={e => {
                                e.preventDefault();
                                return false;
                            }} className={showSearch ? "showSearch" : ""}>
                                <div className='search-form-main'>
                                    <span className='icona-search '></span>
                                    <input type="text" onChange={searchChange}
                                           onKeyDown={(event) => {
                                               if (event.key == "Enter") {

                                                   setShowSearch(false)

                                                   window.location.href = `/ProductsFilter/?searchValue=${searchVal}`;

                                                   // event.preventDefault();
                                               }
                                           }}
                                           placeholder={t('search.placeHolder')}/>
                                    {/* <b onClick={ () =>navigate('/ProductsFilter`,{state: {search: searchVal}})}>search</b> */}
                                    <p className='close-search-btn visible-xs visible-sm'
                                       onClick={() => setShowSearch(false)}>{t('search.close')}</p>
                                </div>
                                <div>
                                    {searchAutoComplete &&searchVal&&
                                        <ul>

                                            <li key={0}>
                                           

                                                <a href="" onClick={() => {
                                                    navigate('/ProductsFilter', {state: {search: searchVal}})
                                                }}><span>{t('search.SearchFor')}</span> ({searchVal}) </a>
                                            </li>

                                        {searchAutoComplete && searchVal&&searchData.map((el, idx) =>

                                            <li key={idx}>
                                                <a href="" onClick={() => {
                                                    navigate(`/Product/${el.id}`)
                                                }}>  {el.name}</a>
                                            </li>
                                        )}
                                    </ul>
                                    }
                                </div>
                                <div className='serach-bg' onClick={() => setShowSearch(false)}></div>
                            </form>
                        </div>
                    </div>
                    <div className={showMenu ? "showMenu" : ""}>


                        {token ?
                            (
                                <div className="col-md-4 col-lg-3 head-men-main logdin-menu">

                                    <li className='hidden-sm hidden-xs cheackout-cart-link'><Link
                                        to="/cartCheckOut">
                                        {cartStore.totalCount > 0 ? <span>{cartStore.totalCount}</span> : ""}

                                        <b
                                            className='icona-cart'></b>{t('header.cart')}</Link></li>


                                    <li className='visible-sm visible-xs'>  <span className='icona-close'
                                                                                  onClick={() => setShowMenu(false)}></span>
                                    </li>
                                    <li className='cart-li hidden-sm hidden-xs'>


                                        <Cart url="/cartCheckOut"/>

                                    </li>
                                    <li><Link to="/Profile"><span
                                        className='icona-user'></span>{t('helpCenter.profile')}</Link></li>
                                    <li><a onClick={logOut}><span className='icona-exit'></span> {t('header.singOut')}
                                    </a></li>
                                    <li className='lang-link visible-sm visible-xs'>

                                        {i18n.language == "en" ? <button onClick={e => {
                                            changeLanguage("ar");
                                        }}>العربية<img src={arFlg} alt="arFlg"/></button> : <button onClick={e => {
                                            changeLanguage("en");

                                        }}>
                                            English<img src={enFlg} alt="arFlg"/></button>
                                        }

                                    </li>

                                </div>
                            )
                            : (
                                <>
                                    <div className="col-md-4 col-lg-3 head-men-main">
                                        <ul>
                                            <li>  <span className='icona-close visible-sm visible-xs'
                                                        onClick={() => setShowMenu(false)}></span></li>

                                            <li><Link to="/login">{t('header.singIn')}</Link></li>
                                            <li><Link to="/Register" className='solid-btn'>{t('header.singUp')}</Link>
                                            </li>
                                            <li className='lang-link visible-sm visible-xs'>
                                                {i18n.language == "en" ? <button onClick={e => {
                                                        changeLanguage("ar");

                                                    }}>العربية<img src={arFlg} alt="arFlg"/></button> :
                                                    <button onClick={e => {
                                                        changeLanguage("en");

                                                    }}>
                                                        English<img src={enFlg} alt="arFlg"/></button>
                                                }

                                            </li>

                                        </ul>
                                    </div>

                                </>
                            )
                        }


                    </div>

                    <ul className='visible-sm visible-xs mobile-btns'>
                        <li className='search-icon'><span className='icona-search'
                                                          onClick={() => setShowSearch(true)}></span></li>
                        <li>{token ? <Link to="/cartCheckOut" className='icona-cart'>

                            {cartStore.totalCount > 0 ? <span>{cartStore.totalCount}</span> : ""}

                        </Link> : ""}</li>


                        <li><span className='icona-burger' onClick={() => setShowMenu(true)}></span></li>

                    </ul>


                </div>


            </header>


        </>
    )
}

export default Header
