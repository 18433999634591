import React, {useState, useEffect} from 'react'
import CheckoutSide from './CheckOutSide'

import SiteMap from '../general/siteMap'
import Header from '../general/header'
import Footer from '../general/footer'
import RootStore from "../../mobx-stores/index";
import orderStore from "../../mobx-stores/stores/orderStore/index";
import {IPAYMENT_METHOD} from "../../mobx-stores/stores/orderStore/types";
import {useTranslation} from 'react-i18next';

import { Link } from 'react-router-dom'

const {
  woocommerceStore: {
      Stores: {
          cartStore,
      },
  }
} = RootStore.Stores;

const CheckoutReview = () => {




  useEffect(() => {
    if(cartStore?.cart?.Time == ""){
        window.location.href='/CheckoutDownPaymentDate';
    }
}, []);

  useEffect(() => {
    if(cartStore?.cart?.Date == ""){
        window.location.href='/CheckoutDownPaymentDate';
    }
}, []);

  useEffect(() => {
    if(!cartStore?.cart?.selectedCashAddress?.id){
        window.location.href='/CheckoutDownPayment';
    }
}, []);

    useEffect(() => {
        if(!cartStore?.cart?.selectedAddress?.id){
            window.location.href='/CheckoutAdress';
        }
    }, []);


    useEffect(() => {
        if(cartStore.cart.line_items.length == 0){
            window.location.href='/CartCheckOut';
        }
    }, []);


  const {t, i18n} = useTranslation();

  const [showDonePop, setShowDonePop] = useState();
  const [cratedOrder, setCratedOrder] = useState();
  const [needLabor, setNeedLabor] = useState(0);

  useEffect(() => {
    cartStore.setLabor(needLabor)
}, []);

  const handleChange = () => {
    cartStore.setLabor(needLabor === 1 ? 0 : 1 )
    setNeedLabor(needLabor === 1 ? 0 : 1 );
    
  }



console.log(needLabor)

 
  const createOrder= async (e)=>{
    e.preventDefault();



  let res= await orderStore.createOrder()

    if (res.status && res.orderId){

      setCratedOrder(res)
      setShowDonePop(true)
    }
    // navigate(`/orderDetails/${res.orderId}`)


}

console.log(cartStore?.cart)


  return (
    <>
    <Header hide="hidden-cart"/>
    <SiteMap pageUrl="/CartCheckOut"  pageLink={t('checkOut.title')} pageName={t('checkOut.review')}/>



    <div className='container cheackout-main'>
    <h1 className='fontX3'>{t('checkOut.title')}</h1>
         <div className='col-lg-7 col-xs-12 col-md-6 remove-padding checkout-adress'>



           <div className='chosen-adress col-xs-12 remove-padding'>
             <h3 className='fontX4'>{t('checkOut.subTileAdress')}</h3>
             <Link to="/CheckoutAdress" className='text-btn'>{t('checkOut.change')}</Link>
             <h5 className='fontX6'>
             {`${cartStore?.cart?.selectedAddress?.address_title}-`}

             </h5>
             <p>{`${cartStore?.cart?.selectedAddress?.street}-${cartStore?.cart?.selectedAddress?.address_title}-`}</p>
           </div>

           <div className='chosen-adress col-xs-12 remove-padding'>
             <h3 className='fontX4'>{t('checkOut.Promocode')}</h3>
             <Link to="/CheckoutPromoCode" className='text-btn'>{t('checkOut.change')}</Link>
             <h5 className='fontX6'>{t('checkOut.Promocode')}</h5>
             {`${cartStore?.cart?.promo?.code??'__'}`}
           </div>

           <div className='chosen-adress col-xs-12 remove-padding'>
             <h3 className='fontX4'>{t('checkOut.Payment')}</h3>
             <Link to="/CheckoutPayment" className='text-btn'> {t('checkOut.change')}</Link>
            <div className='clearfix'></div>
             <p> {t('checkOut.Cash')} </p>
           </div>


           <div className='chosen-adress col-xs-12 remove-padding review-product-down'>
             <h3 className='fontX4'>{t('checkOut.Downpayment')} </h3>
             <Link to="/CheckoutDownPayment" className='text-btn'>{t('checkOut.change')}</Link>
            <div className='clearfix'></div>
             <p><b>{t('checkOut.Adress')} : </b>{`${cartStore?.cart?.selectedCashAddress?.street}-${cartStore?.cart?.selectedCashAddress?.address_title}-`}</p>
             <p><b>{t('checkOut.date')} : </b> {`${cartStore?.cart?.Date}`}</p>
             <p><b>{t('checkOut.time')} : </b> {t('checkOut.from')} {`${cartStore?.cart?.Time?.from}`} {t('checkOut.to')} {`${cartStore?.cart?.Time?.to}`}</p>
<div className='col-xs-12 remove-padding need-labor'>

   <label className='checkbox-main'>{t('checkOut.lober')}
    <input  value={needLabor} onChange={(e) => {handleChange(e) }}type='checkbox' /> 
    <span className="checkmark fontX6Label"></span>
   </label>
</div>


           </div>







         </div>
         <div className='col-md-4 col-xs-12 col-md-offset-1 remove-padding'>
         <div className='checkout-side'>

<CheckoutSide/>

<button className='solid-btn' onClick={createOrder}>{t('checkOut.placeBtn')}</button>

</div>
         </div>

        </div>

        <div className={showDonePop ? "pop-addcard-main" : " showPop"}>
                <div className='pop-addcard order-done-pop'>
                    <span className='icona-check'></span>
                    <h2 className='fontX3'>{t('checkOut.placedOrder')}</h2>
                    <p className='fontX5'>{t('checkOut.OrderNumber')} {cratedOrder&&cratedOrder.orderId}</p>
                    <ul>
                        <li><Link className='solid-btn' to={`/`}>{t('checkOut.Continue')}</Link></li>
                        <li><Link className='outline-btn' to={`/orderDetails/${cratedOrder&&cratedOrder.orderId}`}>{t('checkOut.orderDetails')}</Link></li>

                    </ul>



                </div>
                <div className='bg-pop order-done-bg'></div>

            </div>

        <Footer/>

        </>
  )
}

export default CheckoutReview
