import React from 'react'
import { useTranslation } from 'react-i18next';



export const WhyUs = () => {  
   const { t, i18n } = useTranslation();
  


    return (
       <>
          <div className='container-fluid whyUs-main'>
               <div className='container remove-padding'>
                     <h1 className='title fontX3'>{t('aboutUs.title')}</h1>
                    <div className='col-md-4'>
                       <div className='col-xs-12 why-item'>
                        <h4 className='fontX5'><span className='icona-filter2'></span>{t('aboutUs.FStitle')}</h4>
                        <p>{t('aboutUs.Ftext')}</p>
                    </div>
                    </div>
                    <div className='col-md-4'>
                       <div className='col-xs-12 why-item'>
                        <h4 className='fontX5'><span className='icona-bag'></span>{t('aboutUs.SStitle')}</h4>
                        <p>{t('aboutUs.Stext')}</p>
                    </div>
                    </div>
                    <div className='col-md-4'>
                       <div className='col-xs-12 why-item'>
                        <h4 className='fontX5'><span className='icont-truck'></span>{t('aboutUs.TStitle')}</h4>
                        <p>{t('aboutUs.Ttext')}</p>
                    </div>
                    </div>
               </div>
               
          </div>
       
       </>
    )
}

export default WhyUs