import React, {useEffect} from 'react'
import axios from 'axios';
import {useState} from 'react'
import logo from '../../imeges/logo.png';
import arFlg from '../../imeges/eg.png';
import googleImg from '../../imeges/gogole.png';
import fbImg from '../../imeges/fb.png';
import enFlg from '../../imeges/ar.png';

import {Link} from 'react-router-dom';
import Joi from 'joi';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import {gapi} from 'gapi-script';
import memberStore from "../../mobx-stores/stores/memberStore/memberStore";

// import env from 'react-dotenv';


import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {changeLanguage} from "../../utils/language";


export const LogIn = () => {

    const {t, i18n} = useTranslation();

// eye password


    const clientId = '856709740247-j6i9i3295gvgd00ig73o4mdvimmj6fr0.apps.googleusercontent.com'

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    }, []);

    const toggleIsPasswordShowValue = () => {
        setIsPasswordShow(!isPasswordShow);
    };
    const [isPasswordShow, setIsPasswordShow] = useState(false);
    const toggleIsPasswordShowValue1 = () => {
        setIsPasswordShow1(!isPasswordShow1);
    };
    const [isPasswordShow1, setIsPasswordShow1] = useState(false);


    const navigate = useNavigate();

    function goConfirm() {
        let path = '/'
        navigate(path);
    }

    function goConfirmNum() {
        let path = '/ConfirmUserNumber'
        navigate(path);
        window.location.refresh();

    }



// user info

    let [user, setUser] = useState({
        username: '',
        password: '',
    });


    let [errorMsg, setErrorMsg] = useState("");
    let [errorList, setErrorList] = useState({});

//
    //
    async function submitFormData(e) {



        e.preventDefault();
        let validationResult = validateForm();
        const {error} = validationResult;

        if (error) {
            const errorData = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                errorData[name] = message;
            }
            setErrorList(errorData);
        } else {
            // validateForm()

            try {
                setErrorMsg('');

                // console.info(process.env.REACT_APP_BASE_URL)
                // console.info('process.env.REACT_APP_BASE_URL')

                const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

                const {data: response} = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/customer/auth/login`, user, {
                    headers: {
                        'x-lang-code': changeLang,
                        'Access-Control-Allow-Origin': 'token'
                    }
                });




                if (response.status === "ok") {

                    const {data} = response;
                    localStorage.setItem('token', data.token)

                    goConfirm()
                    window.location.reload();

                }
            } catch (error) {

                if (error?.response?.data?.code == "phone_not_verified")
                goConfirmNum()
                localStorage.setItem("username", user.username)

                if (error?.response?.data?.message)
                    setErrorMsg(error?.response?.data?.message)
                else
                    console.info('')
            }


        }
    }

    function getFormDate(e) {
        let myUser = {...user};
        myUser[e.target.name] = e.target.value;
        setUser(myUser);

    }

    function validateForm() {
        const schema = Joi.object({
            username: Joi.string().required(),
            password: Joi.string().required().label('Password'),
        });

        return schema.validate(user, {abortEarly: false});
    }

// disable btn val


    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    function SubmitButton() {
        if (username && password) {
            return <button className="solid-btn fontX7" type="submit">{t('login.Lbtn')}</button>
        } else {
            return <button className="solid-btn fontX7" type="submit" disabled>{t('login.Lbtn')}</button>
        }
        ;
    };
    const responseGoogle = async (response) => {
        const res = await memberStore.socialLogin(response?.googleId,'google')
        if (res) {
            window.location.href='/'
        }

    }
    const responseFacebook = async (response) => {
        const res = await memberStore.socialLogin(response.id,'facebook')
        if (res) {
            window.location.href='/'
        }
    }


    return (


        <div className="container-fluid form-main remove-padding">


            <aside className=" col-sm-4 col-xs-12 col-md-5 form-side hidden-xs">
                <div className="logo-main">
                    <a href="#"></a>
                    <Link to="/home"><img src={logo} alt="Logo"/></Link>
                </div>
                <div className="form-side-info">
                    <h2 className="fontX3">{t('login.title')}</h2>
                    {/* <p className="fontX5">{t('login.subTitle')}</p> */}
                    <p className="fontX5"></p>

                    <Link to="/Register" className="outline-btn fontX6">{t('login.btn')}</Link>
                </div>
                <div className="form-side-fot">


                    <ul>


                        {i18n.language == "en" ? <button onClick={e => {
                            changeLanguage("ar");

                        }}>العربية<img src={arFlg} alt="arFlg"/></button> : <button onClick={e => {
                            changeLanguage("en");

                        }}>
                            English<img src={enFlg} alt="arFlg"/></button>
                        }

                    </ul>


                    <h5>{t('login.copy')}</h5>
                </div>
            </aside>


            <div className="col-md-7 col-sm-8 log-form-main remove-padding">
            <div className='reg-main col-xs-12'>
            <Link className='visible-xs' to="/home"><img src={logo} alt="Logo"/></Link>

                <h1 className="fontX3">{t('Register.title')}</h1>

                <div className="row">
                    <ul className="col-xs-12 remove-padding">




                        <li className="col-md-6 col-sm-6">
                            <GoogleLogin
                                clientId={clientId}
                                render={renderProps => (
                                    <button className="outline-btn google-btn" onClick={renderProps.onClick}>
                                        <img src={googleImg} alt="Logo"/>{t('login.google')}
                                    </button>
                                )}
                                buttonText="Login"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />

                        </li>
                        <li className="col-md-6 col-sm-6">


                            <FacebookLogin
                                appId={process.env.REACT_APP_FB_ID}
                                autoLoad={false}
                                callback={responseFacebook}
                                textButton={t('login.facebook')}
                                icon={<img src={fbImg} alt="Logo"/>}
                                render={renderProps => (
                                    <button className="outline-btn facebook-btn" onClick={renderProps.onClick}></button>
                                )}
                            />
                        </li>


                    </ul>
                    <h2 className='fontX4'><span>{t('login.loginOption')}</span></h2>
                    <form onSubmit={submitFormData}>
                        {errorMsg && <p className='error-backend-label'>{errorMsg}</p>}
                        {errorMsg ? <div className="erorMsg"></div> : ""}


                        <div className="col-xs-12 input-main remove-padding">
                            <label>{t('login.userName')}</label>
                            <input style={{borderColor: errorList.username ? '#D62921' : ''}} onChange={e => {
                                setUsername(e.target.value);
                                getFormDate(e)
                            }} autoComplete="off" name="username" type="text"/>
                            <div style={{display: errorList.username ? '' : 'none'}} className="error-msg">
                                {errorList.username}
                            </div>
                        </div>


                        <div className="col-xs-12 input-main remove-padding">
                            <label>{t('login.password')}</label>
                            <input
                                name="password"
                                autoComplete="off"
                                type={isPasswordShow ? 'text' : 'password'}
                                style={{borderColor: errorList.confirm_password ? '#D62921' : ''}}
                                onChange={e => {
                                    setPassword(e.target.value);
                                    getFormDate(e)
                                }}


                            />
                            <span className={isPasswordShow ? `icona-eye-close` : `icona-eye`}
                                  onClick={toggleIsPasswordShowValue}>

         </span>


                            <div style={{display: errorList.password ? '' : 'none'}} className="error-msg">
                                {errorList.password}
                            </div>
                            <Link className='text-btn forgot-btn' to="/forgotPasswprd">{t('login.passwordLink')}</Link>

                        </div>

                        <div className='col-xs-12 remove-padding'><label className="checkbox-main">{t('login.Remmber')}
                            <input type="checkbox"/>
                            <span className="checkmark fontX6Label"></span>
                        </label></div>


                        <SubmitButton/>


                    </form>


                </div>
                <aside/>
                <p className='visible-xs fontX6Label'>{t('login.haventAcount')} <Link className='text-btn'
                                                                                      to="/Register">{t('login.btn')}</Link>
                </p>

                <div className="form-side-fot visible-xs text-center">


<ul>


    {i18n.language == "en" ? <button onClick={e => {
        changeLanguage("ar");

    }}>العربية<img src={arFlg} alt="arFlg"/></button> : <button onClick={e => {
        changeLanguage("en");

    }}>
        English<img src={enFlg} alt="arFlg"/></button>
    }

</ul>


<h5>{t('login.copy')}</h5>
</div>
            </div>
</div>
        </div>
    )


}
export default LogIn
