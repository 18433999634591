import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import React, {Component} from 'react';
import SearchBox from "./searchBox";
import Geocode from "react-geocode";
import {ClipLoader} from "react-spinners";
import memberStore from "../../mobx-stores/stores/memberStore/memberStore";

export class MapContainer extends Component {



    handleFormSubmit = async (lat, lng, address) => {

        if (!lat && localStorage.getItem('lat')) {
            const editAddress = JSON.parse(localStorage.getItem('editAddress'))
            let userAddress = localStorage.getItem('address');

            if (editAddress&&!userAddress){
                let address = await this.get_address(editAddress.lat, editAddress.long)
                this.setState({
                    lat: editAddress.lat,
                    lng: editAddress.long,
                    address: address,
                })
                localStorage.setItem("lat", editAddress.lat)
                localStorage.setItem("lng", editAddress.long)
                localStorage.setItem("address", address)
                window.dispatchEvent(new Event("address"));
            }

            this.setState({
                lat: localStorage.getItem('lat'),
                lng: localStorage.getItem('lng'),
                address: localStorage.getItem('address'),
            })
        } else if (lat) {
            this.setState({
                lat: lat,
                lng: lng,
                address: address
            })
            localStorage.setItem("lat", lat)
            localStorage.setItem("lng", lng)
            localStorage.setItem("address", address)
            window.dispatchEvent(new Event("address"));
        }

    };


    state = {
        address: 'tahrir square',
        lat: 30.0444,
        lng: 31.2357,
        loading: false,
        areaActive:false,

    };




    componentDidMount() {
        this.handleFormSubmit()
    }


    get_address = async (lat, long) => {
        const checkAddressAvailable=await memberStore.checkAddress(lat,long);

        if (checkAddressAvailable?.data?.status=='ok'){
            Geocode.setApiKey("AIzaSyALFyWepEG54N7yAO5RngPn-cJcXaYMo0s");
            let address = '';
            document.getElementById("erorrMsg").innerHTML = "";
            await Geocode.fromLatLng(lat, long).then(
                (response) => {
                    address = response.results[0].formatted_address;
                },
                (error) => {
                    console.error(error);
                }

            );
            localStorage.setItem('areaActive', 'yes');
            window.dispatchEvent(new Event("areaActive"));
            return address;



        }
        else {
            localStorage.setItem('areaActive', 'no');
            window.dispatchEvent(new Event("areaActive"));
            // alert(checkAddressAvailable?.data.message)
            document.getElementById("erorrMsg").innerHTML = `<p>${checkAddressAvailable?.data.message}</p>`;
            return
        }


    }

    onMapClicked = async (props, marker, e) => {
        this.state.loading = true
        let address = await this.get_address(e.latLng.lat(), e.latLng.lng())
        await this.handleFormSubmit(e.latLng.lat(), e.latLng.lng(), address)
        this.state.loading = false
    };
    onPlacesChanged = async (places) => {
        this.state.loading = true
        let address = await this.get_address(places[0].geometry.location.lat(), places[0].geometry.location.lng())
        await this.handleFormSubmit(places[0].geometry.location.lat(), places[0].geometry.location.lng(), address)
        this.state.loading = false
    }

    render() {

        const markerCenter = {
            lat: this.state.lat,
            lng: this.state.lng
        };

        return (
            <>
   <div id='erorrMsg'></div>

                <ClipLoader
                    // color={color}
                    loading={this.state.loading}
                    cssOverride={{display: "flex", position: "absolute", left: "50%", right: "50%", top: '60vh'}}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                <SearchBox
                    google={this.props.google}
                    onPlacesChanged={ this.onPlacesChanged}
                    placeholder="Search location"
                    default={this.state.address}
                />
                <Map initialCenter={markerCenter} center={markerCenter} google={this.props.google} zoom={14}

                     onClick={this.onMapClicked}
                >


                    <Marker
                        title="Location"
                        id={1}
                        position={{lat: this.state.lat, lng: this.state.lng}}
                        draggable={true}
                        icon={{url: require('../../imeges/Union.png')}}
                        onDragend={this.onMapClicked}
                        name={this.state.address}

                    />

                </Map>




                {/* <h1>{this.state.address}</h1> */}

            </>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyALFyWepEG54N7yAO5RngPn-cJcXaYMo0s")
})(MapContainer)
