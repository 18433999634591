export interface IOrder {
    customer_address_id: number;
    down_payment_address_id: number;
    time_slot_id: number;
    cash_voucher_id: number;
    down_payment_date?: string;
    payment_method: IPAYMENT_METHOD;
    channel: IChanel,
    order_items: IOrderItems[];
    manual_labor?:boolean;

}

export enum IChanel {
    Website = 1
}

export enum IPAYMENT_METHOD {
    cash = 1,
    card = 2,
}


export type IOrderItems = {
    product_id: number,
    quantity: number,
    unit: any,
}
