import React from 'react'
import axios from 'axios';
import {useState, useEffect} from 'react'
import logo from '../../imeges/logo.png';
import arFlg from '../../imeges/eg.png';
import enFlg from '../../imeges/ar.png';

import {Link} from 'react-router-dom';
import Joi from 'joi';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {changeLanguage} from "../../utils/language";


export const ForgotPasswprd = () => {

    const {t, i18n} = useTranslation();

    const navigate = useNavigate();

    function goConfirm() {
        let path = '/ConfirmNumber'
        navigate(path)
    }


// user info

    let [user, setUser] = useState({
        username: '',
    });


    let [errorMsg, setErrorMsg] = useState("");
    let [errorList, setErrorList] = useState({});

//
    //
    async function submitFormData(e) {
        e.preventDefault();
        let validationResult = validateForm();
        const {error} = validationResult;
        if (error) {
            const errorData = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                errorData[name] = message;
            }
            setErrorList(errorData);
        } else {
            // validateForm()

            try {
                setErrorMsg('');

                const changeLang = localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == 'ar' ? localStorage.getItem("lang") : 'en'

                let {data: response} = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/customer/auth/forgot-password`, user, {

                    headers: {
                        'x-lang-code': changeLang,
                    }
                });


                if (response.status === "ok") {
                    const {data} = response;
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('name', data.name)
                    goConfirm()

                }

            } catch (error) {

                if (error?.response?.data?.message)
                    setErrorMsg(error?.response?.data?.message)
                else
                    goConfirm()
            }


        }
    }


    function getFormDate(e) {
        let myUser = {...user};
        myUser[e.target.name] = e.target.value;
        setUser(myUser);
    }


    function validateForm() {
        const schema = Joi.object({
            username: Joi.required(),
        });

        return schema.validate(user, {abortEarly: false});

    }


// disable btn val


    const [username, setUsername] = useState('');


    function SubmitButton() {
        if (username) {
            return <button className="solid-btn fontX7" type="submit">{t('login.ForgotBtn')}</button>
        } else {
            return <button className="solid-btn fontX7" type="submit" disabled>{t('login.ForgotBtn')}</button>
        }
        ;
    };


    return (

        <div className="container-fluid form-main remove-padding">

            <aside className=" col-sm-4 col-xs-12 col-md-5 form-side hidden-xs">
                <div className="logo-main">
                    <a href="#"></a>
                    <Link to="/home"><img loading="lazy" src={logo} alt="Logo"/></Link>
                </div>
                <div className="form-side-info">
                    <h2 className="fontX3">{t('login.title')}</h2>
                    {/* <p className="fontX5">{t('login.subTitle')}</p> */}
                    <p className="fontX5"></p>

                    <p className="fontX5"></p>

                    <Link to="/Register" className="outline-btn fontX6">{t('login.btn')}</Link>
                </div>
                <div className="form-side-fot">


                    <ul>


                        {i18n.language == "en" ? <button
                            onClick={e => {
                                changeLanguage("ar");
                            }}>العربية<img loading="lazy" src={arFlg} alt="arFlg"/></button> : <button
                            onClick={e => {
                                changeLanguage("en");

                            }}>
                            English<img loading="lazy" src={enFlg} alt="arFlg"/></button>
                        }

                    </ul>


                    <h5>{t('login.copy')}</h5>
                </div>
            </aside>
            <div className="col-md-7 col-sm-8 log-form-main remove-adding">
                <div className='reg-main col-xs-12'>

                    <h1 className="fontX3">{t('login.ForgotBtn')}</h1>

                    <div className="row">


                        <form onSubmit={submitFormData}>
                            {errorMsg && <p className='error-backend-label'>{errorMsg}</p>}
                            {errorMsg ? <div className="erorMsg"></div> : ""}


                            <div className="col-xs-12 input-main remove-padding">
                                <label>{t('login.userName')}</label>
                                <input style={{borderColor: errorList.username ? '#D62921' : ''}} onChange={e => {
                                    setUsername(e.target.value);
                                    getFormDate(e)
                                }} autoComplete="off" name="username" type="text"/>
                                <div style={{display: errorList.username ? '' : 'none'}} className="error-msg">
                                    {errorList.username}
                                </div>
                            </div>


                            <SubmitButton/>


                        </form>

                        <div className="form-side-fot visible-xs text-center">


                            <ul>


                                {i18n.language == "en" ? <button onClick={e => {
                                    changeLanguage("ar");

                                }}>العربية<img loading="lazy" src={arFlg} alt="arFlg"/></button> : <button onClick={e => {
                                    changeLanguage("en");

                                }}>
                                    English<img loading="lazy" src={enFlg} alt="arFlg"/></button>
                                }

                            </ul>


                            <h5>{t('login.copy')}</h5>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )


}
export default ForgotPasswprd
