import {action, observable} from "mobx";
// import {persist} from "mobx-persist";
import {CreateAddress, EditAddress} from "./types";

import axios from "axios";
import {
    deleteRequest,
    deleteRequestTicket,
    UpdateName,
    UpdateEmail,
    UpdatePhone,
    UpdateCreateAddress, register, social, checkLat
} from "../../Requests/requests";
import {useNavigate} from "react-router-dom";
        const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

class MemberStore {
    @observable isUpdatingUser = false;
    @observable token = localStorage.getItem("token");
    // navigate = useNavigate();

    /* @persist("object") @observable user: User = {
     };*/


    async socialLogin(id,provider) {
        try {
            let data = {
                social_id: id
            }
        const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

         const res=  await axios.post(social(provider), data, {
                headers: {'x-lang-code': changeLang, 'Authorization': `Bearer ${this.token}`}
            });

            if (res?.data?.status === "ok") {
                const {data:{data}} = res;
                localStorage.setItem('token', data.token)
               return true
            }
            return false

        } catch {

        } finally {

        }
    }





    async register(user) {

        try {

             const res=   await axios.post(register, user, {
                headers: {
                    'x-lang-code': changeLang,
                }
            });
            return res


        } catch (e){
            return e.response
        } finally {

        }
    }
    async checkAddress(lat,long) {

        try {

            const data={lat:`${lat}`,long:`${long}`}

             const res=   await axios.post(checkLat, data, {
                 headers: {
                     'x-lang-code': changeLang, 'Authorization':
                         `Bearer ${this.token}`
                 }
            });
            return res


        } catch (e){
            return e.response
        } finally {

        }
    }

    async facebookRegister(id) {
        try {
            let data = {
                social_id: id,
                social_provider: "facebook"
            }
            const res = await axios.post(register, data, {
                headers: {'x-lang-code': changeLang, 'Authorization': `Bearer ${this.token}`}
            });
            if (res.data.status == 'ok') {

            }

        } catch {

        } finally {

        }
    }

    updateAddress = async (address: EditAddress) => {
        try {

            return await axios.put(UpdateCreateAddress, address, {
                headers: {'x-lang-code': changeLang, 'Authorization': `Bearer ${this.token}`}
            });

        } catch(e) {


        } finally {

        }
    }
    createAddress = async (address: CreateAddress) => {
        try {
            return await axios.post(UpdateCreateAddress, address, {
                headers: {'x-lang-code': changeLang, 'Authorization': `Bearer ${this.token}`}
            });

        } catch {

        } finally {

        }
    }


    UpdateName = async (userName: string) => {
        try {
            await axios.put(UpdateName, {name: userName}, {
                headers: {
                    'x-lang-code': changeLang, 'Authorization':
                        `Bearer ${this.token}`
                }
            });

        } catch {

        } finally {

        }
    }


    UpdateEmail = async (userEmail: string) => {
        try {
            const result = await axios.put(UpdateEmail, {email: userEmail}, {
                headers: {
                    'x-lang-code': changeLang, 'Authorization':
                        `Bearer ${this.token}`
                }
            }).catch(e => {
                return {status: false, response: e.response}
            });
            return {status: true, response: result}
        } catch (e) {
            return e
        } finally {

        }
    }


    UpdatePhone = async (userPhone: string) => {
        try {
            const result = await axios.put(UpdatePhone, {phone: userPhone}, {


                headers: {
                    'x-lang-code': 'en', 'Authorization':
                        `Bearer ${this.token}`
                }

            }).catch(e => {
                return {status: false, response: e.response}
            });
            return {status: true, response: result}

        } catch (e) {
            return e
        } finally {

        }
    }


    deleteAddress = async (address_id: number) => {
        try {
            await axios.delete(deleteRequest(address_id), {
                headers: {'x-lang-code': 'en', 'Authorization': `Bearer ${this.token}`}
            });

        } catch {

        } finally {

        }
    }


    deleteTicket = async (tickets_id: number) => {
        try {
            await axios.delete(deleteRequestTicket(tickets_id), {
                headers: {'x-lang-code': 'en', 'Authorization': `Bearer ${this.token}`}
            });

        } catch {

        } finally {

        }
    }


}

export default new MemberStore();
