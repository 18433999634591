import {Link} from 'react-router-dom';
import React from 'react'
import {useState, useEffect} from 'react';

import Axios from 'axios';
import RootStore from "../../mobx-stores/index";
import CartControl from "./component/CartControl";
import { useTranslation } from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import placeHolder from '../../imeges/placeHolder.png';


const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'


const {
    woocommerceStore: {
        Stores: {
            cartStore,
        },
    }
} = RootStore.Stores;
const Products = () => {
    const token = localStorage.getItem("token");
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [products, setProduct] = useState([]);
    const [title, setTitle] = useState("");
    const [CartItems, setCartItems] = useState([]);
    const [qtnAlert, setQtnAlert] = useState(false);
    const [productInCart, setProductInCart] = useState({});
    const [updateStatus, setUpdateStatus] = useState(false);



    useEffect(() => {
        if (productInCart.quantity > products.actual_quantity){setIsDisabled(true);setQtnAlert(true)}
        });


        function handleExistedInCart() {
         
    
            if (CartItems) {
    
                let existed = CartItems.find(
                    cartItem => cartItem.id == products.id
                )
    
                if (existed)
                    setProductInCart(existed);
                else
                    setProductInCart({});
            }
        }


    async function dataApi() {
        let {data: productsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/home/first-section-products?per_page=4`,{
            headers: {
                'x-lang-code': changeLang,
            }
        });
        const {
            data: {
                title,
                products:
                    {
                        list: products
                    }
            }
        } = productsResponse;
        setProduct(products)
        setTitle(title)
        setCartItems(cartStore?.cart?.line_items)
    }


    useEffect(() => {
        dataApi().then(r => {
            setLoading(false);

        })
        handleExistedInCart()
    }, [updateStatus, CartItems]);


    window.addEventListener('cart', () => {
        setCartItems([...cartStore.cart.line_items])
        setUpdateStatus(!updateStatus)
    })
    
    async function addToCart(product) {



        cartStore.addItem(product)
      
    }


    window.addEventListener('cart', () => {
        setCartItems([...cartStore.cart.line_items])
    })

    const [addQtbBtn, setAddQtbBtn] = useState();

    async function removeFromCart(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.description,
            taxes: product.tax

        };

        cartStore.deleteItem(item)
    }

    async function decreaseItemQuantity(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.description,
            taxes: product.tax

        };

        cartStore.removeItem(item)
    }

   

    return (
        <div className="container products-main remove-padding">


{loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={4}/></div>
                        </SkeletonTheme> : ""}

            {products && products.length < 1 ? "" : <h2 className="fontX3">{title}</h2>}
            
            {products && products.length > 4 ?<Link to="/offers" className="text-btn">{t('product.seeAll')}  <span className="iconar-right"></span></Link> : ""  }  

            {products && products.map((item, index) => {
                let existed = 0;
            
                if (CartItems) {
                    existed = CartItems.find(
                        cartItem => cartItem.id === item.id
                    )

                }

// {console.log(item)}

                return <div className='col-md-3 col-sm-6 col-xs-6 product-item' key={index}>

                    <div className='col-xs-12 remove-padding'>
                    {item.discount_percentage <= 0 ?
                                       ""  : <span className="offer-label">{t('product.save')} {item.discount_percentage} %</span>}


                        <div className='img-frame'><img src={item.photo_url || placeHolder} alt="product name"/></div>


                        {token ?

                            <div>
                                {!existed ?
                                    <button
                                        onClick={() => addToCart(item)}
                                    > 
                                     {t('product.addCart')}
                                    
                                    </button> :



                                    <div className='qtn-main'>
                                        
                                {existed.quantity < item.actual_quantity ? "" : <p  className='no-items-avalible'><span>{t('productDetails.noMore')}</span> </p>}

                                        <button
                                            className={existed.quantity == 1 ? "icona-minus dis-qtn" : "icona-minus"}
                                            onClick={() => {
                                                decreaseItemQuantity(item).then(r => {
                                                });
                                   
                                            }}></button>

                                        <h4>{existed.quantity}</h4>
                                       {existed.quantity < item.actual_quantity? <button  className={isDisabled ? "icona-plus showBtn" : "icona-plus"} onClick={() => {
                                            addToCart(item).then(r => {
                                            });
                                           
                                            

                                        }}></button>: <button  className="icona-plus showBtn"></button> } 


                                        <span className='icona-minus' onClick={() => removeFromCart(item)}></span>
                                    </div>
                                 
                                }



                            </div> : <Link className='go-log-in-link' to="/login">{t('product.addCart')}</Link>}
                        <Link className="fontX6" to={`/Product/${item.id}`}>{item.description}</Link>

                        <h3 className={item.discount_percentage <= 0 ? "fontX6" : "fontX6 has-offer"} >
                            <b>{item.price} {t('product.currency')}</b>
                            <span>{item.discount_percentage <= 0 ? "" : `${item.old_price} ${t('product.currency')}`} </span></h3>
                       
                        <h5>{t('product.per')} {item.unit}</h5>
                

                    </div>
                </div>
            })}


        </div>
    )
}

export default Products
