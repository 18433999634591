import {useState, useEffect} from 'react';
import Axios from 'axios';
import {useParams} from 'react-router-dom'
import Header from '../general/header'
import Footer from '../general/footer'
import SiteMap from '../general/siteMap'
import SimpleDateTime  from 'react-simple-timestamp-to-date';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

const Ticket = () => {

    const {t, i18n} = useTranslation();

    const {ticketId} = useParams()
    const [ticketInfo, setTicketInfo] = useState();
    const [replayT, setReplayT] = useState();
    const [showMenu, setShowMenu] = useState();
    const [loadingT, setLoadingT] = useState(true);
    const [enteredText, setEnteredText] = useState('');
    async function dataApi() {
    const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

        const token = localStorage.getItem("token")
        let {data: ticketsResponse} = await Axios.get(`https://dashboard.ezawaya.com/api/customer/tickets/${ticketId}`, {
            headers: {
                'x-lang-code': changeLang,
                'Authorization': `Bearer ${token}`

            }
        });
        const {data: ticketsData} = ticketsResponse;
        setTicketInfo(ticketsData)
    }

    useEffect(() => {
        dataApi().then(r => {
            setLoadingT(false);

        })
    }, []);


    async function submitFormData(e) {


        e.preventDefault();
        const token = localStorage.getItem("token")
        const replyTicket = replayT

        const userConfirm = {
            reply: replyTicket,
            ticket_id: ticketInfo.id,
        }

        try {
            let {data} = await Axios.post(`${process.env.REACT_APP_BASE_URL}/api/customer/tickets/reply`, userConfirm, {
                headers: {
                    'x-lang-code': 'en',
                    'Authorization': `Bearer ${token}`
                }
            })

            if (data.status == "ok") {
                dataApi()
                setEnteredText('');
                window.location.refresh();

            }
        } catch {
        }

    }


    const handleChange = e => {
        setEnteredText(e.target.value);
      };


    const [emptyReply, setEmptyReply] = useState('');


    function SubmitButton() {
        if (emptyReply) {
            return <button

            type='submit' className='icona-send-vertical'></button>
        } else {
            return <button

            disabled type='submit' className='icona-send-vertical'></button>
        }
        ;
    };




    return (
        <>
            <Header/>
            <SiteMap pageUrl="/TicketList" pageLink={t('ticketList.ticketsAll')} pageName={t('ticketList.ticketDetails')}/>
            {loadingT ?<div className='container add-ticket-loading'> <div className='col-xs-12 col-sm-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3'> <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                            <div><Skeleton count={8}/></div>
                        </SkeletonTheme></div></div> :<>

                        {ticketInfo ? <div className='container add-ticket'>
                <div className='col-xs-12 col-sm-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3'>
                    <h1 className='fontX3'>{t('ticketList.number')}{ticketInfo.id}</h1>


                    <div className={showMenu ? "showMenu" : ""}>
                    <div className='col-xs-12 remove-padding ticket-chat'>

                        <div className='chat-cont col-xs-12 remove-padding'>
                                 <div className='chat-main-cont'>
                            <div className='send-section text-section'>


                                <p>

                                    {ticketInfo.photo_url !== null ?
                                        <Zoom><img loading="lazy" src={ticketInfo.photo_url}/> </Zoom> : ""}

                                     <b>{ticketInfo.title}</b>
                                    <div className='clearfix'></div>
                                    {ticketInfo.description}
                                </p>
                            </div>


                            {ticketInfo.replies.length ? ticketInfo.replies.map((reply, key) => {

                                    return <div
                                        className={reply.is_customer_reply ? 'send-section text-section' : 'reply-section text-section'}>
                                        {/* <span>
                                         <Timestamp date={reply.created_at} autoUpdate/>
                                  </span> */}
                                        <span>




                    <SimpleDateTime dateSeparator="/" showTime="0" format="YMD">{reply.created_at}</SimpleDateTime>
                    &nbsp;&nbsp; | &nbsp;&nbsp;
                    <SimpleDateTime timeSeparator=":" showDate="0">{reply.created_at}</SimpleDateTime>

                                         {/* <Timestamp options={{ twentyFourHour: true }} date={reply.created_at} autoUpdate/> */}
                                  </span>
                                        <p>{reply.reply}</p>
                                    </div>
                                }
                            ) : ""}

</div>
                        </div>

                        <form onSubmit={submitFormData}>
                            <textarea
                                 value={enteredText}
                                onChange={e => {
                                    setReplayT(e.target.value);
                                    setEmptyReply(e.target.value);
                                    handleChange(e)
                                }}
                                name='reply' placeholder={t('ticketList.placeholder')} type="text"></textarea>



                            <SubmitButton/>


                        </form>
                    </div>
                    </div>

                </div>

            </div> : ""}
                        </> }



            <Footer/>
        </>
    )
}

export default Ticket
