import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ProfileSide = () => {
  const { t, i18n } = useTranslation(); 


  return (
 <div className='col-md-3 col-md-offset-1 col-xs-12 col-sm-12'>
 <ul className='profile-side-main'>
    <li><Link  to="/Orders"><span className='icona-bag'></span> {t('profile.profileSide.orders')}</Link></li>
    <li><Link  to="/Address"><span className='icont-truck'></span>{t('profile.profileSide.adress')}</Link></li>
    <li><Link  to="/HelpCenter"><span className='icona-question-mark-circle'></span>{t('profile.profileSide.helpCenter')}</Link></li>
    <li><Link  to="/Terms"><span className='icona-info-circle'></span> {t('profile.profileSide.terms')}</Link></li>
  </ul>
  </div>
  )
}

export default ProfileSide
