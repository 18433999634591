import React from 'react'
import NotFoundImg from '../../imeges/404.svg'
import { Link } from 'react-router-dom'
import {useTranslation} from 'react-i18next';

export const NotFound = () => {
  const {t, i18n} = useTranslation();

  return (
    <div className='container notfound-img text-center'>
    <img loading="lazy" src={NotFoundImg} alt="" />
    <p className='fontX4'>{t('NotFound.Opps')}</p>
    <Link className='solid-btn' to={`/`}>{t('checkOut.Continue')}</Link>
    </div>
  )
}

export default NotFound
