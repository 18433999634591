import React from 'react'
import Footer from '../general/footer'
import Header from '../general/header'
import Axios from 'axios'
import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import pdfImg from '../../imeges/pdf.svg';
import SiteMap from '../general/siteMap'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import RootStore from "../../mobx-stores/index";
import ReactDOM from "react-dom";
import SamilerProducts from './samilerProducts'
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import placeHolder from '../../imeges/placeHolder.png';
import {Helmet} from "react-helmet";
import { useContext } from "react";
import { MassegeContext } from '../MsgStore/MsgContext';



const {
    woocommerceStore: {
        Stores: {
            cartStore,
        },
    }
} = RootStore.Stores;
export const Product = () => {

    let msg = useContext(MassegeContext)

    const token = localStorage.getItem("token");
    const {t, i18n} = useTranslation();


    const {productId} = useParams()
    const [loading, setLoading] = useState(true);
    const [loadingInfo, setLoadingInfo] = useState(true);


    const [ProductSlider, setProductSlider] = useState([]);
    const [Product, setProduct] = useState([]);
    const [productInCart, setProductInCart] = useState({});
    const [addQtbBtn, setAddQtbBtn] = useState();
    const [CartItems, setCartItems] = useState([]);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [qtnAlert, setQtnAlert] = useState(false);


    async function addToCart(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.description

        };

        cartStore.addItem(product)

    }


    window.addEventListener('cart', () => {
        setCartItems([...cartStore.cart.line_items])
        setUpdateStatus(!updateStatus)
    })

    async function removeFromCart(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.description

        };

        cartStore.deleteItem(item)
    }

    async function decreaseItemQuantity(product) {

        let item = {
            id: product.id,
            photo: product.photo_url,
            quantity: 1,
            price: product.price,
            name: product.description

        };

        cartStore.removeItem(item)
    }

    const [showMore, setShowMore] = useState(false);

    async function dataApi() {
        const token = localStorage.getItem("token")
        try {
            const changeLang = localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == 'ar' ? localStorage.getItem("lang") : 'en'

            let {data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/products/${productId}`, {
                headers: {
                    'x-lang-code': changeLang,

                }
            });
            const {data: Product_data} = brandsResponse;
            setProduct(Product_data)

            const photoList = Product_data.images;


            let imges = [];
            if (photoList) photoList.map(item => {
                imges.push({
                    "original": item,
                    "thumbnail": item
                })

            })
            setProductSlider(imges)
            setCartItems(cartStore?.cart?.line_items)
        } catch (e) {
        }


    }


    function handleExistedInCart() {

        if (CartItems) {

            let existed = CartItems.find(
                cartItem => cartItem.id == Product.id
            )

            if (existed)
                setProductInCart(existed);
            else
                setProductInCart({});
        }
    }


    useEffect(() => {
        dataApi().then(r => {
            setLoadingInfo(false)
            setLoading(false)

        })
        handleExistedInCart()


    }, [updateStatus, CartItems, productId]);


    useEffect(() => {
        if (productInCart.quantity > Product.actual_quantity) {
            setIsDisabled(true);
            setQtnAlert(true)
        }
    });


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ezaway products</title>
                <meta name="description" content="ezaway product" />
                <link rel="canonical" href={`https://ezawaya.com/product/${productId}`} />
            </Helmet>
            <Header/>
            <SiteMap pageUrl="/Product" hide="hidden" pageName={t('product.product')}/>
            <div className='container remove-padding product-main'>
                <div className='col-md-7 col-xs-12 product-slider'>
                    {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                        <div><Skeleton count={1}/></div>
                    </SkeletonTheme> : <>
                        {ProductSlider.length > 1 ? <ImageGallery items={ProductSlider}
                                                                  isRTL={i18n.language == "ar" ? true : false}

                            /> :
                            <img loading="lazy" width="100%" src={Product.photo_url || placeHolder}/>}
                    </>}


                </div>

                {loadingInfo ? <div className="col-md-4 col-xs-12 product-info"><SkeletonTheme baseColor="#eee"
                                                                                               highlightColor="#ddd">
                    <div><Skeleton count={6}/></div>
                </SkeletonTheme></div> : <>
                    <div className="col-md-4 col-xs-12 product-info">
                        <h2 className="fontX4">{Product.name}</h2>
                        <h5 className="fontX6">{t('productDetails.brand')}: <span>{Product.brand_name}</span></h5>


                        <h3 className={Product.discount_percentage <= 0 ? "fontX6" : "fontX6 has-offer"}>
                            <b>{Product.price} {t('product.currency')}</b>
                            <span>{Product.discount_percentage <= 0 ? "" : `${Product.old_price} ${t('product.currency')}`} </span>
                        </h3>
                        <p style={{ marginTop: "15px" }}>{msg.messages.home_price_notice}</p>

                        {/* <h3  className="fontX4"><b>EGP 2360.00</b><span>EGP 1860.00</span></h3> */}

                        {Product.color = "" ? <ul>
                            <li><b>{t('productDetails.color')}: </b>: {Product.color}</li>
                        </ul> : ""}

                        {/* <li><b>Matterial </b>: wood</li> */}

                        {/* <li><b>Size </b>: 25x63 m</li>
                        <li><b>Weight </b>: 22 kg</li> */}


                        <div className="col-xs-12 remove-padding">
                            <h4 className="fontX6"> {t('productDetails.about')}</h4>
                            <p className="text fontX6">

                                {showMore ? Product?.description : `${Product?.description?.substring(0, 120)}`}
                                {Product?.description?.length >= 120 ? <span className="read-or-hide"
                                                                             onClick={() => setShowMore(!showMore)}>{!showMore ? `...${t('productDetails.more')}` : `${t('productDetails.less')}`}</span> : ""}
                            </p>


                        </div>

                        {Product.data_sheet && Product.data_sheet.length > 0 ?
                            <div className="col-xs-12 remove-padding download-files-main">
                                <h4>{t('productDetails.guide')}</h4>
                                <ul>

                                    <li>

                                        <a download href={Product.data_sheet}><img src={pdfImg}/> </a></li>


                                </ul>
                            </div> : ""}

                        {token ?
                            <div>

                                {!productInCart?.id ?

                                    <button className='solid-btn'
                                            onClick={() => addToCart(Product)}
                                    > {t('product.addCart')}
                                    </button> :

                                    <div className='qtn-main-product qtn-main col-xs-12'>
                                        <span className='icona-minus' onClick={() => removeFromCart(Product)}></span>
                                        <button
                                            className={productInCart.quantity == 1 ? "icona-minus dis-qtn" : "icona-minus"}
                                            onClick={() => {
                                                decreaseItemQuantity(Product).then(r => {
                                                });
                                                productInCart.quantity == 1 ? setAddQtbBtn(false) : setAddQtbBtn(true)
                                                productInCart.quantity > Product.actual_quantity ? setIsDisabled(true) || setQtnAlert(true) : setQtnAlert(false) || setIsDisabled(false)

                                            }}></button>

                                        <h4><span
                                            className="icona-cart"></span> {productInCart.quantity} {t('productDetails.inCart')}
                                        </h4>
                                        <button className={isDisabled ? "icona-plus showBtn" : "icona-plus"}
                                                onClick={() => {
                                                    addToCart(Product).then(r => {
                                                    });
                                                    productInCart.quantity == 1 ? setAddQtbBtn(false) : setAddQtbBtn(true)
                                                    productInCart.quantity >= Product.actual_quantity ? setIsDisabled(true) || setQtnAlert(true) : setQtnAlert(false) || setIsDisabled(false)
                                                }}></button>


                                    </div>


                                }

                                {/* {qtnAlert ? <p className='no-more-qtn-alert'><span className='icona-exclamation-circle'></span>{t('productDetails.noMore')} </p> : ""} */}
                                <div className='clearfix'></div>
                                {productInCart.quantity >= Product.actual_quantity ?
                                    <p className='no-more-qtn-alert'><span
                                        className='icona-exclamation-circle'></span>{t('productDetails.noMore')}
                                    </p> : ""}


                            </div> :
                            <div className='col-xs-12 remove-padding'><Link className='go-log-in-link solid-btn' to="/login">{t('product.addCart')}</Link>
                            </div>}


                    </div>
                </>}


            </div>

            <SamilerProducts/>


            <Footer/>

        </>
    )
}

export default Product
