import React, {useEffect} from 'react'
import Header from '../general/header'
import {Link} from 'react-router-dom'
import Joi from 'joi';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react'
import {useParams} from 'react-router-dom'
import memberStore from "../../mobx-stores/stores/memberStore/memberStore";
import {useTranslation} from 'react-i18next';


export const AddAdress = () => {
    const {t} = useTranslation();

    const [addressTitle, setAddressTitle] = useState('');
    const [street, setStreet] = useState('');
    const [buildingNumber, setBuildingNumber] = useState('');
    const [floorNumber, setFloorNumber] = useState('');
    const [flatNumber, setFlatNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDefault, setIsDefault] = useState(false);

    const {addressId} = useParams()
    const editAddress = JSON.parse(localStorage.getItem('editAddress'))

    function updateAddressDone() {
        localStorage.removeItem("editAddress")
    }


    useEffect(  () => {

        if (addressId && !editAddress) {
           window.location.href = '/Address'
        }
        else if (editAddress) {
            setAddressTitle(editAddress.address_title)
            setStreet(editAddress.street)
            setBuildingNumber(editAddress.building_number)
            setFloorNumber(editAddress.floor_number)
            setFlatNumber(editAddress.flat_number)
            setPhoneNumber(editAddress.phone_number)
            setIsDefault(editAddress.is_default)
            updateAddressDone()
        }
    }, [])


    let userLat = localStorage.getItem('lat');
    let userLng = localStorage.getItem('lng');
    let userAddress = localStorage.getItem('address');
    const navigate = useNavigate();
    let [address, setAddress] = useState({
        address_title: addressId ? editAddress?.address_title : '',
        street: addressId ? editAddress?.street : '',
        building_number: addressId ? editAddress?.building_number : '',
        floor_number: addressId ? editAddress?.floor_number : '',
        flat_number: addressId ? editAddress?.flat_number : '',
        phone_number: addressId ? editAddress?.phone_number : '',
        additional_info: addressId ? editAddress?.additional_info : '',
    });

    let [errorMsg, setErrorMsg] = useState("");
    let [errorList, setErrorList] = useState({});

    //
    //
    const updateAddress = async (address) => {
        setLoading(true)
        try {
            return await memberStore.updateAddress(address)
        } catch {

        } finally {
            setLoading(false)


        }

    }
    const createAddress = async (address) => {
        setLoading(true)
        try {
            return await memberStore.createAddress(address)
        } catch {

        } finally {
            setLoading(false)
        }

    }

    async function submitFormData(e) {
        let myAddress = {...address, lat: userLat, long: userLng, map_address: address.address_title};
        e.preventDefault();
        let validationResult = validateForm();
        const {error} = validationResult;
        if (error) {
            const errorData = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                errorData[name] = message;
            }
            setErrorList(errorData);

        } else {
            // validateForm()

            try {
                setErrorMsg('');

                let response = {}
                if (addressId) {

                    let updateAddressData = {...myAddress, address_id: addressId, is_default: isDefault}
                    response = await updateAddress(updateAddressData)
                } else {
                    response = await createAddress(myAddress)
                }
                const {data} = response
                if (data.status == "ok") {
                    navigate('/Address')
                    updateAddressDone()
                }

            } catch (error) {

                if (error?.response?.data?.message)
                    setErrorMsg(error?.response?.data?.message)
                else
                    console.info(error)
            }


        }
    }

    function getFormDate(e) {
        let myAddress = {...address};
        myAddress[e.target.name] = e.target.value;
        setAddress(myAddress);
    }


    function validateForm() {
        const schema = Joi.object({
            address_title: Joi.string().required(),
            street: Joi.string().required(),
            building_number: Joi.string().required(),
            floor_number: Joi.string().required(),
            flat_number: Joi.string().required(),
            phone_number: Joi.string().min(8).max(20).required()
                .messages({'string.min': `${t('validation.phoneNumber')}`})
            ,
            additional_info: Joi.string().allow(null, '')
        });

        return schema.validate(address, {abortEarly: false});

    }


    function SubmitButton() {
        if (addressTitle && street && buildingNumber && floorNumber && flatNumber && phoneNumber) {
            return <button className="solid-btn fontX7" type="submit">{t('addAdress.btn')}</button>
        } else {
            return <button className="solid-btn fontX7" type="submit" disabled>{t('addAdress.btn')}</button>
        }
        ;
    };


    return (
        <>
            <Header/>
            {loading ? <div className="loader-line"></div> : ""}
            <div className='container add-adress-main'>
                <div
                    className='col-md-offset-3 col-xs-offset-0 col-sm-offset-0 col-md-6 col-xs-12 col-sm-12 remove-padding'>

                    <form onSubmit={submitFormData}>
                        {errorMsg && <p className='error-backend-label'>{errorMsg}</p>}

                        <div className='col-xs-12'>
                            <h1 className='fontX3'>{t('addAdress.title')}</h1>
                            <p>{t('addAdress.Location')} <Link className='text-btn'
                                                               to={addressId ? `/EditMapAddress/${addressId}` : '/mapAdress'}>{addressId && !userAddress ? address.address_title : userAddress}...</Link>
                            </p>
                        </div>
                        {errorMsg ? <div className="erorMsg"></div> : ""}
                        <div className='input-main col-xs-12'>
                            <label>{t('addAdress.LocationInput')}</label>
                            <input
                                value={address.address_title}
                                style={{borderColor: errorList.address_title ? '#D62921' : ''}}
                                onChange={e => {
                                    setAddressTitle(e.target.value);
                                    getFormDate(e)
                                }} name='address_title' type="text" placeholder={t('addAdress.LocationInput')}/>
                            <div style={{display: errorList.address_title ? '' : 'none'}} className="error-msg">
                                {errorList.address_title}
                            </div>
                        </div>


                        <div className='input-main col-xs-12'>
                            <label>{t('addAdress.Street')}</label>
                            <input
                                value={address.street}
                                style={{borderColor: errorList.street ? '#D62921' : ''}}
                                onChange={e => {
                                    setStreet(e.target.value);
                                    getFormDate(e)
                                }}
                                name='street' type="text" placeholder={t('addAdress.Street')}/>
                            <div style={{display: errorList.street ? '' : 'none'}} className="error-msg">
                                {errorList.street}
                            </div>
                        </div>

                        <div className='input-main col-xs-12'>
                            <label>{t('addAdress.Building')}</label>
                            <input
                                value={address.building_number}
                                style={{borderColor: errorList.building_number ? '#D62921' : ''}}
                                onChange={e => {
                                    setBuildingNumber(e.target.value);
                                    getFormDate(e)
                                }}
                                name="building_number" type="text" placeholder={t('addAdress.Building')}/>
                            <div style={{display: errorList.building_number ? '' : 'none'}} className="error-msg">
                                {errorList.building_number}
                            </div>
                        </div>


                        <div className='input-main col-xs-6'>
                            <label>{t('addAdress.Floor')}</label>
                            <input
                                value={address.floor_number}

                                style={{borderColor: errorList.floor_number ? '#D62921' : ''}}
                                onChange={e => {
                                    setFloorNumber(e.target.value);
                                    getFormDate(e)
                                }} name='floor_number' type="number" placeholder={t('addAdress.Floor')}/>
                            <div style={{display: errorList.floor_number ? '' : 'none'}} className="error-msg">
                                {errorList.floor_number}
                            </div>
                        </div>

                        <div className='input-main col-xs-6'>
                            <label>{t('addAdress.Flat')}</label>
                            <input
                                value={address.flat_number}

                                style={{borderColor: errorList.flat_number ? '#D62921' : ''}}
                                onChange={e => {
                                    setFlatNumber(e.target.value);
                                    getFormDate(e)
                                }} name='flat_number' type="number" placeholder={t('addAdress.Flat')}/>
                            <div style={{display: errorList.flat_number ? '' : 'none'}} className="error-msg">
                                {errorList.flat_number}
                            </div>
                        </div>

                        <div className='input-main col-xs-12'>
                            <label>{t('addAdress.Mobile')}</label>
                            <input
                                value={address.phone_number}

                                style={{borderColor: errorList.phone_number ? '#D62921' : ''}}
                                onChange={e => {
                                    setPhoneNumber(e.target.value);
                                    getFormDate(e)
                                }} name='phone_number' type="number" placeholder={t('addAdress.Mobile')}/>
                            <div style={{display: errorList.phone_number ? '' : 'none'}} className="error-msg">
                                {errorList.phone_number}
                            </div>
                        </div>

                        <div className='input-main col-xs-12'>
                            <label>{t('addAdress.Additional')}</label>
                            <textarea
                                value={address.additional_info}
                                name='additional_info'
                                onChange={e => {
                                    getFormDate(e)
                                }} placeholder={t('addAdress.Additional')}> </textarea>
                        </div>
                        <div className='col-xs-12'><SubmitButton/></div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default AddAdress
