import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import CheckoutSide from './CheckOutSide'
import SiteMap from '../general/siteMap'
import Header from '../general/header'
import Footer from '../general/footer'
import {Link} from 'react-router-dom'
import Axios from 'axios';
import RootStore from "../../mobx-stores/index";
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import address from "../address/Address";


const {
    woocommerceStore: {
        Stores: {
            cartStore,
        },
    }
} = RootStore.Stores;
const CheckoutAddress = () => {



useEffect(() => {
    if(cartStore.cart.line_items.length == 0){
        window.location.href='/CartCheckOut';
    }
}, []);

    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    function goPromo() {
        let path = '/CheckoutPromoCode'
        navigate(path)
    }

    const [Address, setAddress] = useState([]);
    const [defaultActive, setDefaultActive] = useState(false);


    async function dataApi() {
        const token = localStorage.getItem("token")
        const changeLang = localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == 'ar' ? localStorage.getItem("lang") : 'en'

        let {data: AddressResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/addresses`,{
            headers: {
                'x-lang-code': changeLang,
                'Authorization': `Bearer ${token}`
            }
        });
        const {data: Address} = AddressResponse;
        setAddress(Address)
        if (Address.length)
            cartStore.setSelectedAddress(Address[0])
    }


    useEffect(() => {
        dataApi().then(r => {
            setLoading(false);

        })
    }, []);


    useEffect(() => {


        if (Address && Address.length) {

            const addressSelected = Address.find(a => {
                return a.is_default == true
            })

            if (addressSelected)
                setDefaultActive(true)

        }

    }, [Address]);


    const changeAddress = (address) => {
        cartStore.setSelectedAddress(address)

    }

    const setEditAddress = (address) => {
        localStorage.setItem('editAddress', JSON.stringify(address));
        localStorage.setItem("address", '')
        navigate(`/EditAddress/${address.id}`)
    }

    return (
        <>
            <Header hide="hidden-cart"/>
            <SiteMap pageUrl="/CartCheckOut" pageLink={t('checkOut.title')} pageName={t('checkOut.subTileAdress')}/>


            <div className='container cheackout-main'>


                <h1 className='fontX3'>{t('checkOut.title')}</h1>
                <div className='col-lg-7 col-md-6 remove-padding checkout-Address'>
                    <h2 className='fontX3'>{t('checkOut.subTileAdress')}</h2>
                    {loading ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                        <div><Skeleton count={2}/></div>
                    </SkeletonTheme> : <form>


                        {Address && Address.map((item, index) =>
                            <div className="radio-label Address-item" key={index}>
                                <input defaultChecked={item.is_default == 1 ||(!defaultActive&&index==0)? true : false} id={item.id} type="radio"
                                       onClick={() => changeAddress(item)} className="space"
                                       name="selectedAddress"/>
                                <div className="bg"></div>


                                <label id={item.id}>{item.address_title}</label>
                                <p>{t('Adress.street')} {item.street}, {t('Adress.building')} {item.building_number},
                                    {t('Adress.floor')} {item.floor_number}, {item.flat_number} {t('Adress.Phone')} {item.phone_number}</p>
                                <a onClick={() => setEditAddress(item)}>{t('checkOut.change')}</a>
                            </div>)}


                        <Link to="/mapAdress" className='outline-btn'><span
                            className='icona-plus'></span> {t('checkOut.newAddBtn')}</Link>
                        <button type='submet' onClick={() => goPromo()}
                                className='solid-btn'>{t('checkOut.useAdressBtn')}</button>
                    </form>}


                    <ul className='checkout-ul'>
                        <li className='fontX4'>{t('checkOut.Promocode')}</li>
                        <li className='fontX4'>{t('checkOut.Payment')}</li>
                    </ul>


                </div>
                <div className='col-md-4 col-md-offset-1 remove-padding'>
                    <div className='checkout-side'>

                        <CheckoutSide/>
                        <button className='solid-btn' onClick={() => goPromo()}>{t('checkOut.addressBtn')}</button>

                    </div>
                </div>

            </div>

            <Footer/>

        </>
    )
}

export default CheckoutAddress
