import {action, observable} from "mobx";
import {persist} from "mobx-persist";
import rootStore from "../../index";
import {IChanel, IOrder, IOrderItems, IPAYMENT_METHOD} from "./types";
import axios from "axios";
import {createOrder} from "../../../mobx-stores/Requests/requests";
import { Status } from "@googlemaps/react-wrapper";

const cartStore = rootStore.Store.Stores.cartStore;

class OrderStore {
    @observable token = localStorage.getItem("token");
    @observable lang = localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == 'ar' ? localStorage.getItem("lang") : 'en'
    @observable header = {
        headers: {
            'x-lang-code': this.lang,
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
        }
    }
    @persist("object") @observable order: IOrder = {
        order_items: [
            {
                quantity: 0,
                unit: 0,
                product_id: 0
            }
        ],
        customer_address_id: 0,
        cash_voucher_id: 0,
        down_payment_address_id:0,
        time_slot_id:0,
        down_payment_date:'',
        channel: IChanel.Website,
        payment_method: IPAYMENT_METHOD.cash,
        manual_labor:false

    };

    @action
    async createOrder() {
        const newOrder = this.order;

        let cart = cartStore.cart;
        let paymentMethod = cart.PaymentMethod
        let lineItems = [];

        cart.line_items.map(el => {
            lineItems.push({
                product_id: el.id,
                unit: el.unit,
                quantity: el.quantity
            })
        })
        newOrder.order_items = lineItems;
        if (cart?.promo?.id)
        newOrder.cash_voucher_id = cart.promo.id;
        else
           delete newOrder.cash_voucher_id
        newOrder.payment_method = paymentMethod;
        newOrder.customer_address_id = cart.selectedAddress.id
      if (paymentMethod==IPAYMENT_METHOD.cash){
          newOrder.down_payment_address_id = cart?.selectedCashAddress?.id
          newOrder.time_slot_id = cart?.Time?.id
          newOrder.down_payment_date = cart.Date
      }

      newOrder.manual_labor=true;

        try {
            const res = await axios.post(createOrder, JSON.stringify(newOrder), {
                ...this.header
            });


            if (res.data.status == 'ok') {
                cartStore.resetCart();

               return {status:true, orderId:res.data.data.id,cowpay_token:res.data.data.cowpay_token,iframe_url:res.data.data.iframe_url}

            }
            else {
                return false
            }
        } catch (e) {
            return false
        }
    }


}

export default new OrderStore();
