import React from 'react'
import {useState} from 'react';
import {useEffect} from 'react';
import Header from '../general/header'
import Footer from '../general/footer'
import {Link, useNavigate} from 'react-router-dom';
import Axios from 'axios'
import delIcon from '../../imeges/del.svg';
import memberStore from "../../mobx-stores/stores/memberStore/memberStore";
import {useTranslation} from 'react-i18next';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import noResults from '../../imeges/no-results.png';

export const Address = () => {

    const {t, i18n} = useTranslation();

    const [sorrtedAddressList, setSorrtedAddressList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedAddressId, setSelectedAddressId] = useState();
    const [buttonVisible, setButtonVisible] = useState(false);
    const [loadingA, setLoadingA] = useState(true);

    const navigate = useNavigate()

    async function dataApi() {
        const token = localStorage.getItem("token")
        const changeLang = localStorage.getItem("lang") == 'en' || localStorage.getItem("lang") == 'ar' ? localStorage.getItem("lang") : 'en'

        let {data: brandsResponse} = await Axios.get(`${process.env.REACT_APP_BASE_URL}/api/customer/addresses`,{
            headers: {
                'x-lang-code': changeLang,
                'Authorization': `Bearer ${token}`

            }
        });
        const {data: brands} = brandsResponse;

        setSorrtedAddressList(brands)

    }

    useEffect(() => {
        dataApi().then(r => {
            setLoadingA(false);

        })
    }, []);


    const setDefault = async (address) => {
        let tempAddress = {...address}
        tempAddress['is_default'] = 1
        tempAddress['address_id'] = address.id
        setLoading(true)
        try {
            await memberStore.updateAddress(tempAddress).then(dataApi)
        } catch {

        } finally {
            setLoading(false)
        }
    }

    const deleteAddress = async (address_id) => {
        setButtonVisible(true)
        setSelectedAddressId(address_id)
    }
    const ConfirmDeleteAddress = async () => {
        setLoading(true)
        try {
            await memberStore.deleteAddress(selectedAddressId).then(dataApi)
        } catch {

        } finally {
            setLoading(false)
            setButtonVisible(false)
        }
    }

    const setEditAddress = (address) => {
        localStorage.setItem('editAddress', JSON.stringify(address));
        localStorage.setItem("address", '')
        navigate(`/EditAddress/${address.id}`)
    }

    return (
        <>


            <Header/>
            {loading ? <div className="loader-line"></div> : ""}

            {/* {buttonVisible? <button href="" onClick={ConfirmDeleteAddress}>confirm delete</button>:''} */}


            {buttonVisible ? <div className="pop-addcard-main adress-popup-confirm">
                <div className='pop-addcard '>
                    <img src={delIcon} loading="lazy"/>
                    <h2 className='fontX3'>{t('Adress.Confirm')}</h2>
                    <p>{t('Adress.popInfo')}</p>


                    <div className='col-xs-12 remove-padding'>
                        <div className='col-md-6 col-xs-6'>
                            <span className='outline-btn'
                                  onClick={() => setButtonVisible(false)}>{t('Adress.No')}</span>
                        </div>
                        <div className='col-md-6 col-xs-6'>
                            <button className='solid-btn' onClick={ConfirmDeleteAddress}>{t('Adress.yes')}</button>
                        </div>
                    </div>


                </div>
                <div className='bg-pop' onClick={() => setButtonVisible(false)}></div>

            </div> : ''}


            <div className='container profile-main'>
                <div className='col-md-6 col-sm-12 col-xs-12 col-md-offset-3 address-main'>
                    <h1 className='fontX3'>{t('Adress.title')}</h1>
                    <Link to="/Loacation" className='outline-btn'><span className='icona-plus'></span>
                        {t('Adress.btn')}</Link>
                    {loadingA ? <SkeletonTheme baseColor="#eee" highlightColor="#ddd">
                        <div><Skeleton count={3}/></div>
                    </SkeletonTheme> : <div>

                        {sorrtedAddressList.length && sorrtedAddressList.length >= 0 ? <div>

                            {sorrtedAddressList && sorrtedAddressList.map((item, index) =>

                                <div className='col-xs-12 address-item' key={index}>
                                    <h3 className='fontX6'>{item.address_title}</h3>
                                    <p className='fontX7'>{t('Adress.street')} {item.street}, {t('Adress.building')} {item.building_number},
                                        {t('Adress.floor')} {item.floor_number}, {t('Adress.flat')}{item.flat_number},
                                        {t('Adress.title')} {item.phone_number}
                                    </p>
                                    <ul>
                                        {item.is_default === 0 ?
                                            <li><span onClick={() => setDefault(item)}>{t('Adress.default')}</span>
                                            </li> :
                                            <li><span></span></li>}
                                        <li><a onClick={() => setEditAddress(item)}>{t('Adress.Edit')}</a></li>
                                        <li>{item.is_default === 0 && sorrtedAddressList.length > 1 ? <span onClick={(e) => deleteAddress(item.id)}>{t('Adress.Delete')}</span>:""}
                                         {console.log( )}
                                        </li>
                                    </ul>
                                </div>
                            )}

                        </div> : <div className='no-results-main no-results-address'>
                            <img loading="lazy" src={noResults} alt="no Results"/>
                            <h4>{t('NotFound.adress')}</h4>
                            <Link className='solid-btn' to={`/`}>{t('checkOut.Continue')}</Link>

                        </div>


                        }


                    </div>}


                </div>


            </div>
            <Footer/>
        </>
    )
}

export default Address
