import React from 'react'
import axios from 'axios';
import {useState} from 'react'
import { useTranslation } from 'react-i18next';


export const Subscribe = () => {
    const { t, i18n } = useTranslation();
    let [user, setUser] = useState({
        email: ''
    });

let[errorMsg,setErrorMsg] = useState("");
let[socMsg,setSocMsg] = useState("");




    async function submitFormData(e) {
        e.preventDefault();


          try {
            setErrorMsg('');

            const changeLang = localStorage.getItem("lang")=='en' || localStorage.getItem("lang")=='ar' ? localStorage.getItem("lang") : 'en'

            let {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/customer/home/newsletter`, user, {
                headers: {
                    'x-lang-code': changeLang,
                }
            });

            if (data.status == "ok") {
                setSocMsg(`${t('Subscribe.doneMsg')}`);

            }
        } catch (error) {

            if (error?.response?.data?.message)
                setErrorMsg( error?.response?.data?.message);


        }

        finally {


            setTimeout(function() {
                setSocMsg(false)
                 }, 5000);

                 setTimeout(function() {
                    setErrorMsg(false)
                     }, 5000);


      }



    }


    function getFormDate(e) {
        let myUser = {...user};
        myUser[e.target.name] = e.target.value;
        setUser(myUser);
    }


    return (
       <>
          <div className='container-fluid Subscribe-main remove-padding'>

               <div className='container'>
                   <div className='col-md-6 col-md-offset-3'>
                       <h2 className='fontX4'>{t('Subscribe.title')}</h2>
                       <h5>{t('Subscribe.SubTitle')}</h5>
                       <form onSubmit={submitFormData}>
                           <div className='input-main'>
                               <label>{t('Subscribe.label')}</label>
                               <input name='email' onChange={e => {getFormDate(e)}} placeholder={t('Subscribe.E-maill')} type="text"/>
                    {errorMsg&&<p className='errorMsg'>{errorMsg}</p>}
                    {socMsg&&<p className="socMsg">{socMsg}</p>}

                               <button className='text-btn'>{t('Subscribe.btn')}</button>
                           </div>
                       </form>
                   </div>
               </div>

          </div>

       </>
    )
}

export default Subscribe
